/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { TextField, Chip, Avatar, Box, debounce, Typography, useTheme } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectEditUnit,
  selectNewOrder,
  selectOrderDetails,
  selectOrderRequirementTypes,
  selectStage,
} from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { useFormContext } from 'react-hook-form';
import { IRequrementType } from '@AMIEWEB/Order/Store/helpers/rateCard/types';
import { LicenseDropdownGrid } from './LicenseDropdown';
import { selectOrderLicenses } from '@AMIEWEB/Order/Store/Order.selectors';
import { CompactStatus, IOrderLicense, orderDataActions } from '@AMIEWEB/Order/Store/Order.redux';
import { transformLicenseData } from '../../helper';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { orderCreationReducer, orderCreationSliceKey } from '@AMIEWEB/Order/Store/OrderCreation.redux';
import { orderCreationSaga } from '@AMIEWEB/Order/Store/OrderCreation.saga';
import { createOrderOcbrReducer, createOrderOcbrSliceKey } from 'store/redux-store/create-order-ocbr/slice';
import { createOrderOcbrSaga } from 'store/redux-store/create-order-ocbr/saga';
import { CustomTooltip } from '@AMIEWEB/Common/Tooltips/CustomTooltip';
import { orderCreationActions } from '@AMIEWEB/Order/Store/OrderCreation.actions';

const useStyles = makeStyles()(theme => ({
  searchField: {
    height: '1%',
    width: '100%',
    borderBottom: '1px solid #CCCCCC',
  },
  chip: {
    margin: '0 1% 1% 0',
  },
  incompleteChip: {
    margin: '0 1% 1% 0',
    border: '2px dashed #bdbdbd',
    opacity: 0.7,
  },
  wrapper: {
    backgroundColor: '#FFFFFF !important',
    border: '1px solid #CCCCCC',
    borderRadius: 5,
    minHeight: 300,
  },
  noBorder: {
    border: 'hidden',
  },
  chipWrapper: {
    paddingTop: '0.8%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  chipIcon: {
    fontSize: 'large',
    marginLeft: 6,
  },
}));

interface Props {
  division?: string;
  isDisabled?: boolean;
}

export const LicenseDropdownWrapper = (props: Props) => {
  /* eslint-disable i18next/no-literal-string */
  const theme = useTheme();
  const { isDisabled } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  useInjectReducer({ key: orderCreationSliceKey, reducer: orderCreationReducer });
  useInjectSaga({ key: orderCreationSliceKey, saga: orderCreationSaga });
  useInjectReducer({ key: createOrderOcbrSliceKey, reducer: createOrderOcbrReducer });
  useInjectSaga({ key: createOrderOcbrSliceKey, saga: createOrderOcbrSaga });
  const dispatch = useDispatch();
  const { states, licenses } = useSelector(selectOrderRequirementTypes);
  const orderLicenses = useSelector(selectOrderLicenses);
  const { setValue } = useFormContext();
  const [licenseRequirements, setLicenseRequirements] = useState<IRequrementType[] | null>();
  const [isError, setIsError] = useState<boolean>(false);
  const [transformedLicenses, setTransformedLicenses] = useState<any[]>(null);
  const unitLicenses = useSelector(selectOrderDetails)?.unit?.qualification?.licenses;
  const editableUnitLicenses = useSelector(selectEditUnit)?.qualification?.licenses;
  const currentStage = useSelector(selectStage);
  const newOrderObj = useSelector(selectNewOrder);

  const getSelectionInfo = searchQuery => {
    if (!Array.isArray(licenses)) return [];
    const searchResult =
      searchQuery && searchQuery.length >= 2
        ? licenses?.filter(item =>
            `${item?.abbreviation} - ${item?.credentialName}`
              .toLowerCase()
              .replace(/\s|\.|\(|\)/g, '')
              .includes(searchQuery.toLowerCase().replace(/\s|\.|\(|\)/g, '')),
          )
        : licenses;

    return searchResult.map(
      item =>
        ({
          abbreviation: item.abbreviation,
          category: item.category,
          credentialName: item.credentialName,
          isSelected: false,
          requirementTypeId: item.requirementTypeID,
          subCategory: item.subCategory,
        } as IRequrementType),
    );
  };

  const handleSearch = event => {
    (async () => {
      if (Array.isArray(licenses) && licenses.length > 0) {
        setLicenseRequirements(getSelectionInfo(event.target.value));
      }
    })();
  };

  const updateLicenses = newLicenses => {
    const individualLicenses = [];
    newLicenses?.map(l => {
      if (l.compactStatus === CompactStatus.single) {
        if (l?.licenseStateDetails?.length === 0) {
          individualLicenses.push({
            requirementTypeId: l.requirementTypeId ?? l.requirementTypeID,
            licenseAbbreviation: l.abbreviation,
            licenseDescription: l.credentialName,
            stateAbbreviation: null,
            state: null,
            isRequired: true,
            isMultiState: false,
            documentAttachmentRequired: false,
            isError: l.isError,
          });
        } else {
          l.licenseStateDetails?.map(s =>
            individualLicenses.push({
              requirementTypeId: l.requirementTypeId ?? l.requirementTypeID,
              licenseAbbreviation: l.abbreviation,
              licenseDescription: l.credentialName,
              stateAbbreviation: s?.state?.value,
              state: s?.state?.name,
              isRequired: s?.isRequired,
              isMultiState: false,
              documentAttachmentRequired: s?.documentAttachmentRequired,
              isError: l.isError,
            }),
          );
        }
      } else {
        individualLicenses.push({
          requirementTypeId: l.requirementTypeId ?? l.requirementTypeID,
          licenseAbbreviation: l.abbreviation,
          licenseDescription: l.credentialName,
          stateAbbreviation: null,
          state: null,
          isRequired: l?.licenseStateDetails[0]?.isRequired,
          isMultiState: l.compactStatus === CompactStatus.multiple ? true : null,
          documentAttachmentRequired: l?.licenseStateDetails[0]?.documentAttachmentRequired,
          isError: l.isError,
        });
      }
    });
    setValue('qualification.licenses', individualLicenses);
  };

  const debouncedSearchHandler = debounce(handleSearch, 300);

  const handleDelete = (licenseToDelete, data: IOrderLicense[]) => {
    let selectedLicenseDetails = [];
    const licenseFromData = data?.find(
      licenseDetails => licenseDetails?.licenseId === (licenseToDelete.licenseId ?? licenseToDelete.requirementTypeId),
    );

    if (
      (licenseToDelete.compactStatus === 'Single State' || licenseToDelete.isMultiState === false) &&
      licenseFromData.licenseStateDetails?.length > 1
    ) {
      selectedLicenseDetails = data?.map(license => ({
        ...license,
        licenseStateDetails:
          license?.licenseId === (licenseToDelete.licenseId ?? licenseToDelete.requirementTypeId)
            ? license?.licenseStateDetails?.filter(s => s?.state?.value !== licenseToDelete.stateAbbreviation)
            : license.licenseStateDetails,
      }));
    } else {
      selectedLicenseDetails = data?.filter(
        license => license?.licenseId !== (licenseToDelete.licenseId ?? licenseToDelete.requirementTypeId),
      );
    }
    dispatch(orderDataActions.setOrderLicenseDetails(selectedLicenseDetails));
  };

  const loadSavedLicenses = (savedLicenses: any[]) => {
    const formattedLicenses: IOrderLicense[] = [];
    let licenseStates: any[] = [];
    savedLicenses?.forEach(license => {
      const stateValue = states?.find(s => s?.value === license?.stateAbbreviation);
      const existingLicense = licenseStates?.find(l => l?.requirementTypeId === license?.requirementTypeId);
      if (existingLicense) {
        const existingItemIndex = licenseStates?.findIndex(
          l => l?.requirementTypeId === existingLicense?.requirementTypeId,
        );
        const stateDetails = {
          state: stateValue,
          isRequired: license?.isRequired,
          documentAttachmentRequired: license?.documentAttachmentRequired,
        };
        const stateArray = [stateDetails];
        existingLicense?.licenseState?.forEach(a => {
          stateArray.push(a);
        });
        const newLicenseData = {
          ...existingLicense,
          licenseState: stateArray,
        };
        licenseStates[existingItemIndex] = newLicenseData;
      } else {
        const licenseDetails = {
          requirementTypeId: license?.requirementTypeId,
          stateAbbreviation: license?.stateAbbreviation,
          licenseAbbreviation: license?.licenseAbbreviation,
          licenseDescription: license?.licenseDescription,
          isMultiState: license?.isMultiState,
          licenseState: [
            {
              state: stateValue,
              isRequired: license?.isRequired,
              documentAttachmentRequired: license?.documentAttachmentRequired,
            },
          ],
        };
        licenseStates.push(licenseDetails);
      }
    });
    licenseStates?.forEach(licenseData => {
      const LicenseDetails: IOrderLicense = {
        licenseDescription: licenseData?.licenseDescription,
        licenseAbbreviation: licenseData?.licenseAbbreviation,
        licenseId: licenseData?.requirementTypeId,
        compactStatus:
          licenseData?.isMultiState === true
            ? CompactStatus?.compact
            : licenseData?.isMultiState === false
            ? CompactStatus?.single
            : null,
        abbreviation: licenseData?.stateAbbreviation,
        licenseStateDetails: licenseData?.licenseState,
        isError: { compactStatus: false, state: false },
        isSelected: true,
      };
      formattedLicenses.push(LicenseDetails);
    });

    dispatch(orderDataActions.setOrderLicenseDetails(formattedLicenses));
  };

  useEffect(() => {
    if (Array.isArray(licenses) && licenses.length > 0) {
      setLicenseRequirements(getSelectionInfo(''));
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(orderLicenses)) {
      const isLicenseError = orderLicenses?.find(
        license => license.isError?.compactStatus === true || license.isError?.state === true,
      );
      if (isLicenseError) {
        setIsError(true);
      } else {
        setIsError(false);
      }
      setTransformedLicenses(transformLicenseData(orderLicenses));
    }
  }, [orderLicenses]);

  useEffect(() => {
    if (currentStage !== 'createUnitDetails') {
      if (unitLicenses?.length && licenses?.length) {
        loadSavedLicenses(unitLicenses);
      } else if (editableUnitLicenses?.length && licenses?.length) {
        loadSavedLicenses(editableUnitLicenses);
      }
    }
  }, [unitLicenses, editableUnitLicenses]);

  useEffect(() => {
    dispatch(
      orderCreationActions.setOrder({
        ...newOrderObj,
        qualification: {
          ...newOrderObj?.qualification,
          licenses: transformedLicenses,
        },
      }),
    );
  }, [transformedLicenses]);

  return (
    <>
      <Box className={classes.wrapper}>
        <TextField
          variant="outlined"
          color="primary"
          placeholder={t('order.createUnit.qualifications.placeHolder')}
          id="txtLicense"
          autoComplete="on"
          onChange={debouncedSearchHandler}
          disabled={isDisabled}
          fullWidth
          classes={{ root: classes.searchField }}
          InputProps={{
            startAdornment: <SearchIcon style={{ opacity: 0.6 }} />,
            classes: { notchedOutline: classes.noBorder },
          }}
        />
        <LicenseDropdownGrid
          filteredLicenses={licenseRequirements}
          updateLicenses={updateLicenses}
          isDisabled={isDisabled}
        />
      </Box>
      {isError && <Typography style={{ color: '#D90000', margin: '5px' }}>{t('order.requiredMessage')}</Typography>}
      <div className={classes.chipWrapper} id={'createUnitQualificationsLicenseAddedItemContainer'}>
        {orderLicenses &&
          transformedLicenses?.map((item, idx) => {
            const isRequiredText = item?.isRequired ? t('order.createUnit.required') : t('order.createUnit.preferred');
            const stateValue = item?.state ? item?.state : 'Compact';
            return (
              <div key={idx} style={{ paddingRight: '6px' }}>
                <CustomTooltip
                  tooltipContent={`(${isRequiredText}) ${item?.licenseDescription} - ${stateValue}`}
                  placement="bottom"
                  isStandardContent={true}
                  contentStyle={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}
                >
                  <Chip
                    variant="outlined"
                    avatar={
                      <Avatar variant={item.isRequired ? 'primary' : 'green'}>{item.isRequired ? 'R' : 'P'}</Avatar>
                    }
                    disabled={isDisabled}
                    key={item.licenseId}
                    label={
                      <React.Fragment>
                        {item.isMultiState
                          ? `${item.licenseAbbreviation} - Compact`
                          : item.isMultiState === false && item.stateAbbreviation
                          ? `${item.licenseAbbreviation} - ${item.stateAbbreviation}`
                          : `${item.licenseAbbreviation}`}
                        {item.documentAttachmentRequired && (
                          <DescriptionOutlinedIcon
                            style={{ fill: theme.palette.components.icons.secondary.backgroundColor }}
                            className={classes.chipIcon}
                          />
                        )}
                      </React.Fragment>
                    }
                    className={item.isMultiState !== null ? classes.chip : classes.incompleteChip}
                    onDelete={() => handleDelete(item, orderLicenses)}
                  />
                </CustomTooltip>
              </div>
            );
          })}
      </div>
    </>
  );
};
