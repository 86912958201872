import { Checkbox, Divider, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { ContactItem } from './ContactItem';
import { useTranslation } from 'react-i18next';
import { selectPlacementContactsData } from 'store/redux-store/placement-details/selectors';
import { selectPlacementOrderUnit } from 'store/redux-store/placement-summary/selectors';
import { useSelector } from 'react-redux';

const useStyles = makeStyles()(theme => ({
  root: {
    padding: '0px 5px',
  },
  iconRoot: {
    minWidth: '34px',
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItemText: {
    display: 'flex',
    flexDirection: 'column',
  },
  secondaryText: {
    maxWidth: 280,
  },
  title: {
    color: theme.palette.framework.system.charcoal,
    fontSize: '16px !important',
    fontWeight: 500,
    marginTop: 5,
  },
  tooltipArrow: {
    color: theme.palette.framework.system.white,
    '&::before': {
      border: `1px solid ${theme.palette.framework.system.doveGray}`,
    },
  },
  tooltipBackground: {
    backgroundColor: theme.palette.framework.system.white,
    color: theme.palette.framework.system.doveGray,
    border: `1px solid ${theme.palette.framework.system.doveGray}`,
    fontSize: '12px',
  },
  tooltip: {
    margin: '6px 2px 2px 10px',
  },
  description: {
    color: theme.palette.framework.system.charcoal,
    fontSize: 12,
  },
}));

export const ContactList = props => {
  const {
    enableCommunication,
    clientContact,
    normalOrder = false,
    handleClick = () => {},
    handleSelectAll = () => {},
    showCheckbox = false,
    areAllClientContactsChecked,
    disableIndividualIcons = false,
  } = props;
  const { classes } = useStyles();
  const { flattenedContacts: contacts, facility } = useSelector(selectPlacementContactsData);
  const unitId = useSelector(selectPlacementOrderUnit);
  const getDescription = (description: string) => {
    return description?.length > 90 ? (
      <Tooltip classes={{ tooltip: classes.tooltipBackground, arrow: classes.tooltipArrow }} arrow title={description}>
        <Typography classes={{ root: classes.description }} data-testid="description">
          {description?.substring(0, 87) + '...'}
        </Typography>
      </Tooltip>
    ) : (
      description
    );
  };

  return (
    <List className={classes.root}>
      {showCheckbox && (
        <SelectAllCheckbox
          handleSelectAll={handleSelectAll}
          disabled={contacts?.facilityContacts?.filter(contact => contact?.email && contact?.id)?.length === 0}
          checked={areAllClientContactsChecked?.()}
        />
      )}
      {contacts?.facilityContacts?.map((contact, index) => (
        <>
          <ContactItem
            {...contact}
            key={index}
            description={getDescription(contact?.description)}
            enableCommunication={enableCommunication}
            placementClientContact={clientContact}
            placementId={contacts?.placementId}
            normalOrder={normalOrder}
            disableMailIcon={disableIndividualIcons}
            disablePhoneIcon={disableIndividualIcons}
            showCheckbox={showCheckbox}
            facilityId={facility?.id}
            unitId={unitId}
            checkbox={
              <ContactCheckbox
                id={contact.id}
                title={contact?.title}
                disabled={!contact?.email}
                checked={contact?.checked}
                handleClick={handleClick}
              />
            }
          />
          {index !== contacts?.length - 1 && !showCheckbox && (
            <Divider variant="fullWidth" component="li" key={index} />
          )}
        </>
      ))}
    </List>
  );
};

export const ContactCheckbox = ({ id, disabled, handleClick, checked, title }) => {
  const { classes } = useStyles();

  return (
    <ListItemIcon classes={{ root: classes.iconRoot }} onClick={() => !disabled && handleClick(id)}>
      <Checkbox
        edge="start"
        checked={checked}
        disableRipple
        inputProps={{ 'aria-labelledby': title }}
        disabled={disabled}
      />
    </ListItemIcon>
  );
};

export const SelectAllCheckbox = ({ handleSelectAll, disabled, checked }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <ListItem alignItems="flex-start" disableGutters classes={{ root: classes.checkbox }}>
      <ListItemIcon classes={{ root: classes.iconRoot }} onClick={() => !disabled && handleSelectAll()}>
        <Checkbox
          edge="start"
          checked={checked}
          disableRipple
          classes={{ root: classes.checkbox }}
          inputProps={{ 'aria-labelledby': t('placement.placementDrawerCotactTab.selectAll') }}
          disabled={disabled}
        />
      </ListItemIcon>
      <ListItemText
        primary={t('placement.placementDrawerCotactTab.selectAll')}
        classes={{
          root: classes.listItemText,
          primary: classes.title,
        }}
      />
    </ListItem>
  );
};
