/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NewUnit } from 'app/models/Unit/CreateUnit';
import {
  ISaveOffContract,
  ISaveRateElementCommand,
  IDisciplineSpecialityRateType,
  IGetRateElement,
  IOffContract,
  ISaveOCBRAttachments,
  IFacilityDetails,
  ISearchResults,
  IContractDetails,
  IAdditionalContractDetails,
  IDisciplineList,
  IDisciplineSpecialityRate,
  IRateElement,
  ISpecialtySubSpecialty,
  IApprovalDocuments,
  IAttachments,
  IUpdateInternalContactCommand,
  IDisplayInternalContact,
  IFacilityRegionUpdateCommand,
  IFacilityRegionEdit,
  IFacilityInstructions,
  ISelectedUnitNavigationDetail,
  IFacilityContractAffiliate,
  IUpdateDrugScreenPolicyCommand,
  IUpdateDrugScreenPolicy,
  IUpdateReferencesCommand,
  IOffContractRateElements,
  IEditInternalContacts,
} from './Facility.model';
import { IEditFacilityRoles } from 'app/models/Facility/FacilityRoles';
import { IBestBets } from '../FacilityDetails/FacilityTabs/GeneralTab/AtAGlance/ManageBestBetsForFacility';

//
// ─── ACTIONS ────────────────────────────────────────────────────────────────────
//
const getFacilityDetailsWithOpenOrderCountAction = createAction<{
  id: number;
}>('GET_FACILITY_DETAILS_WITH_OPEN_ORDER_COUNT');

const getFacilityDetailsAction = createAction<{
  id: number;
}>('GET_FACILITY_DETAILS');

const getUnitsForFacilityAction = createAction<{
  facilityId: number;
}>('GET_UNITS_FOR_FACILITY');

const createUnitAction = createAction<{
  newUnit: NewUnit;
}>('CREATE_UNIT');

const getClientContactAction = createAction<{
  searchString: string;
  pageNumber?: number;
}>('GET_CLIENT_CONTACT');

const searchSkillsetsAction = createAction<{
  keyword: string;
}>('SEARCH_SKILLSETS');

const searchFacilitiesAction = createAction<{
  searchString: string;
  pageNumber?: number;
}>('SEARCH_FACILITIES');

const saveOffContractAction = createAction<{
  offContract: ISaveOffContract;
}>('SAVE_OFF_CONTRACT');

const updateRateElements = createAction<{
  saveRateElementCommand: ISaveRateElementCommand;
  message?: string;
}>('UPDATE_RATE_ELEMENTS');

const requestUpdateInternalContact = createAction<{
  updateInternalContact: IUpdateInternalContactCommand;
  setInternalContact: IDisplayInternalContact;
}>('UPDATE_INTERNAL_CONTACT');

const getFacilityContractAction = createAction<number>('GET_FACILITY_CONTRACT');
const getAdditionalContractAction = createAction<number>('GET_ADDITIONAL_CONTRACT');
const getFacilityDisciplineAction = createAction<number>('GET_FACILITY_DISCIPLINE');
const getDisciplineSpecialityRatesAction = createAction<IDisciplineSpecialityRateType>(
  'GET_DISCIPLINE_SPECIALITY_RATES',
);

const updateReferencesAction = createAction<IUpdateReferencesCommand>('UPDATE_REFERENCES');

const getRateElementsAction = createAction<IGetRateElement>('GET_RATE_ELEMENTS');
const getOffContractRateElements = createAction<IOffContract>('GET_OFF_CONTRACT_RATE_ELEMENTS');
const getApprovalDocuments = createAction<any>('GET_APPROVAL_DOCUMENTS');
const getApprovalDocumentsOCBR = createAction<any>('GET_APPROVAL_DOCUMENTS_OCBR');
const saveOCBRAttachmentsAction = createAction<{ saveAttachments: ISaveOCBRAttachments }>('SAVE_OCBR_ATTACHMENTS');
const getMiscellaneousData = createAction('GET_FACILITY_MISCELLANEOUS_DATA');
const getFacilityRegions = createAction('GET_FACILITY_REGIONS');
const updateFacilityRegionAction = createAction<IFacilityRegionUpdateCommand>('UPDATE_FACILITY_REGION');
const getFacilityInstructions = createAction<number>('GET_FACILITY_INSTRUCTIONS');
const updateFacilityInstructions = createAction<IFacilityInstructions>('UPDATE_FACILITY_INSTRUCTIONS');
const getSelectedUnitDetail = createAction<{ unitId: number }>('GET_SELECTED_UNIT_DETAIL');
const getContractAffiliateData = createAction<number>('GET_CONTRACT_AFFILIATE_DATA');
const getFacilityDrugScreenPolicies = createAction('GET_FACILITY_DRUG_SCREEN_POLICY');
const getFacilitiesForAffiliate = createAction<number>('GET_FACILITIES_FOR_AFFILIATE');

const requestUpdateDrugScreenPolicy = createAction<{
  updateDrugScreenPolicy: IUpdateDrugScreenPolicyCommand;
}>('UPDATE_DRUG_SCREEN_POLICY');

const updateBestBetsForFacility = createAction<IBestBets>('UPDATE_BEST_BETS_FOR_FACILITY');

const initialInternalContactsState = {
  accountCoordinator: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  accountManager: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  billingSpecialist: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  clientAcctgRep: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  clientContractAdmin: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  clientContractSpecialist: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  clinicalDirector: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  credentialingAnalyst: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  credentialingSpecialist: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  customerSupportSpecialist: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  housingAccountExecutive: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  payrollRegion: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  payrollSpecialist: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  regionalDirector: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  timeProcessingSpecialist: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
};

export const initialState: IFacilityDetails = {
  facilityResponse: null,
  openOrdersCount: 0,
  searchResults: {
    count: 0,
    items: [],
    positionCount: 0,
    drawerData: {
      open: false,
      data: undefined,
    },
  },
  contractDetails: null,
  additionalContractDetails: null,
  disciplineList: null,
  selectedDiscipline: null,
  disciplineSpecialityRates: [],
  selectedSpecialtySubSpecialty: {
    disciplineAbbreviation: '',
    specailty: '',
    subSpecialty: '',
  },
  rateElements: {
    rateElements: [],
    shifts: [],
  },
  createdOffContractId: null,
  offContractRateElements: null,
  offContractApprovalDocuments: null,
  updatedOffContractRateElement: false,
  selectedApproveRejectOption: 0,
  uploadedDocuments: [],
  selectedApproveRejectRows: [],
  uploadDocumentModalOpen: false,
  miscellaneous: {
    disciplineOpts: [],
    specialtyOpts: [],
    subSpecialtyOpts: [],
    divisionDisciplineOpts: [],
  },
  facilityDrawerState: {
    open: false,
    tabId: 0,
  },
  internalContacts: { ...initialInternalContactsState },
  editInternalContacts: {
    recruiter: {
      value: null,
      error: null,
      isDirty: false,
      checked: false,
    },
    accountManager: {
      value: null,
      error: null,
      isDirty: false,
      checked: false,
    },
    accountCoordinator: {
      value: null,
      error: null,
      isDirty: false,
      checked: false,
    },
    clinicalDirector: {
      value: null,
      error: null,
      isDirty: false,
      checked: false,
    },
    regionalDirector: {
      value: null,
      error: null,
      isDirty: false,
      checked: false,
    },
    credentialingSpecialist: {
      value: null,
      error: null,
      isDirty: false,
      checked: false,
    },
    credentialingAnalyst: {
      value: null,
      error: null,
      isDirty: false,
      checked: false,
    },
    clientContractSpecialist: {
      value: null,
      error: null,
      isDirty: false,
      checked: false,
    },
    customerSupportSpecialist: {
      value: null,
      error: null,
      isDirty: false,
      checked: false,
    },
    housingAccountExecutive: {
      value: null,
      error: null,
      isDirty: false,
      checked: false,
    },
    payrollSpecialist: {
      value: null,
      error: null,
      isDirty: false,
      checked: false,
    },
    payrollRegion: {
      value: null,
      error: null,
      isDirty: false,
      checked: false,
    },
    timeProcessingSpecialist: {
      value: null,
      error: null,
      isDirty: false,
      checked: false,
    },
    clientAcctgRep: {
      value: null,
      error: null,
      isDirty: false,
      checked: false,
    },
    clientContractAdmin: {
      value: null,
      error: null,
      isDirty: false,
      checked: false,
    },
    billingSpecialist: {
      value: null,
      error: null,
      isDirty: false,
      checked: false,
    },
  },
  isFacilityByIdRequestSuccess: true,
  facilityRegions: [],
  editFacilityRegion: undefined,
  instructions: null,
  isFacilityIntructionsUpdateSucess: false,
  unitNavigationDetails: {
    facilityId: undefined,
    unitId: undefined,
    mode: '',
    standardizedRequirements: false,
  },
  contractAffiliateData: null,
  facilitiesForAffiliate: [],
  facilityDrugScreenPolicies: [],
  unitDrawerState: {
    open: false,
    tabId: 0,
  },
  facilityClientContacts: [],
  httpStatus: {
    statusCode: undefined,
    pageName: undefined,
  },
  tabHeight: 0,
  isTabExpanded: { isInternal: true, isClient: true },
};

const facilitySlice = createSlice({
  name: 'facilityData',
  initialState,
  reducers: {
    setFacilityData(state, action: PayloadAction<IFacilityDetails>) {
      return {
        ...action.payload,
      };
    },
    setSearchResults(state, action: PayloadAction<ISearchResults>) {
      state.searchResults = action.payload;
    },
    setDrawerData(state, action: PayloadAction<{ open: boolean; data: any }>) {
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          drawerData: {
            open: action.payload.open,
            data: action.payload.data,
          },
        },
      };
    },
    setContractDetails(state, action: PayloadAction<IContractDetails | null>) {
      state.contractDetails = action.payload || null;
    },
    setAdditionalContractDetails(state, action: PayloadAction<IAdditionalContractDetails | null>) {
      state.additionalContractDetails = action.payload || null;
    },
    setDisciplineList(state, action: PayloadAction<IDisciplineList[]> | null) {
      state.disciplineList = action.payload || null;
    },
    setSelectedDiscipline(state, action: PayloadAction<IDisciplineList | undefined>) {
      state.selectedDiscipline = action.payload || undefined;
    },
    setDisciplineSpecialityRates(state, action: PayloadAction<IDisciplineSpecialityRate[]> | null) {
      state.disciplineSpecialityRates = action.payload || undefined;
    },
    setRateElements(state, action: PayloadAction<IRateElement> | null) {
      state.rateElements = action.payload || null;
    },
    setSelectedSpecialtySubSpecialty(state, action: PayloadAction<ISpecialtySubSpecialty>) {
      state.selectedSpecialtySubSpecialty = action.payload;
    },
    setOffContract(state, action: PayloadAction<IOffContractRateElements | null>) {
      state.offContractRateElements = action.payload;
    },
    setCreatedOffContractId(state, action: PayloadAction<string | null>) {
      state.createdOffContractId = action.payload;
    },
    setApprovalDocument(state, action: PayloadAction<IApprovalDocuments> | null) {
      state.offContractApprovalDocuments = action.payload;
    },
    setUpdatedOffContract(state, action: PayloadAction<boolean>) {
      state.updatedOffContractRateElement = action.payload;
    },
    setSelectedApproveRejectOption(state, action: PayloadAction<number>) {
      state.selectedApproveRejectOption = action.payload;
    },
    setUploadedDocuments(state, action: PayloadAction<IAttachments[]>) {
      state.uploadedDocuments = action.payload;
    },
    setSelectedRejectedRows(state, action: PayloadAction<any>) {
      state.selectedApproveRejectRows = action.payload;
    },
    setUploadDocumentModalOpen(state, action: PayloadAction<boolean>) {
      state.uploadDocumentModalOpen = action.payload;
    },
    setMiscellaneousData(
      state,
      action: PayloadAction<
        { key: 'disciplineOpts' | 'specialtyOpts' | 'subSpecialtyOpts' | 'divisionDisciplineOpts'; data }[]
      >,
    ) {
      const miscellaneous = action.payload.reduce((results, { key, data }) => ({ ...results, [key]: data }), {});
      return {
        ...state,
        miscellaneous: {
          ...state.miscellaneous,
          ...miscellaneous,
        },
      };
    },
    setFacilityPageLoading(state, action: PayloadAction<boolean>) {
      state.isFacilityByIdRequestSuccess = action.payload;
    },
    setFacilityDrawerVisibility(state, action: PayloadAction<{ open: boolean; tabId: number }>) {
      state.facilityDrawerState = action.payload;
    },
    setInternalContacts(state, action: PayloadAction<IEditFacilityRoles>) {
      state.internalContacts = action.payload;
    },
    setFacilityInstructionsSuccess(state, action: PayloadAction<boolean>) {
      state.isFacilityIntructionsUpdateSucess = action.payload;
    },
    editInternalContact(state, action) {
      return {
        ...state,
        editInternalContacts: {
          ...state.editInternalContacts,
          [action.payload.key]: action.payload.data,
        },
      };
    },
    removeInternalContactHelperText(state, action) {
      return {
        ...state,
        editInternalContacts: {
          ...state.editInternalContacts,
          [action.payload]: {
            ...state.editInternalContacts[action.payload],
            helperText: undefined,
          },
        },
      };
    },
    setInternalContactUpdateError(state, action) {
      return {
        ...state,
        editInternalContacts: {
          ...state.editInternalContacts,
          [action.payload.key]: {
            ...state.editInternalContacts[action.payload.key],
            error: action.payload.error,
          },
        },
      };
    },
    setFacilityRegions(state, action) {
      state.facilityRegions = action.payload;
    },
    setFacilityRegion(state, action) {
      state.facilityResponse.location.region = action.payload;
    },
    editFacilityRegion(state, action: PayloadAction<IFacilityRegionEdit | undefined>) {
      return {
        ...state,
        editFacilityRegion: action.payload,
      };
    },
    setInstructionsData(state, action: PayloadAction<IFacilityInstructions>) {
      state.instructions = action.payload;
    },
    setUnitNavigationDetails(state, action: PayloadAction<ISelectedUnitNavigationDetail>) {
      state.unitNavigationDetails = action.payload;
    },
    setSaveModalState(state, action: PayloadAction<{ open: boolean; data: any }>) {
      return {
        ...state,
        saveModalState: {
          open: action.payload.open,
          data: action.payload.data,
        },
      };
    },
    setFacilityRegionModalState(state, action: PayloadAction<{ open: boolean; data: any }>) {
      return {
        ...state,
        facilityRegionModalState: {
          open: action.payload.open,
          data: action.payload.data,
        },
      };
    },
    setContractAffiliateData(state, action: PayloadAction<IFacilityContractAffiliate | undefined>) {
      state.contractAffiliateData = action.payload;
    },
    setFacilityDrugScreenPolicies(state, action: PayloadAction<IUpdateDrugScreenPolicy[] | undefined>) {
      state.facilityDrugScreenPolicies = action.payload;
    },
    setFaciliteisForAssociate(
      state,
      action: PayloadAction<
        {
          id: number;
          facilityId: number;
          facilityName: string;
        }[]
      >,
    ) {
      state.facilitiesForAffiliate = action.payload;
    },
    setUnitDrawerVisibility(state, action: PayloadAction<{ open: boolean; tabId: number }>) {
      state.unitDrawerState = action.payload;
    },
    setFacilityClientContacts(state, action: PayloadAction<any[]>) {
      state.facilityClientContacts = action.payload;
    },
    setHttpStatus(
      state,
      action: PayloadAction<{
        statusCode: number | undefined;
        pageName: string | undefined;
      }>,
    ) {
      state.httpStatus = action.payload;
    },
    setTabHeight(state, action: PayloadAction<number>) {
      state.tabHeight = action.payload;
    },
    setIsTabExpanded(state, action: PayloadAction<{ type: 'isInternal' | 'isClient'; value: boolean }>) {
      state.isTabExpanded = { ...state.isTabExpanded, [action.payload.type]: action.payload.value };
    },
    setEditInternalContacts(state, action: PayloadAction<IEditInternalContacts>) {
      return {
        ...state,
        editInternalContacts: {
          ...action.payload,
        },
      };
    },
  },
});

export const { name: facilitySliceKey, reducer: facilityReducer } = facilitySlice;

export const facilityActions = {
  ...facilitySlice.actions,
  getFacilityDetailsWithOpenOrderCountAction,
  getFacilityDetailsAction,
  getFacilityContractAction,
  getAdditionalContractAction,
  getUnitsForFacilityAction,
  createUnitAction,
  getClientContactAction,
  searchSkillsetsAction,
  searchFacilitiesAction,
  getFacilityDisciplineAction,
  getDisciplineSpecialityRatesAction,
  getRateElementsAction,
  saveOffContractAction,
  getOffContractRateElements,
  getApprovalDocuments,
  updateRateElements,
  requestUpdateInternalContact,
  saveOCBRAttachmentsAction,
  getMiscellaneousData,
  getApprovalDocumentsOCBR,
  getFacilityRegions,
  updateFacilityRegionAction,
  getFacilityInstructions,
  updateFacilityInstructions,
  getSelectedUnitDetail,
  getContractAffiliateData,
  requestUpdateDrugScreenPolicy,
  getFacilityDrugScreenPolicies,
  updateReferencesAction,
  getFacilitiesForAffiliate,
  updateBestBetsForFacility,
};

export type { IFacilityDetails };
