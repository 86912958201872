import { IVICardView } from '@AMIEWEB/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/Statuses/InterviewStatus/VirtualInterview/InterviewSpecs';
import { ITypeAheadOption } from '../Orders/OrderDetails';
import { DirectHireDetails, IPlacementSubmissionPacketDetail } from './PlacementDetails';
import { IOperationContainer } from '@AMIEWEB/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/GroupedRequirements/types';

export interface IPlacementSummary {
  orderInformation: IOrderInfo;
  submissionInfo: ISubmissionInfo & reqFilterType;
  interviewInfo: IIntetrviewInfo & reqFilterType;
  offerInfo: IOfferInfo & reqFilterType;
  bookingInfo: IBookingInfo & reqFilterType;
  assignmentInformation: IAssignmentInfo;
  extensionsInfo: IExtensionInfo[];
  selectedDirectHireDetail: DirectHireDetails;
  serviceFailures: IServiceFailure;
  auditDetailsInfo: IAuditDetailsInfo[];
  referenceAndEmploymentVerificationRecords: any[];
  editableRequirementTypes: IPlacementEditableRequirements;
  peopleSoft: IPeopleSoft;
  submissionPacketDetail: IPlacementSubmissionPacketDetail;
  classicPacketInOkToSendExists: boolean;
}

export interface IPlacementEditableRequirementDeps {
  requirement?: requirementType;
  // Set to true if this requirement depends on other requirements
  requiredDependency?: boolean;
}

export interface IPlacementEditableRequirements {
  availabilityDate?: IPlacementEditableRequirementDeps;
  placementDate?: IPlacementEditableRequirementDeps;
  shiftOffered?: IPlacementEditableRequirementDeps;
  datesConfirmed?: IPlacementEditableRequirementDeps;
  dnsCleared?: IPlacementEditableRequirementDeps;
  travelConfirmed?: IPlacementEditableRequirementDeps;
  transportation?: IPlacementEditableRequirementDeps;
  travelTimePreference?: IPlacementEditableRequirementDeps;
  dob?: IPlacementEditableRequirementDeps;
  gender?: IPlacementEditableRequirementDeps;
  ssn?: IPlacementEditableRequirementDeps;
  departureAirport?: IPlacementEditableRequirementDeps;
  departureItineraryLink?: IPlacementEditableRequirementDeps;
  facilityStatus?: IPlacementEditableRequirementDeps;
  arrivalDate?: IPlacementEditableRequirementDeps;
  arrivalTime?: IPlacementEditableRequirementDeps;
  orderDob?: IPlacementEditableRequirementDeps;
  orderSsn?: IPlacementEditableRequirementDeps;
  virtualInterview?: IPlacementEditableRequirementDeps;
  extensions?: {
    extensionTimeOff?: IPlacementEditableRequirementDeps;
  }[];
}

export enum requirementType {
  null = -1,
  pass = 1,
  fail,
  risk,
}

export interface IRequirement {
  operation?: string;
  type: requirementType;
  data?: any;
}

export interface IRequirementCounts {
  aggregate: number;
  passed: number;
  failed: number;
  risks: number;
}

export interface IRequirementRowPadding {
  hasRequirements: boolean;
}

type ReqCountObjectType = { reqCounts: IRequirementCounts };

type IRequirementType = Omit<IRequirement, 'data'>;

export interface IExtensionInfo {
  extensionStatusId: number;
  extensionStatus: string;
  startDate: string;
  endDate: string;
  duration: number;
  timeOffRequest: {
    timeOffRequestDetails: {
      startDate: string;
      endDate: string;
      dailyCost: number;
      placementTimeOffPayerId: number;
      placementTimeOffPayer: string;
      facilityApprovedBy: string;
      days: number;
      hours: number;
    }[];
    timeOffRequestReason: number;
  };
  notes: string;
  amnHousing: boolean;
  updatedBy: string;
  updatedById: number;
  updatedAt: string;
  isSentCueNote: boolean;
  cueRecipient: { recipientId: number | null };
}
export interface IConfirmation {
  checked: boolean;
  lastUpdatedById: number | null;
  lastUpdatedBy: string | null;
  lastUpdatedAt: string | null;
}

export interface IAssignmentInfo {
  placementId: number | null;
  placementStatusId: number | null;
  placementStatus: string | null;
  startDate: string | null;
  endDate: string | null;
  endDateTravelerOptions: IAssignmentInfoEndDateTravelerOptions[];
}

interface IAssignmentInfoEndDateTravelerOptions {
  endDate: string | null;
  effectiveDate: string | null;
  approvalStatusId: number | null;
  voidStatus: boolean | null;
}

export interface IOrderInfo {
  numberOfWeeks: any;
  startDate: string | null;
  endDate: string | null;
  numOfWeeks: number | null;
  orderType: string | null;
  contractBillRate: number | null;
  orderBillRate: number | null;
  vmsBillRate: number | null;
  expectedHours: number | null;
  orderGWW: number | null;
  contractGWW: boolean | null;
  weeklyGrossPay: number | null;
  requiredQualifications: string | null;
  preferredQualifications: string | null;
  internalNotes: string | null;
  float: IOrderInfoFloat | null;
  flexibleSchedule: IOrderInfoFlexibleSchedule | null;
  orderShifts: string[] | null;
  orderPriorities: IOrderInfoOrderPriorityType[] | null;
  division: string | null;
  positionsAvailable: number | null;
  unitId: number | null;
}

interface IOrderInfoFloat {
  isRequired: boolean | null;
  floatToMiles: number | null;
  floatToUnit: string | null;
  floatToLocation: string | null;
}

interface IOrderInfoFlexibleSchedule {
  isRequired: boolean | null;
  workDuringWeekend: number | null;
  workDuringHoliday: number | null;
}

interface IOrderInfoOrderPriorityType {
  id: number;
  description: string;
}

export interface IPeopleSoft {
  lastSync: string;
  syncStatus: requirementType;
  syncStatusLabel: string;
  description: string;
  awaitingResultsCount: number;
}

export interface ISubmissionInfo {
  amnReq: ReqCountObjectType & {
    amn: {
      skillset: Omit<IRequirement, 'data'> & {
        data: { verifiedByCmType: requirementType; strikeMatchType: requirementType };
      };
      status: IRequirement | null;
      audit: IRequirement | null;
      education: IRequirement | null;
      experience: IRequirement | null;
      workHistory: IRequirement | null;
      references: string;
      skillsCheckList: IRequirement | null;
      application: any | null;
    };
    candidate: {
      skillset: any;
      status: any;
      audit: any;
      education: any;
      experience: any;
      workHistory: any;
      references: any;
      skillsCheckList: any;
      application: any;
      distance: any;
    };
  };
  orderReq: ReqCountObjectType & {
    order: {
      skillset: IRequirement | null;
      status: any;
      facilityStatus: any;
      dates: IRequirement | null;
      schedule: any;
      experience: IRequirement | null;
      workHistory: any;
      references: IRequirement | null;
      skillsCheckList: IRequirement | null;
      licenses: IRequirement | null;
      certifications: any;
      milesRadius: IRequirement | null;
      miceDetails: any;
      interview: any;
      covid: any;
      dob: any;
      ssn: any;
      documents: any;
      orderDob: IRequirementCheck;
      orderSsn: IRequirementCheck;
      virtualInterview: IRequirementCheck;
    };
    candidate: {
      skillset: any;
      status: any;
      dates: any;
      schedule: any;
      experience: any;
      workHistory: any;
      references: any;
      skillsCheckList: any;
      licenses: any;
      certifications: any;
      milesRadius: any;
      miceDetails: any;
      interview: any;
      covid: any;
      dob: any;
      ssn: any;
      documents: any;
    };
  };
  skillReq: ReqCountObjectType & IGroupedSkillsetRequirement;
}

export interface ISkillsetRequirement {
  skillset: {
    amnRequirement: IRequirement | null;
    orderRequirement: IRequirement | null;
    candidateRequirement: IRequirement | null;
  };
  experience: {
    amnRequirement: IRequirement | null;
    orderRequirement: IRequirement | null;
    candidateRequirement: IRequirement | null;
  };
  licenses: {
    amnRequirement: IRequirement | null;
    orderRequirement: IRequirement | null;
    candidateRequirement: IRequirement | null;
  };
  certifications: {
    amnRequirement: IRequirement | null;
    orderRequirement: IRequirement | null;
    candidateRequirement: IRequirement | null;
  };
}

export interface IGroupedSkillsetRequirement {
  containers: IOperationContainer | null;
  containerRegistry: {
    [key: string]: ISkillsetRequirement;
  };
}

export interface IRequirementCheck extends IRequirement {
  messageId?: number;
  messageVersion?: string;
  isValidated?: boolean;
  isRisk?: boolean;
}

export interface IIntetrviewInfo {
  virtualInterview: ReqCountObjectType & {
    order: IRequirementType & {
      data: IOrderVirtualInterviewData | null;
      view: {
        rating: string;
        vaAutoOffer: string;
        skillset: string;
        matchingSkillset: string;
      };
    };
    candidate: (ICandidateVirtualInterviewData & { cardView: IVICardView })[];
  };
  interview: ReqCountObjectType & {};
  interviewScheduled: ReqCountObjectType & {};
}

interface ISkillSet {
  disciplineId: number;
  disciplineAbbr: string;
  specialtyId: number;
  specialtyAbbr: string;
}

export interface IOrderVirtualInterviewData {
  rating: {
    orderPriorityType: string;
  }[];
  isVaAutoOffer: boolean;
  isVoiceAdvantage: boolean;
  placementSkillset: ISkillSet[];
  placementAllowableMismatch: ISkillSet[];
  virtualRatingSubmissionRequirement?: boolean;
}

export interface ICandidateVirtualInterviewData {
  candidateInterviewItem: {
    disciplineId: number;
    disciplineAbbr: string;
    specialtyId: number;
    specialtyAbbr: string;
    isAllowableMatch: boolean;
    interviewReason: string;
    interviewStatus: string;
    interviewRating: string;
    inviteId: number;
    travelerInterviewRatingId: number;
    invitationDate: string;
    statusDate: string;
    interviewDate: string;
    changedDate: string;
    updatedBy: string;
  };
  candidateInterviewHistory: {
    fieldChangedId: number;
    fieldChanged: string;
    oldValue: string;
    newValue: string;
    changedBy: string;
    changedDate: string;
  }[];
}

interface IOfferInfo {
  confirmation: ReqCountObjectType & IOfferConfirmation;
  transportation: ReqCountObjectType & ITransportation;
  strikeTravelDetails: IStrikeTravelDetails;
}

interface IBookingInfo {
  confirmation: ReqCountObjectType & IBookingConfirmation;
  credentialing: ReqCountObjectType & IBookingCredentialing;
}

export interface ITransportation {
  gender: IRequirementType & { data: 'F' | 'M' | null };
  transportation: IRequirementType & {
    data: {
      transportation: string | null;
      transportationId: number | null;
    };
  };
  travelTimePreference: IRequirementType & {
    data: {
      travelTimePreference: 'AM' | 'PM' | 'No Preference' | null;
      travelTimePreferenceId: number | null;
    };
  };
  dob: IRequirementType;
  ssn: IRequirementType;
  departureAirport: IRequirementType & { data: string | null };
  departureItineraryLink: IRequirementType & { data: string | null };
  arrivalDate: IRequirementType & { data: string | null };
  arrivalTime: IRequirementType & { data: string | null };
  picklists: IPickLists;
}

export interface IStrikeTravelDetails {
  travelFromStrike: ITravelDetails & { departureDate: string; departureTime: string };
  travelToStrike: ITravelDetails & { arrivalDate: string; arrivalTime: string };
}

export interface ITravelDetails {
  statusOfTravel: string;
  airline: string;
  flightNumber: string;
  departureAirport: string;
  arrivalAirport: string;
  itineraryLink: string;
  arrivalDate: string;
  arrivalTime: string;
}

export interface IOfferConfirmation {
  datesConfirmed: (IRequirementType & { data: IConfirmation }) | null;
  dnsCleared: (IRequirementType & { data: IConfirmation }) | null;
  travelConfirmed: (IRequirementType & { data: IConfirmation }) | null;
}

export interface IBookingCredentialing {
  gtgComplete: (IRequirementType & { data: IConfirmation }) | null;
  finalizedByQs: (IRequirementType & { data: IConfirmation }) | null;
}

export interface IBookingConfirmation {
  arrived: (IRequirementType & { data: IConfirmation }) | null;
  directDeposit: (IRequirementType & { data: IConfirmation }) | null;
  wisely: (IRequirementType & { data: { checked: boolean | null; lastUpdatedAt: string | null } }) | null;
}

export interface IServiceFailure {
  /**Submission - amn - requirements  */
  skillsetAmnRequirement?: boolean;
  statusAmnRequirement?: boolean;
  auditAmnRequirement?: boolean;
  educationAmnRequirement?: boolean;
  experienceRequirement?: boolean;
  workHistorySubmissionRequirement?: boolean;
  referenceAmnRequirement?: boolean;
  skillsChecklistRequirement?: boolean;
  applicationAmnRequirement?: boolean;
  strikeSkillsetAmnRequirement?: boolean;

  /**Submission - order - requirements  */
  skillsetOrderRequirement?: boolean;
  statusOrderRequirement?: boolean;
  datesOrderRequirement?: boolean;
  scheduleOrderRequirement?: boolean;
  workHistoryOrderRequirement?: boolean;
  referenceOrderRequirement?: boolean;
  licenseOrderRequirement?: boolean;
  certificationOrderRequirement?: boolean;
  milesRadiusOrderRequirement?: boolean;
  miceDetailsOrderRequirement?: boolean;
  interviewOrderRequirement?: boolean;
  covidOrderRequirement?: boolean;
  dobOrderRequirement?: boolean;
  ssnOrderRequirement?: boolean;
  documentsOrderRequirement?: boolean;
  facilityStatusOrderRequirement?: boolean;
  dobStatusOrderRequirement?: boolean;
  ssnStatusOrderRequirement?: boolean;
  assignmentInformationRequirement?: boolean;

  /** Order information  */
  orderInformationRequirement?: boolean;

  /**Offer Info */
  offerTransportation?: boolean;
  offerConfirmationRequirement?: boolean;
  offerTravelDetailsRequirement?: boolean;
  airportCodes?: boolean;
  transportationOpts?: boolean;
  travelTimePreferenceOpts?: boolean;

  /** Interview */
  virtualInterviewRequirement?: boolean;

  /**Grouped skillset */
  groupedSkillsetRequirement?: boolean;

  /*candiate requirement */
  arbitrationAgreementRequirements?: boolean;

  /*Booking  */
  bookingConfirmationRequirement?: boolean;
  bookingCredentialingRequirement?: boolean;
  bookingRequirements?: boolean;
  bookingApprovedRequirements?: boolean;
  waitingGoodToGoRequirements?: boolean;
}

export interface ITravelTimePrference extends Omit<ITypeAheadOption, 'object'> {
  object: { travelTimePreferenceId: number | null; travelTimePreference: string | null };
}

export interface IGender extends Omit<ITypeAheadOption, 'object'> {
  object: 'F' | 'M' | null;
}

export interface IDepartureAirport extends Omit<ITypeAheadOption, 'object'> {
  object: string | null;
}

export interface IPickLists {
  transportationOpts: { transportationId: number; transportation: string }[];
  travelTimePreferenceOpts: ITravelTimePrference[];
  airportCodes: IDepartureAirport[];
}

export type IPickListsKey = keyof IPickLists;

export type IServiceFailureKey = keyof IServiceFailure;

export type ISubmissionAmnRequirementKey =
  | 'skillset'
  | 'strikeSkillset'
  | 'status'
  | 'audit'
  | 'education'
  | 'experience'
  | 'workHistory'
  | 'references'
  | 'skillsCheckList'
  | 'application'
  | 'distance';

export type ISubmissionOrderRequirementKey =
  | 'skillset'
  | 'status'
  | 'dates'
  | 'schedule'
  | 'experience'
  | 'workHistory'
  | 'references'
  | 'skillsCheckList'
  | 'licenses'
  | 'certifications'
  | 'milesRadius'
  | 'miceDetails'
  | 'interview'
  | 'covid'
  | 'dob'
  | 'ssn'
  | 'documents'
  | 'facilityStatus'
  | 'orderDob'
  | 'orderSsn'
  | 'virtualInterview';

export interface IUpdateStrikePlacementProps {
  transportationId?: number;
  transportation?: string;
  travelTimePreferenceId?: number;
  travelTimePreference?: 'AM' | 'PM' | 'No Preference' | null;
  gender?: 'F' | 'M' | null;
  departureAirport?: string | null;
  arrivalDate?: string | null;
  arrivalTime?: string | null;
  datesConfirmed?: IConfirmation;
  dnsCleared?: IConfirmation;
  travelConfirmed?: IConfirmation;
}

export interface IAuditDetailsInfo {
  status: string | null;
  holdFileOut: string | null;
  strikeApproved: string | null;
  auditType: string | null;
  auditCategory: string | null;
  auditSubCategory: string | null;
  auditAction: string | null;
  personResponsible: string | null;
  description: string | null;
  placementId: number | null;
  insertDate: string | null;
  insertedByUser: string | null;
  lastUpdated: string | null;
  lastUpdatedByUser: string | null;
  actionable: string | null;
  group: string | null;
}

/** Requirements filter - Start */
export type RequirementFilterKey =
  | 'submission'
  | 'submission-amn'
  | 'submission-order'
  | 'interview'
  | 'interview-virtual'
  | 'offer'
  | 'offer-confirmation'
  | 'offer-transportation'
  | 'booking'
  | 'booking-confirmation'
  | 'booking-credentialing'
  | string;

export interface IRequirementTreeFilter {
  key: RequirementFilterKey;
  filter: requirementType[];
  children?: IRequirementTreeFilter[];
}

export type reqFilterType = { requirementFilter: IRequirementTreeFilter | null };
/** Requirements filter - End */
