import { PayloadAction } from '@reduxjs/toolkit';
import { trackEvent, trackException } from 'app-insights/appInsightsTracking';
import { ChannelType, HubEventType } from 'app/components/Notification/Constants';
import {
  ConsentType,
  HubEventListenerProps,
  IBulkSmscandidateConsent,
  ICandidateBrand,
  ICommunicationLimitsPayload,
  IInboxQuery,
  IInboxReadStatusCommand,
  ISaveTemplate,
  ITemplatesByCategory,
  IUnreadSmsCount,
  IUpdateTemplate,
} from 'app/models/Notification/Notification';
import _ from 'lodash';
import { put, select, takeEvery, takeLatest, call, getContext } from 'redux-saga/effects';
import { notificationDataActions } from './notification.redux';
import {
  notificationSelection,
  selectCommunicationLimitsSms,
  selectPinnedMessages,
} from 'store/redux-store/notification/notification.selector';
import { selectUser } from 'oidc/user.selectors';
import { IMessageAttachment } from 'app/models/Sms/ISmsResult';
import { checkCandidatesConsents } from '@AMIEWEB/Candidate/CandidateProfile/Profile/helper';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { SmsType } from 'app/enums/Sms';
import {
  manuallyDecrementPromiseCounter,
  manuallyIncrementPromiseCounter,
  manuallyResetPromiseCounter,
  trackPromise,
} from 'react-promise-tracker';
import { selectCoveredInbox } from '../user-coverage/selectors';
import { coverageActions } from '../user-coverage/async-actions';
import { ExceptionType, SignalREventType } from 'app/enums/Common';
import { selectFailedServices } from 'app/ApplicationRoot/Global.selectors';
import { FailedServices } from 'app/ApplicationRoot/types';
import { httpSuccess } from 'app/services/serviceHelpers';
import { TemplateUserTypes } from '@AMIEWEB/Common/SubmissionPreference/Common/helper';
import i18next from 'i18next';
import { TemplateService } from 'app/services/TemplateService/template-service';
import { NotificationService } from 'app/services/NotificationServices/notification-service';

const TrackWrapper = (fn, ...args) => trackPromise(fn(...args), 'notification-saga');
const TrackEmailConsents = (fn, ...args) => trackPromise(fn(...args), 'email-consents-saga');
const TrackBulkSmsConsents = (fn, ...args) => trackPromise(fn(...args), 'bulk-sms-consents-saga');
const TrackDeleteTemplate = (fn, ...args) => trackPromise(fn(...args), 'delete-template-saga');
const TrackSMSInbox = (fn, ...args) => trackPromise(fn(...args), 'sms-inbox-saga');
const TrackCommunicationLimits = (fn, ...args) => trackPromise(fn(...args), 'communication-limits-saga');
const TrackRequestBaseTemplate = (fn, ...args) => trackPromise(fn(...args), 'request-base-template');
const TrackRequestFacilityTemplates = (fn, ...args) => trackPromise(fn(...args), 'request-facility-templates');
const TrackCreateFacilityTemplate = (fn, ...args) => trackPromise(fn(...args), 'create-facility-template');
const TrackGetTemplateByCategory = (fn, ...args) => trackPromise(fn(...args), 'get-template-by-category');
const TrackUpdatesFacilityTemplate = (fn, ...args) => trackPromise(fn(...args), 'update-facility-templaUpdates');
const TrackUnreadCount = (fn, ...args) => trackPromise(fn(...args), 'unread-count-query');
const TrackInboxReadStatus = (fn, ...args) => trackPromise(fn(...args), 'send-inbox-read-status');

export function* HubEventListener(action: PayloadAction<HubEventListenerProps>) {
  try {
    const { eventName, log, coverageEnabled } = action.payload;

    const notificationData = yield select(notificationSelection);
    const { coveredInboxes, activeInbox } = yield select(selectCoveredInbox);
    const user = yield select(selectUser);

    const response = { chathistory: [], inboxMessages: [] };

    const chatData = notificationData.sms.chatHistory?.chats;

    const activeSmsData = notificationData.sms;
    if (activeSmsData.minimized) {
      if (activeSmsData.data?.tos.find(Boolean)?.contactId === log.message.tos.find(Boolean)?.contactId) {
        yield put(notificationDataActions.updateUnreadCount(activeSmsData.activeUnreadCount + 1));
      }
    }

    if (eventName === HubEventType.InboundSms) {
      //*Deals with unreadCount
      if (
        !notificationData?.sms?.open ||
        (notificationData?.sms?.open &&
          (notificationData?.sms?.data?.tos?.[0].phoneNumber !== log?.message?.tos?.[0]?.phoneNumber ||
            (notificationData?.sms?.data?.tos?.[0].phoneNumber !== log?.message?.tos?.[0]?.phoneNumber &&
              activeInbox?.phoneNumber !== log?.message?.sender?.phoneNumber)))
      ) {
        yield call(getUnreadCountSaga);
      }

      //Updating Inbox with latest message:

      //*Deals with coverage -> inboxCoverage -> coveredInboxes
      const sender = log.message.sender.userId;
      const senderInboxIndex = coveredInboxes?.findIndex(item => item?.coveredUserId === sender);
      const candidateRecordIndex = coveredInboxes?.[senderInboxIndex]?.unreadLogs?.findIndex(
        item => item?.contactId === log.message.tos?.[0]?.contactId,
      );
      //if senderInbox's unreadLogs inside coveredInboxes have this record
      if (candidateRecordIndex !== -1) {
        const candidateRecord = {
          ...coveredInboxes?.[senderInboxIndex]?.unreadLogs?.[candidateRecordIndex],
          unreadLogsCount: coveredInboxes?.[senderInboxIndex]?.unreadLogs?.[candidateRecordIndex]?.unreadLogsCount + 1,
        };
        const updatedUnreadLogs = coveredInboxes?.[senderInboxIndex]?.unreadLogs?.map((item, index) =>
          index === candidateRecordIndex ? candidateRecord : item,
        );
        const updatedSenderInbox = { ...coveredInboxes?.[senderInboxIndex], unreadLogs: updatedUnreadLogs };
        const updatedCoverage = coveredInboxes?.map((item, index) =>
          index === senderInboxIndex ? updatedSenderInbox : item,
        );
        yield put(coverageActions.setInboxCoverage(updatedCoverage));
      }
      //if senderInbox's unreadLogs inside coveredInboxes does not have this record
      else {
        const item = {
          contactId: log?.message?.tos?.[0]?.contactId,
          phoneNumber: log?.message?.tos?.[0]?.phoneNumber,
          unreadLogsCount: 1,
        };
        const unreadArr = [...coveredInboxes[senderInboxIndex].unreadLogs, item];
        const coverage = coveredInboxes?.map((item, index) => {
          return index === senderInboxIndex ? { ...item, unreadLogs: unreadArr } : item;
        });
        yield put(coverageActions.setInboxCoverage(coverage));
      }

      /* updating inbox message with latest message for existing candidate */
      //* Deals with notificationData -> inbox
      const updatedInboxMessage = _.cloneDeep(notificationData.inbox) || [];
      var existingCandidate =
        updatedInboxMessage &&
        updatedInboxMessage.find(x =>
          x.travelerId !== ''
            ? x.travelerId === log.message.tos.find(Boolean)?.contactId
            : x.name === log.message.tos.find(Boolean)?.phoneNumber,
        );

      if (coveredInboxes?.length < 2 || activeInbox?.phoneNumber === log?.message?.sender?.phoneNumber) {
        //if inbox have any existing record
        if (existingCandidate) {
          const elementIndex = updatedInboxMessage.findIndex(el => el === existingCandidate);
          updatedInboxMessage.splice(elementIndex, 1);
          updatedInboxMessage.unshift(existingCandidate);

          // Checking if SMS modal is open and matches the incoming messages
          // Incrementing the unread log count if not matched with open sms modal
          // Marking unread count to be 0 in case the modal is open
          existingCandidate.unreadLogs =
            notificationData.sms.open &&
            log.message.tos.find(Boolean).phoneNumber === notificationData.sms?.data?.tos.find(Boolean)?.phoneNumber
              ? 0
              : existingCandidate.unreadLogs + 1;
          existingCandidate.log = log;
          existingCandidate.publishedOn = log.publishedOn;
        }
        //if inbox does not have this record
        else {
          //create a new record in inbox
          updatedInboxMessage &&
            updatedInboxMessage.unshift({
              name: log.message.tos.find(Boolean)?.name ?? log.message.tos.find(Boolean)?.phoneNumber,
              travelerId: log.message.tos.find(Boolean)?.contactId ?? '',
              log: log,
              unreadLogs: 1,
            });
        }

        yield put(notificationDataActions.updateInbox(updatedInboxMessage));
        response.inboxMessages = updatedInboxMessage;
      }
      //Updating open sms message
      if (notificationData.sms.open || notificationData.sms.minimized) {
        const updatedGlobalMessageSms = notificationData.sms;
        if (
          log.message.tos.find(Boolean).phoneNumber === updatedGlobalMessageSms?.data?.tos.find(Boolean)?.phoneNumber
        ) {
          const duplicateLog = _.cloneDeep(log);
          duplicateLog.message.attachments = (duplicateLog.message.attachmentUrls as string[]).map<IMessageAttachment>(
            url => ({
              signedUrl: url,
              contentUrl: url.split('?')[0],
              thumbnailUrl: null,
              dateDeliveredToKofax: null,
            }),
          );
          const updatedChtResults = [...chatData!.results, duplicateLog];
          const updatedChatData = {
            ...chatData,
            results: updatedChtResults,
          };
          yield put(
            notificationDataActions.updateChatHistory({
              ...updatedGlobalMessageSms.chatHistory,
              chats: _.cloneDeep(updatedChatData),
            }),
          );
        }
      }

      if (coverageEnabled) {
        const coveringEmployeeId = log?.associatedRecords?.find(x => x.name === 'coveringEmployeeId')?.value;
        if (coveringEmployeeId && Number(coveringEmployeeId) === user?.userInfo?.employeeId) {
          trackEvent({
            type: 'event',
            name: `SMS Coverage`,
            properties: {
              EventType: SignalREventType.CoverageInboundSMS,
              Date: log?.publishedOn,
              CoveringUser: coveringEmployeeId,
              CoveredUser: log?.message?.sender?.userId,
              Description: `Coverage - Inbound message for covered user: ${log?.message?.sender?.userId}, received by covering user: ${coveringEmployeeId}`,
            },
          });
        }
      }
    }

    /*Updating the message error reponse after publish*/
    if (eventName === HubEventType.OutboundSmsProcessError) {
      //Updating open sms message status
      const updatedGlobalMessageSms = _.cloneDeep(notificationData.sms);
      const index = chatData?.results?.findIndex(x => x.id === log.id)!;
      if (chatData!.results[index]?.deliveryStatus !== 'delivered') {
        let updatedChatData = _.cloneDeep(chatData);
        let updatedResults = [...updatedChatData.results];
        updatedResults[index] = { ...log, deliveryStatus: 'Error' };
        updatedChatData = { ...updatedChatData, results: updatedResults };
        yield put(
          notificationDataActions.updateChatHistory({
            ...updatedGlobalMessageSms.chatHistory,
            chats: _.cloneDeep(updatedChatData),
          }),
        );
      }
    }
    //TODO - Yugo Team (Find out the root cause for Communications History Data being pushed to messageStack)
    if (eventName === HubEventType.OutboundSmsDelivery) {
      const updatedGlobalMessageSms = _.cloneDeep(notificationData.sms);
      const index = chatData?.results.findIndex(x => x.id === log.id)!;
      if (chatData!.results[index]?.deliveryStatus !== 'delivered') {
        const duplicateLog = _.cloneDeep(log);
        duplicateLog.message.attachmentUrls = _.cloneDeep(chatData!.results[index].message.attachmentUrls);
        duplicateLog.message.attachments = (duplicateLog.message.attachmentUrls as string[]).map<IMessageAttachment>(
          url => ({
            signedUrl: url,
            contentUrl: url.split('?')[0],
            thumbnailUrl: null,
            dateDeliveredToKofax: null,
          }),
        );
        let chatDataCopy = _.cloneDeep(chatData);
        chatDataCopy!.results[index] = duplicateLog;
        yield put(
          notificationDataActions.updateChatHistory({
            ...updatedGlobalMessageSms.chatHistory,
            chats: _.cloneDeep(chatDataCopy),
          }),
        );
      }
    }
  } catch (error) {
    trackEvent({
      type: 'event',
      name: 'Updating notification inbox data failed',
    });
    console.log('ERROR', error);
  }
}

export function* getCandidateConsentsSagaAction(
  action: PayloadAction<{ candidateIds: Array<string>; brandId: any; consentType: ConsentType }>,
) {
  try {
    const notificationService: NotificationService = yield getContext('notificationService');
    const response = yield call(TrackWrapper, notificationService.getCandidateConsents, action.payload.candidateIds);
    if (httpSuccess(response.status)) {
      const candidateConsents = checkCandidatesConsents(
        response?.data?.[0],
        action.payload.brandId,
        action.payload.candidateIds?.[0],
      );

      if (action.payload.consentType === ConsentType.candidateDetails) {
        yield put(notificationDataActions.setConsent(candidateConsents));
      } else if (action.payload.consentType === ConsentType.sidePanels) {
        yield put(notificationDataActions.setSidePanelConsent(candidateConsents));
      } else if (action.payload.consentType === ConsentType.smsModal) {
        yield put(notificationDataActions.setSmsConsents(candidateConsents));
      }
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getCandidateConsentsSagaAction',
        area: 'src/store/redux-store/notification/notification.saga.ts',
      },
    });
  }
}

export function* getBulkSmsConsentsSaga(action: PayloadAction<IBulkSmscandidateConsent>) {
  try {
    const notificationService: NotificationService = yield getContext('notificationService');
    const response = yield call(
      TrackBulkSmsConsents,
      notificationService.getCandidateConsents,
      action.payload.contactIds,
    );
    const candidateCommunicationLimits = yield select(selectCommunicationLimitsSms);
    const pinnedMessages = yield select(selectPinnedMessages);
    if (httpSuccess(response.status)) {
      const resultArray = action.payload.candidateData.map((item, index) => {
        const candidateConsentData = response.data?.filter(ele => ele?.contactId === item?.contactId)?.[0];
        const candidateConsents = checkCandidatesConsents(
          candidateConsentData,
          item?.brandId,
          item?.contactId,
        )?.hasTextConcents;

        const matchingDataItem = candidateCommunicationLimits?.find(dataItem => {
          return dataItem?.contactId.toString() === item?.contactId && dataItem?.brandId.toString() === item?.brandId;
        });

        const communicationLimits = matchingDataItem ? true : false;
        return {
          ...item,
          consents: candidateConsents,
          selectionStatus: candidateConsents,
          communicationLimits: communicationLimits,
        };
      });

      const candidateSelectedData = resultArray?.filter(
        options =>
          options?.phoneNumber !== undefined && options?.consents !== false && options?.communicationLimits === false,
      );
      yield put(
        notificationDataActions.setSmsData({
          open: true,
          minimized: false,
          data: { candidateData: resultArray, selectedCandidates: candidateSelectedData },
          sendType: SmsType.bulk,
          pinnedMessages: pinnedMessages,
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getBulkSmsConsentsSaga',
        area: 'src/store/redux-store/notification/notification.saga.ts',
      },
    });
  }
}

export function* getConsentsWithdrawnSagaAction(action: PayloadAction<ICandidateBrand[]>) {
  try {
    const notificationService: NotificationService = yield getContext('notificationService');
    const candidates = action.payload;
    const uniqueCandidateIds = [];
    candidates?.forEach(
      val => !uniqueCandidateIds.includes(`${val?.contactId}`) && uniqueCandidateIds.push(`${val?.contactId}`),
    );

    const response = yield call(TrackEmailConsents, notificationService.getCandidateConsents, uniqueCandidateIds);
    if (httpSuccess(response.status)) {
      const resultArr = [];

      for (let i = 0; i < candidates?.length; i++) {
        const resIdx = response?.data?.findIndex(res => res?.contactId == candidates?.[i]?.contactId);
        if (resIdx !== -1) {
          const emailConsent = checkCandidatesConsents(
            response.data?.[resIdx],
            candidates[i]?.brandId,
            candidates[i]?.contactId,
          )?.hasEmailConsents;

          !emailConsent &&
            resultArr.push({ contactId: `${candidates[i]?.contactId}`, brandId: `${candidates[i].brandId}` });
        }
      }
      yield put(notificationDataActions.setEmailConsentWithdrawn(resultArr));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getConsentsWithdrawnSagaAction',
        area: 'src/store/redux-store/notification/notification.saga.ts',
      },
    });
  }
}

export function* fetchTemplatesByCategory(action: PayloadAction<{ category: string }>) {
  try {
    const templateService: TemplateService = yield getContext('templateService');
    const response = yield call(
      TrackGetTemplateByCategory,
      templateService.GetTemplatesByCategory,
      action.payload.category,
      'email',
    );
    if (httpSuccess(response.status)) {
      const mapped = response?.data?.map(item => item?.title?.toLowerCase()) || [];
      yield put(notificationDataActions.setCategoryTitles([...mapped]));
      yield put(notificationDataActions.setPacketCategoryTitles(response?.data));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'fetchTemplatesByCategory',
        area: 'src/store/redux-store/notification/notification.saga.ts',
      },
    });
  }
}

export function* deleteTemplateByCategory(
  action: PayloadAction<{ templateId: string; category: string; getTemplate?: () => Promise<any> }>,
) {
  try {
    manuallyIncrementPromiseCounter('delete-template');
    const templateService: TemplateService = yield getContext('templateService');
    const response = yield call(
      TrackDeleteTemplate,
      templateService.deletePersonalTemplate,
      action.payload.templateId,
      action.payload.category,
    );
    if (httpSuccess(response.status)) {
      manuallyDecrementPromiseCounter('delete-template');
      if (action.payload.getTemplate) {
        yield call(action.payload.getTemplate);
      }
      yield put(
        globalActions.setSnackBar({
          message: 'Template deleted successfully',
          severity: 'success',
        }),
      );
    } else {
      manuallyDecrementPromiseCounter('delete-template');
      yield put(
        globalActions.setSnackBar({
          message: 'ERROR: failed to delete template',
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    manuallyResetPromiseCounter('delete-template');
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'deleteTemplateByCategory',
        area: 'src/store/redux-store/notification/notification.saga.ts',
      },
    });
  }
}

export function* getSmsInbox(action: PayloadAction<IInboxQuery>) {
  const failedService = yield select(selectFailedServices);
  yield put(
    globalActions.setFailedServices({ failedServices: failedService.filter(f => f.type != FailedServices.SMSInbox) }),
  );
  try {
    const inboxQuery = action.payload;
    const notificationService: NotificationService = yield getContext('notificationService');
    const response = yield call(TrackSMSInbox, notificationService.getInboxMessages, inboxQuery);
    if (httpSuccess(response?.status)) {
      yield put(notificationDataActions.updateInboxMessages(response?.data));
    }
  } catch (error) {
    yield put(
      globalActions.setFailedServices({
        failedServices: [...failedService, { type: FailedServices.SMSInbox, payload: action.payload }],
      }),
    );
    yield put(globalActions.setCatastrophicDialog());
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getSmsInbox',
        area: 'src/store/redux-store/notification/notification.saga.ts',
      },
    });
  }
}

export function* getUnreadCountSaga() {
  try {
    const { coveredInboxes } = yield select(selectCoveredInbox);
    const { userInfo } = yield select(selectUser);
    const userPhoneNumber = [userInfo?.phoneNumber];
    const phoneNumbers = coveredInboxes?.length > 0 ? coveredInboxes?.map(item => item?.phoneNumber) : userPhoneNumber;
    const notificationService: NotificationService = yield getContext('notificationService');
    const response = yield call(TrackUnreadCount, notificationService.getUnreadCount, phoneNumbers);
    if (httpSuccess(response?.status)) {
      const arr: IUnreadSmsCount[] = response?.data;
      yield put(notificationDataActions.setUnreadCount(arr));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getUnreadCountSaga',
        area: 'src/store/redux-store/notification/notification.saga.ts',
      },
    });
  }
}

export function* getCommunicationLimitsSaga(action: PayloadAction<ICommunicationLimitsPayload>) {
  try {
    const limitsQuery = action.payload;
    const notificationService: NotificationService = yield getContext('notificationService');
    const response = yield call(TrackCommunicationLimits, notificationService.getCommunicationLimitsData, limitsQuery);
    if (httpSuccess(response?.status)) {
      const data = response?.data;
      const processed = limitsQuery.contacts?.filter(
        rec => !data?.some(con => con?.candidateId === rec?.candidateId && con?.brandId === rec?.brandId),
      );
      const contacts: ICandidateBrand[] = (processed || []).map(rec => ({
        contactId: rec?.candidateId?.toString(),
        brandId: rec?.brandId?.toString(),
      }));
      if (limitsQuery.channel === ChannelType.email) {
        yield put(notificationDataActions.setCommunicationLimitsEmail([...contacts]));
      } else if (limitsQuery.channel === ChannelType.sms) {
        yield put(notificationDataActions.setCommunicationLimitsSms([...contacts]));
        if (action.payload.bulkSmsData) {
          yield call(getBulkSmsConsentsSaga, {
            payload: action.payload.bulkSmsData,
            type: notificationDataActions.getFacilityTemplatesByCategory.type,
          });
        }
      }
    } else {
      yield put(
        notificationDataActions.setCommunicationLimitsDialogOpen({
          open: true,
          key: limitsQuery.channel,
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getCommunicationLimitsSaga',
        area: 'src/store/redux-store/notification/notification.saga.ts',
      },
    });
  }
}

export function* requestFacilityBaseTemplate(action: PayloadAction<{ userType: number }>) {
  try {
    const templateService: TemplateService = yield getContext('templateService');
    const response = yield call(TrackRequestBaseTemplate, templateService.getBaseTemplate, action.payload.userType);
    if (httpSuccess(response.status)) {
      const baseTemplateBody = response?.data?.body;
      yield put(notificationDataActions.setFacilityBaseTemplate(baseTemplateBody));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestFacilityBaseTemplate',
        area: 'src/store/redux-store/notification/notification.saga.ts',
      },
    });
  }
}

export function* requestFacilityTemplatesByCategory(action: PayloadAction<ITemplatesByCategory>) {
  try {
    const templateService: TemplateService = yield getContext('templateService');
    const response = yield call(
      TrackRequestFacilityTemplates,
      templateService.getFacilityTemplatesByCategory,
      action.payload.category,
      action.payload.userType,
      action.payload.userId,
    );
    if (httpSuccess(response.status)) {
      const facilityTemp = response?.data;
      yield put(notificationDataActions.setFacilityTemplates(facilityTemp));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'requestFacilityTemplatesByCategory',
        area: 'src/store/redux-store/notification/notification.saga.ts',
      },
    });
  }
}

export function* saveFacilityTemplate(action: PayloadAction<ISaveTemplate>) {
  try {
    const templateService: TemplateService = yield getContext('templateService');
    const response = yield call(TrackCreateFacilityTemplate, templateService.saveFacilityEmailTemplate, action.payload);
    if (httpSuccess(response.status)) {
      yield call(requestFacilityTemplatesByCategory, {
        payload: {
          category: action.payload.category,
          userType: TemplateUserTypes.Facility,
          userId: parseInt(action.payload.userId),
        },
        type: notificationDataActions.getFacilityTemplatesByCategory.type,
      });
      yield put(
        globalActions.setSnackBar({
          severity: 'success',
          message: 'Email template has been added successfully',
        }),
      );
    } else
      yield put(
        globalActions.setSnackBar({
          severity: 'error',
          message: response?.data?.toString() || 'Error in adding email template. Please try again.',
        }),
      );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'saveFacilityTemplate',
        area: 'src/store/redux-store/notification/notification.saga.ts',
      },
    });
  }
}

export function* updateFacilityEmailTemplates(action: PayloadAction<IUpdateTemplate>) {
  try {
    const templateService: TemplateService = yield getContext('templateService');
    const response = yield call(
      TrackUpdatesFacilityTemplate,
      templateService.updateFacilityEmailTemplate,
      action.payload,
    );
    if (httpSuccess(response.status)) {
      yield call(requestFacilityTemplatesByCategory, {
        payload: {
          category: action.payload.category,
          userType: TemplateUserTypes.Facility,
          userId: parseInt(action.payload.userId),
        },
        type: notificationDataActions.getFacilityTemplatesByCategory.type,
      });
      yield put(
        globalActions.setSnackBar({
          severity: 'success',
          message: i18next.t('Preferences.editSnackBar'),
        }),
      );
    } else
      yield put(
        globalActions.setSnackBar({
          severity: 'error',
          message: i18next.t('Preferences.editFailureSnackBar'),
        }),
      );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'updateFacilityEmailTemplates',
        area: 'src/store/redux-store/notification/notification.saga.ts',
      },
    });
  }
}

export function* updateMessageReadStatus(action: PayloadAction<IInboxReadStatusCommand>) {
  const notificationService: NotificationService = yield getContext('notificationService');
  try {
    var response = yield call(TrackInboxReadStatus, notificationService.updateMessageReadStatus, action.payload);
    if (httpSuccess(response?.status)) {
      yield put(notificationDataActions.getUnreadCount());
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'updateMessageReadStatus',
        area: 'src/store/redux-store/notification/notification.saga.ts',
      },
    });
  }
}

export function* notificationSaga() {
  yield takeEvery(notificationDataActions.handleHubEvent.type, HubEventListener);
  yield takeLatest(notificationDataActions.getCandidatesConsentsAction.type, getCandidateConsentsSagaAction);
  yield takeLatest(notificationDataActions.getConsentsWithdrawn.type, getConsentsWithdrawnSagaAction);
  yield takeLatest(notificationDataActions.getTemplatesByCategory.type, fetchTemplatesByCategory);
  yield takeLatest(notificationDataActions.bulkSmsCandidateConsent.type, getBulkSmsConsentsSaga);
  yield takeLatest(notificationDataActions.deletePersonalTemplate.type, deleteTemplateByCategory);
  yield takeLatest(notificationDataActions.getInboxMessages.type, getSmsInbox);
  yield takeLatest(notificationDataActions.getUnreadCount.type, getUnreadCountSaga);
  yield takeLatest(notificationDataActions.getCommunicationLimitsData.type, getCommunicationLimitsSaga);
  yield takeLatest(notificationDataActions.getFacilityBaseTemplate.type, requestFacilityBaseTemplate);
  yield takeLatest(notificationDataActions.getFacilityTemplatesByCategory.type, requestFacilityTemplatesByCategory);
  yield takeLatest(notificationDataActions.createFacilityTemplate.type, saveFacilityTemplate);
  yield takeLatest(notificationDataActions.updateFacilityEmailTemplates.type, updateFacilityEmailTemplates);
  yield takeLatest(notificationDataActions.updateMessageReadStatus.type, updateMessageReadStatus);
}
