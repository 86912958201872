import { missingField } from 'app/constants';
import { FacilityResponse } from 'app/models/Facility/FacilityDetails';
import { sortBy } from 'lodash';
import { capitalizeFirstCharEachWord } from 'app/helpers/stringHelper';
import { Concatenate } from 'utils/string/string';
import { getInitials } from '@AMIEWEB/Placement/PlacementDetails/PlacementDetailsHeader/utils';
import { IFacilityEmployee, IFacilityRoleAssignments } from 'app/models/Facility/FacilityRoles';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import { Typography } from 'amn-ui-core';
import React from 'react';
import i18next from 'i18next';

const setEmployeeName = (employeeRole: IFacilityEmployee) => {
  return employeeRole?.firstName && employeeRole?.lastName
    ? `${capitalizeFirstCharEachWord(employeeRole?.firstName)} ${capitalizeFirstCharEachWord(employeeRole?.lastName)}`
    : missingField;
};
/**
 * Method to map roles for each employees
 * @param facilityDetailsResponse
 */
export const mapEmployeeRoles = (facilityDetailsResponse: FacilityResponse) => {
  const employeeRoles = [
    {
      name: i18next.t('facility.employeeRoles.accountCoordinator'),
      data: setEmployeeName(facilityDetailsResponse?.employeeRoles?.accountCoordinator),
    },
    {
      name: i18next.t('facility.employeeRoles.accountManager'),
      data: setEmployeeName(facilityDetailsResponse?.employeeRoles?.accountManager),
    },
    {
      name: i18next.t('facility.employeeRoles.billingSpecialist'),
      data: setEmployeeName(facilityDetailsResponse?.employeeRoles?.billingSpecialist),
    },
    {
      name: i18next.t('facility.employeeRoles.clientAcctgRep'),
      data: setEmployeeName(facilityDetailsResponse?.employeeRoles?.clientAcctgRep),
    },
    {
      name: i18next.t('facility.employeeRoles.clientContractAdmin'),
      data: setEmployeeName(facilityDetailsResponse?.employeeRoles?.clientContractAdmin),
    },
    {
      name: i18next.t('facility.employeeRoles.clientContractSpecialist'),
      data: setEmployeeName(facilityDetailsResponse?.employeeRoles?.clientContractSpecialist),
    },
    {
      name: i18next.t('facility.employeeRoles.clinicalDirector'),
      data: setEmployeeName(facilityDetailsResponse?.employeeRoles?.clinicalDirector),
    },
    {
      name: i18next.t('facility.employeeRoles.credentialingAnalyst'),
      data: setEmployeeName(facilityDetailsResponse?.employeeRoles?.credentialingAnalyst),
    },
    {
      name: i18next.t('facility.employeeRoles.credentialingSpecialist'),
      data: setEmployeeName(facilityDetailsResponse?.employeeRoles?.credentialingSpecialist),
    },
    {
      name: i18next.t('facility.employeeRoles.customerSupportSpecialist'),
      data: setEmployeeName(facilityDetailsResponse?.employeeRoles?.customerSupportSpecialist),
    },
    {
      name: i18next.t('facility.employeeRoles.housingAccountExecutive'),
      data: setEmployeeName(facilityDetailsResponse?.employeeRoles?.housingAccountExecutive),
    },
    {
      name: i18next.t('facility.employeeRoles.payrollRegion'),
      data: facilityDetailsResponse?.payrollRegion ?? missingField,
    },
    {
      name: i18next.t('facility.employeeRoles.payrollSpecialist'),
      data: setEmployeeName(facilityDetailsResponse?.employeeRoles?.payrollSpecialist),
    },
    {
      name: i18next.t('facility.employeeRoles.regionalDirector'),
      data: setEmployeeName(facilityDetailsResponse?.employeeRoles?.regionalDirector),
    },
    {
      name: i18next.t('facility.employeeRoles.timeProcessingSpecialist'),
      data: setEmployeeName(facilityDetailsResponse?.employeeRoles?.timeProcessingSpecialist),
    },
  ];
  return sortBy(employeeRoles, ['name']);
};

/**
 * Method to build internal contact edit state based on roles for dropdown/text
 * @param designation
 * @param keyAttribute
 * @param firstName
 * @param lastName
 * @param employeeId
 */
const buildInternalContactState = (
  designation: string,
  keyAttribute: string,
  firstName: string,
  lastName: string,
  employeeId: number,
  phoneNumber: string,
  email: string,
  userInfo,
  availableRoles = [],
) => {
  const isUserAuthorized = Authorized(availableRoles, userInfo);

  return {
    designation: designation,
    keyAttribute: keyAttribute,
    title:
      firstName && lastName
        ? Concatenate([capitalizeFirstCharEachWord(firstName), capitalizeFirstCharEachWord(lastName)], ' ')
        : missingField,
    avatarText: firstName && lastName ? getInitials(firstName, lastName).toUpperCase() : missingField,
    phoneNumber: phoneNumber,
    email: email,
    id: employeeId,
    hideMessageIcon: true,
    lockReason: (
      <>
        <Typography component="div" sx={theme=>({ color: theme.palette.framework.system.doveGray })}>
          {`Cannot be edited due to:`}
        </Typography>
        <Typography component="div" sx={theme=>({ color: theme.palette.framework.system.doveGray, paddingLeft: '15px' })}>
          <li>{`Role permissions`}</li>
        </Typography>
      </>
    ),
    disabled: !isUserAuthorized,
    hideMailboxIcon: false,
    hidePhoneIcon: false,
    disableAvatar: !(firstName && lastName),
  };
};

/**
 * Method to transform internal contacts response for edit internal contacts
 * @param internalContacts
 * @param payRollRegion
 * @param payRollRegionId
 */
export const transformInternalContacts = (
  internalContacts: IFacilityRoleAssignments,
  payRollRegion: string,
  payRollRegionId: number,
  userInfo,
) => {
  return {
    accountCoordinator: buildInternalContactState(
      i18next.t('facility.employeeRoles.accountCoordinator'),
      'accountCoordinator',
      internalContacts?.accountCoordinator?.firstName,
      internalContacts?.accountCoordinator?.lastName,
      internalContacts?.accountCoordinator?.employeeId,
      internalContacts?.accountCoordinator?.phoneNumber,
      internalContacts?.accountCoordinator?.email,
      userInfo,
      [userRoles.accountManagement, userRoles.accountManagement_Leadership, userRoles.accountManagement_TeamMember],
    ),
    accountManager: buildInternalContactState(
      i18next.t('facility.employeeRoles.accountManager'),
      'accountManager',
      internalContacts?.accountManager?.firstName,
      internalContacts?.accountManager?.lastName,
      internalContacts?.accountManager?.employeeId,
      internalContacts?.accountManager?.phoneNumber,
      internalContacts?.accountManager?.email,
      userInfo,
      [userRoles.accountManagement, userRoles.accountManagement_Leadership, userRoles.accountManagement_TeamMember],
    ),
    billingSpecialist: buildInternalContactState(
      i18next.t('facility.employeeRoles.billingSpecialist'),
      'billingSpecialist',
      internalContacts?.billingSpecialist?.firstName,
      internalContacts?.billingSpecialist?.lastName,
      internalContacts?.billingSpecialist?.employeeId,
      internalContacts?.billingSpecialist?.phoneNumber,
      internalContacts?.billingSpecialist?.email,
      userInfo,
      [],
    ),
    clientAcctgRep: buildInternalContactState(
      i18next.t('facility.employeeRoles.clientAcctgRep'),
      'clientAcctgRep',
      internalContacts?.clientAcctgRep?.firstName,
      internalContacts?.clientAcctgRep?.lastName,
      internalContacts?.clientAcctgRep?.employeeId,
      internalContacts?.clientAcctgRep?.phoneNumber,
      internalContacts?.clientAcctgRep?.email,
      userInfo,
      [],
    ),
    clientContractAdmin: buildInternalContactState(
      i18next.t('facility.employeeRoles.clientContractAdmin'),
      'clientContractAdmin',
      internalContacts?.clientContractAdmin?.firstName,
      internalContacts?.clientContractAdmin?.lastName,
      internalContacts?.clientContractAdmin?.employeeId,
      internalContacts?.clientContractAdmin?.phoneNumber,
      internalContacts?.clientContractAdmin?.email,
      userInfo,
      [],
    ),
    clientContractSpecialist: buildInternalContactState(
      i18next.t('facility.employeeRoles.clientContractSpecialist'),
      'clientContractSpecialist',
      internalContacts?.clientContractSpecialist?.firstName,
      internalContacts?.clientContractSpecialist?.lastName,
      internalContacts?.clientContractSpecialist?.employeeId,
      internalContacts?.clientContractSpecialist?.phoneNumber,
      internalContacts?.clientContractSpecialist?.email,
      userInfo,
      [userRoles.clinical_ClientContractSpecialist],
    ),
    clinicalDirector: buildInternalContactState(
      i18next.t('facility.employeeRoles.clinicalDirector'),
      'clinicalDirector',
      internalContacts?.clinicalDirector?.firstName,
      internalContacts?.clinicalDirector?.lastName,
      internalContacts?.clinicalDirector?.employeeId,
      internalContacts?.clinicalDirector?.phoneNumber,
      internalContacts?.clinicalDirector?.email,
      userInfo,
      [userRoles.clinical_Director, userRoles.clinical_ClientContractSpecialist],
    ),
    credentialingAnalyst: buildInternalContactState(
      i18next.t('facility.employeeRoles.credentialingAnalyst'),
      'credentialingAnalyst',
      internalContacts?.credentialingAnalyst?.firstName,
      internalContacts?.credentialingAnalyst?.lastName,
      internalContacts?.credentialingAnalyst?.employeeId,
      internalContacts?.credentialingAnalyst?.phoneNumber,
      internalContacts?.credentialingAnalyst?.email,
      userInfo,
      [
        userRoles.clinical_ClientContractSpecialist,
        userRoles.credentialing_Leadership,
        userRoles.credentialing_TeamMember,
        userRoles.credentialing,
      ],
    ),
    credentialingSpecialist: buildInternalContactState(
      i18next.t('facility.employeeRoles.credentialingSpecialist'),
      'credentialingSpecialist',
      internalContacts?.credentialingSpecialist?.firstName,
      internalContacts?.credentialingSpecialist?.lastName,
      internalContacts?.credentialingSpecialist?.employeeId,
      internalContacts?.credentialingSpecialist?.phoneNumber,
      internalContacts?.credentialingSpecialist?.email,
      userInfo,
      [
        userRoles.clinical_ClientContractSpecialist,
        userRoles.credentialing_Leadership,
        userRoles.credentialing_TeamMember,
        userRoles.credentialing,
      ],
    ),
    customerSupportSpecialist: buildInternalContactState(
      i18next.t('facility.employeeRoles.customerSupportSpecialist'),
      'customerSupportSpecialist',
      internalContacts?.customerSupportSpecialist?.firstName,
      internalContacts?.customerSupportSpecialist?.lastName,
      internalContacts?.customerSupportSpecialist?.employeeId,
      internalContacts?.customerSupportSpecialist?.phoneNumber,
      internalContacts?.customerSupportSpecialist?.email,
      userInfo,
      [userRoles.customerSupport_Leadership, userRoles.customerSupport_TeamMember],
    ),
    housingAccountExecutive: buildInternalContactState(
      i18next.t('facility.employeeRoles.housingAccountExecutive'),
      'housingAccountExecutive',
      internalContacts?.housingAccountExecutive?.firstName,
      internalContacts?.housingAccountExecutive?.lastName,
      internalContacts?.housingAccountExecutive?.employeeId,
      internalContacts?.housingAccountExecutive?.phoneNumber,
      internalContacts?.housingAccountExecutive?.email,
      userInfo,
      [userRoles.housingAccountExecutive],
    ),
    payrollRegion: {
      designation: i18next.t('facility.employeeRoles.payrollRegion'),
      keyAttribute: 'payrollRegion',
      title: payRollRegion ? payRollRegion : missingField,
      avatarText: payRollRegion ? getInitials('Payroll', 'Region') : missingField,
      phoneNumber: null,
      email: null,
      id: payRollRegionId,
      hideMessageIcon: true,
      lockReason: (
        <>
          <Typography component="div" sx={theme=>({ color: theme.palette.framework.system.doveGray })}>
            {`Cannot be edited due to:`}
          </Typography>

          <Typography component="div" sx={theme=>({ color: theme.palette.framework.system.doveGray, paddingLeft: '15px' })}>
            <li>{`Role permissions`}</li>
          </Typography>
        </>
      ),
      disabled: !Authorized([userRoles.payrollSpecialist, userRoles.clinical_ClientContractSpecialist], userInfo),
      hideMailboxIcon: true,
      hidePhoneIcon: true,
      disableAvatar: !payRollRegion,
    },
    payrollSpecialist: buildInternalContactState(
      i18next.t('facility.employeeRoles.payrollSpecialist'),
      'payrollSpecialist',
      internalContacts?.payrollSpecialist?.firstName,
      internalContacts?.payrollSpecialist?.lastName,
      internalContacts?.payrollSpecialist?.employeeId,
      internalContacts?.payrollSpecialist?.phoneNumber,
      internalContacts?.payrollSpecialist?.email,
      userInfo,
      [userRoles.payrollSpecialist, userRoles.clinical_ClientContractSpecialist],
    ),
    regionalDirector: buildInternalContactState(
      i18next.t('facility.employeeRoles.regionalDirector'),
      'regionalDirector',
      internalContacts?.regionalDirector?.firstName,
      internalContacts?.regionalDirector?.lastName,
      internalContacts?.regionalDirector?.employeeId,
      internalContacts?.regionalDirector?.phoneNumber,
      internalContacts?.regionalDirector?.email,
      userInfo,
      [userRoles.regionalDirector, userRoles.accountManagement, userRoles.accountManagement_Leadership, userRoles.accountManagement_TeamMember],
    ),
    timeProcessingSpecialist: buildInternalContactState(
      i18next.t('facility.employeeRoles.timeProcessingSpecialist'),
      'timeProcessingSpecialist',
      internalContacts?.timeProcessingSpecialist?.firstName,
      internalContacts?.timeProcessingSpecialist?.lastName,
      internalContacts?.timeProcessingSpecialist?.employeeId,
      internalContacts?.timeProcessingSpecialist?.phoneNumber,
      internalContacts?.timeProcessingSpecialist?.email,
      userInfo,
      [userRoles.timeProcessingSpecialist, userRoles.clinical_ClientContractSpecialist],
    ),
  };
};

/**
 * Method to set internal contacts state on side panel for view/edit
 * @param designation
 * @param title
 * @param employeeId
 */
export const setEditInternalContactsValue = (
  designation: string,
  title: string,
  employeeId: number | null,
  helperText: string | null,
) => {
  return {
    key: designation,
    data: {
      value: employeeId ? { name: title, value: employeeId, id: employeeId } : null,
      error: null,
      isDirty: false,
      helperText: helperText,
      checked: false,
    },
  };
};
