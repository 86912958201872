import { Box, Grid, Icon, materialUiXGrid, Paper, Typography } from 'amn-ui-core';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { selectUser } from 'oidc/user.selectors';
import { selectGridState } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.selectors';
import { FacilityCommunicationHistoryViewInfo } from './FacilityCommunicationHistoryViewInfo';
import _ from 'lodash';
import { XGrid } from '@AMIEWEB/Common/XGrid/XGrid';
import { selectFacilityCommunicationLog, selectGridLoading } from './store/FacilityCommunicationHistory.selector';
import { checkUserRolesForAccess } from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/CommunicationHistoryTab/helper';
import { Tooltip } from 'amn-ui-core/dist/components/Tooltip/Tooltip';
import { convertToPST } from 'app/helpers/dateHelper';
import { Concatenate } from 'utils/string/string';
import { formatTime } from '@AMIEWEB/Candidate/helper/StringFormatter';
import { ConversationCount } from '@AMIEWEB/Notification/Common/ConversationCount';
import {
  communicationNotesOrSubject,
  FacilityCommunictionHistoryFlattenData,
  getConatctNames,
  getContactInfo,
  getDelieveryStatusMessage,
  identifyCommunicationIcon,
} from './Helpers/helper';
import { facilityCommunicationHostoryActions, IFacilityLogs } from './store/FacilityCommunicationHistory.redux';
import { FacilityResponse } from 'app/models/Facility/FacilityDetails';
import { selectFacilityDetail } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { GridSortModel } from '@mui/x-data-grid';
import { EmailModal } from './EmailModal';
import { globalActions } from 'app/ApplicationRoot/Global.redux';

const useStyles = makeStyles()(theme => ({
  channelIcon: {
    color: theme.palette.framework.system.navyBlue,
    paddingRight: '10px',
    fontSize: '1.25rem !important',
    overflow: 'visible',
  },
  communicationType: {
    display: 'flex',
  },
  communicationTitle: {
    paddingTop: '3px',
    paddingLeft: '6px',
  },
  conversationType: {
    color: theme.palette.framework.system.navyBlue,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  value: {
    color: theme.palette.framework.system.doveGray,
    paddingRight: '2%',
    fontSize: '1rem',
    paddingBottom: '2%',
  },
  title: {
    color: theme.palette.framework.system.charcoal,
    fontSize: '16px',
    fontWeight: 600,
    paddingLeft: '23px',
    paddingTop: '12px',
    paddingBottom: '12px',
  },
  notes: {
    cursor: 'pointer',
  },
  addNote: {
    fontWeight: 500,
    marginTop: 2,
    cursor: 'pointer',
  },
  conversationNotes: {
    cursor: 'pointer',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: '750px',
    outline: 'none',
    borderRadius: '5px',
    boxShadow: `0px 0px 2px 6px ${theme.palette.framework.system.tertiaryGrey}`,
  },
  grid: {
    padding: '0 20px 20px 20px',
  },
  subject: {
    fontSize: '18px',
    paddingBottom: '5px',
  },
  noUnderline: {
    color: theme.palette.framework.system.doveGray,
    textDecoration: 'none',
    cursor: 'pointer',
  },
  tooltipArrow: {
    color: theme.palette.framework.main,
    '&::before': {
      border: `1px solid ${theme.palette.framework.system.doveGray}`,
    },
  },
  tooltipBackground: {
    backgroundColor: theme.palette.framework.main,
    color: theme.palette.framework.system.doveGray,
    border: `1px solid ${theme.palette.framework.system.doveGray}`,
    fontSize: '12px',
  },
}));

export const DEFAULT_PAGE_SIZE = 50;
export const DEFAULT_PAGE_NUMBER = 1;

export const CommunicationHistoryTab = () => {
  const missingField = '--';
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isPageSizeChangeRef = useRef(false);
  const apiRef = materialUiXGrid.useGridApiRef();

  const gridState = useSelector(selectGridState);
  const user = useSelector(selectUser);
  const facilityCommunicationLog = useSelector(selectFacilityCommunicationLog);
  const loading = useSelector(selectGridLoading);
  const facilityDetailsResponse: FacilityResponse | null = useSelector(selectFacilityDetail);

  const defaultSort = [{ field: 'date', sort: 'desc' }];
  const [rows, setRows] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [columns, setColumns] = useState<any[]>([...FacilityCommunicationHistoryViewInfo(t)]);
  const [data, setData] = useState({});
  const [xGridSortModel, setXGridSortModel] = React.useState<any>(defaultSort);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(50);

  const getEmailNotes = (fieldValue: string, row: number, channel) => {
    return (
      <div className={classes.notes}>
        <Typography className={classes.conversationNotes} onClick={() => handleClick(row, channel)}>
          {fieldValue}
        </Typography>
      </div>
    );
  };

  const getNotes = (notes: string) => {
    return (
      <div className={classes.notes}>
        <Typography className={classes.conversationNotes}>{notes}</Typography>
      </div>
    );
  };

  const handleClick = (row: number, channel: string) => {
    setModalOpen(true);
    const data = facilityCommunicationLog?.results[row];
    const modalData = {};

    if (channel === 'Email') {
      modalData['title'] = 'Email Notes';
      modalData['tos'] = data?.message?.tos;
      modalData['ccs'] = data?.message?.ccs;
      modalData['bccs'] = data?.message?.bccs;
      modalData['subject'] = data?.message?.subject;
      modalData['sender'] = data?.message?.sender?.email;
    } else {
      modalData['title'] = 'Notes';
    }
    if (channel === 'Voice') {
      modalData['notes'] = data?.response?.notes;
    } else {
      modalData['notes'] = data?.message?.body;
    }
    setData(modalData);
  };

  const getCommunicationType = log => {
    return (
      <div className={classes.communicationType}>
        <Box>
          <Icon className={classes.channelIcon}>{identifyCommunicationIcon(log, '1.15rem', '7px')}</Icon>
        </Box>
        <Box className={classes.communicationTitle}>
          {log.channel === 'Voice'
            ? 'Call'
            : log.channel === 'Conversation'
            ? getSmsConversationType(log)
            : log.channel}
        </Box>
      </div>
    );
  };

  const getSmsConversationType = log => {
    const hasUserAccess = checkUserRolesForAccess(log, user);
    if (hasUserAccess) {
      return (
        <span
          className={classes.conversationType}
          role="button"
          tabIndex={0}
          onClick={() => {}}
          onKeyPress={() => {}}
        >{`SMS`}</span>
      );
    } else
      return (
        <Tooltip
          classes={{
            tooltip: classes.tooltipBackground,
            arrow: classes.tooltipArrow,
          }}
          arrow
          title={t('FacilityCommunicationHistory.AccessConversation')}
        >
          <span className={classes.noUnderline}>{t('SMS')}</span>
        </Tooltip>
      );
  };

  const formatData = logs => {
    const logData = logs?.map((log, index) => {
      const coveringEmployeeNameObject = log?.associatedRecords?.find(record => record.name === 'coveringEmployeeName');
      const sharedDeskPublisherNameObject = log?.associatedRecords?.find(
        record => record.name === 'sharedDeskPublisherName',
      );
      return {
        rowID: log?.id,
        contactName: getConatctNames(log) || missingField,
        communicationType: getCommunicationType(log) || missingField,
        contactInfo: getContactInfo(log) || missingField,
        date: `${convertToPST(log?.publishedOn, 'MM/DD/YYYY')} | ${formatTime(
          convertToPST(log?.publishedOn, 'HH:mm'),
        )}`,
        from: coveringEmployeeNameObject?.value?.trim()
          ? coveringEmployeeNameObject?.value || missingField
          : sharedDeskPublisherNameObject?.value?.trim()
          ? sharedDeskPublisherNameObject?.value || missingField
          : log?.message?.sender?.name || missingField,
        userRole:
          log?.userRoles && log?.userRoles?.length > 3 ? (
            <Tooltip
              classes={{
                tooltip: classes.tooltipBackground,
                arrow: classes.tooltipArrow,
              }}
              arrow
              title={<span>{Concatenate(log?.userRoles, ', ')}</span>}
            >
              <span>{`${Concatenate(log?.userRoles.slice(0, 2), ', ')}...`}</span>
            </Tooltip>
          ) : (
            Concatenate(log?.userRoles, ', ')
          ),
        status:
          log.channel === 'Email'
            ? log?.message?.tos && getDelieveryStatusMessage(log?.message?.tos[0], missingField)
            : log?.response?.disposition?.replace(/([A-Z][a-z])/g, ' $1').replace(/(\d)/g, ' $1') || missingField,
        type:
          log.channel === 'Conversation' ? (
            <ConversationCount
              channel={log.channel}
              outboundCount={log?.message?.outboundCount}
              inboundCount={log?.message?.inboundCount}
              undeliveredCount={log?.message?.undeliveredMessagesCount}
            />
          ) : log?.message.direction === null ? (
            missingField
          ) : (
            <ConversationCount channel={log?.channel} direction={log?.message?.direction} />
          ),
        notes: communicationNotesOrSubject(log, index, getNotes, getEmailNotes, missingField),
      };
    });
    return logData;
  };

  const handlePagination = (offset: number, page: number) => {
    const facilityLogsPayload: IFacilityLogs = {
      facilityId: facilityDetailsResponse?.facilityId,
      pageSize: offset,
      pageNumber: page,
    };
    dispatch(facilityCommunicationHostoryActions.getFacilityLogs(facilityLogsPayload));
  };

  const onPageChange = (page: number) => {
    if (isPageSizeChangeRef.current) {
      isPageSizeChangeRef.current = false;
      return;
    }
    setPageNumber(page);
    const pagination = apiRef?.current?.state?.pagination;
    if (pagination && !isPageSizeChangeRef.current) {
      handlePagination(pagination?.pageSize, page + 1);
    }
  };

  const onPageSizeChange = (pageSize: number) => {
    if (loading) {
      return;
    }
    setPageSize(pageSize);
    handlePagination(pageSize, pageNumber === 0 ? pageNumber + 1 : pageNumber);
  };

  const onSortModelChanged = React.useCallback(
    (model: GridSortModel) => {
      const pagination = apiRef?.current?.state?.pagination;

      if (pagination) {
        handlePagination(pagination.pageSize, pagination.page + 1);
      }
      setXGridSortModel(model);
    },

    [handlePagination],
  );

  const handleResetColumns = () => {
    setColumns(FacilityCommunicationHistoryViewInfo(t));
    handlePagination(DEFAULT_PAGE_SIZE, 1);
    setPageNumber(0);
    setPageSize(DEFAULT_PAGE_SIZE);
  };

  useEffect(() => {
    if (facilityCommunicationLog) {
      const formattedData = formatData(facilityCommunicationLog?.results);
      const newFormatedData = FacilityCommunictionHistoryFlattenData(formattedData);
      setRows(newFormatedData);
    }
  }, [facilityCommunicationLog]);

  useEffect(() => {
    if (!loading && facilityCommunicationLog?.results.length === 0 && facilityDetailsResponse?.facilityId) {
      const facilityLogsPayload: IFacilityLogs = {
        facilityId: facilityDetailsResponse?.facilityId,
        pageSize: DEFAULT_PAGE_SIZE,
        pageNumber: DEFAULT_PAGE_NUMBER,
      };
      dispatch(facilityCommunicationHostoryActions.getFacilityLogs(facilityLogsPayload));
    }
  }, [facilityDetailsResponse?.facilityId]);

  return (
    <Box>
      <Paper>
        <div className={classes.title}>{t('FacilityCommunicationHistory.communicationHistory')}</div>
        <Box className={classes.grid}>
          <XGrid
            apiRef={apiRef}
            columns={columns}
            page={pageNumber}
            pageSize={pageSize}
            rows={rows || []}
            rowsPerPageOptions={[50, 100, 250, 500]}
            defaultRowsPerPage={gridState.pageSize || DEFAULT_PAGE_SIZE}
            checkboxSelection
            height={360}
            rowCount={facilityCommunicationLog?.totalRecords || 0}
            defaultSortModel={xGridSortModel}
            sortModel={xGridSortModel}
            paginationMode="server"
            defaultColumns={FacilityCommunicationHistoryViewInfo(t)}
            loading={loading}
            disableSelectionOnClick={true}
            onSortModelChange={onSortModelChanged}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            toolbarProps={{
              refreshButton: true,
              resetButton: true,
              refreshGrid: () => {
                handleResetColumns();
                setXGridSortModel(defaultSort);
              },
              resetColumns: () => {
                setColumns(FacilityCommunicationHistoryViewInfo(t));
                setXGridSortModel(defaultSort);
                dispatch(
                  globalActions.setSnackBar({
                    message: 'Columns have been reset.',
                    severity: 'success',
                  }),
                );
              },
            }}
          />
        </Box>
      </Paper>
      {isModalOpen && <EmailModal data={data} onClose={() => setModalOpen(false)} />}
    </Box>
  );
};
