import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { Divider, Typography } from 'amn-ui-core';
import { AccordianComponent } from '@AMIEWEB/NewOrder/CreateOrder/Common/AccordianComponent';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { EmailDefaultValues } from 'app/models/Email/IEmail';
import _, { isEqual } from 'lodash';
import { searchNotificationData } from '@AMIEWEB/GlobalSearch/helper';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';
import { IEmployee, IToken } from 'app/models/Notification/Notification';
import { IEmailData, SendType, UseSubType, UseType } from '@AMIEWEB/Notification/Constants';
import { Concatenate } from 'utils/string/string';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { useHeaderOptions } from '@AMIEWEB/Common/Buttons/UseHeaderOptions';
import { KeyContactType } from '@AMIEWEB/Placement/PlacementDetails/PlacementDetailsHeader/utils';
import { InternalContacts } from '@AMIEWEB/Facility/FacilityDetails/SidePanel/InternalContacts';
import {
  selectEditFacilityInternalContacts,
  selectFacility,
  selectFacilityInternalContacts,
  selectKeyContactsTabExpanded,
  selectKeyContactsTabHeight,
} from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { useDecision } from '@optimizely/react-sdk';
import { ff_facility_ui_edit_internal_contacts } from 'app/constants/FeatureFlags/Facility/Keys';
import {
  getKeyClientContacts,
  getKeyInternalPartners,
} from '@AMIEWEB/Facility/FacilityDetails/FacilityHeader/HeaderUtils';
import ClientContacts from '@AMIEWEB/Facility/FacilityDetails/SidePanel/ClientContacts';
import { facilityActions } from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
import { getDefaultSender } from '@AMIEWEB/Candidate/CandidateProfile/CandidateHeader/NotificationHelper';

const useStyles = makeStyles()(theme => ({
  root: {
    padding: 12,
  },
  selectedContact: {
    fontSize: '14px',
    justifyContent: 'space-between',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    cursor: 'pointer',
    paddingBottom: '12px',
  },
  bulkEMail: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: theme.palette.framework.system.navyBlue,
    },
  },
  emailText: {
    marginLeft: theme.spacing(0.5),
  },
  contactContainer: {
    width: '100%',
    height: 'calc(51vh + 8px)',
    overflowY: 'auto',
    paddingTop: '0px !important',
  },
  selectedText: {
    fontSize: 14,
  },
  mailIcon: {
    width: '20px',
    height: '20px',
  },
  selectedContainer: {
    width: '100%',
  },
  disabled: {
    color: theme.palette.framework.system.lightDoveGray,
    pointerEvents: 'none',
  },
}));

export const ContactsTab = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getEmailSubject } = useHeaderOptions();

  const { userInfo } = useSelector(selectUser);
  const notificationData = useSelector(notificationSelection);
  const isContactsExpanded = useSelector(selectKeyContactsTabExpanded);
  const focusedHeight = useSelector(selectKeyContactsTabHeight);
  const editorInternalContacts = useSelector(selectEditFacilityInternalContacts);
  const facilityData = useSelector(selectFacility);
  const facilityDetailsResponse = facilityData?.facilityResponse;
  const keyClientContacts = getKeyClientContacts(facilityDetailsResponse);
  const keyInternalPartners = useSelector(selectFacilityInternalContacts);

  const [showEditInternalContacts] = useDecision(ff_facility_ui_edit_internal_contacts);

  // method to set the scroll position
  const setScrollPosition = () => {
    const element = document.querySelector('.scroll-class');
    if (element) {
      dispatch(facilityActions.setTabHeight(element.scrollTop));
    }
  };

  // method to get selected internal contacts
  const getSelectedInternalContacts = () => {
    let filteredObj = [];
    Object.entries(editorInternalContacts)?.map(([_, value]) => value?.checked && filteredObj.push(value?.checked));
    return filteredObj;
  };

  const internalContactsLength = useMemo(() => getSelectedInternalContacts().length, [editorInternalContacts]);

  const clientContactsLength = 0;

  const selectedContactsLength = internalContactsLength + clientContactsLength;

  // method to handle bulk email creation
  const handleCreateBulkEmail = () => {
    const notificationUser = searchNotificationData({ userInfo });

    const internalContacts = Object.entries(editorInternalContacts)
      ?.map(
        ([groupKey, items]) =>
          items?.checked && {
            contactId: keyInternalPartners[groupKey]?.id,
            email: keyInternalPartners[groupKey]?.email,
            phoneNumber: keyInternalPartners[groupKey]?.phoneNumber,
            name: keyInternalPartners[groupKey]?.title,
            designation: keyInternalPartners[groupKey]?.designation,
            isInternalRecipient: true,
          },
      )
      .filter(value => !_.isEmpty(value));

    const sender: IEmployee = getDefaultSender({ userInfo })
    const tos = [...internalContacts];
    const currentUrl = window.location.href?.split('?')[0];
    const body = `<p>&nbsp;</p><p>&nbsp;</p><p>Link To Profile : <a href=${currentUrl}>${currentUrl}</a><br><br>{{signature}}</p>`;
    const substitutions: Array<IToken> = [
      {
        name: 'Signature',
        input: '{{signature}}',
      },
    ];
    const emailData: IEmailData = {
      substitutions: substitutions,
      useType: UseType.Bulk,
      useSubType: UseSubType.KeyContactBulkEmail,
      emailType: SendType.one_to_one,
      body: body,
      associatedRecords: [],
      subject: getEmailSubject(),
      tos: tos,
      sender: sender,
      userRole: Concatenate(userInfo?.roles || [], ','),
      isInternal: true,
    };
    if (tos?.length > 0) {
      if (!notificationData.email.open && notificationData.email.minimized) {
        dispatch(
          notificationDataActions.setEmailInteraction({
            open: true,
            minimized: false,
          }),
        );
        if (!isEqual(notificationData.email.data!.tos, [])) {
          dispatch(notificationDataActions.setSnackBarData({ channel: 'email', manual: false, changeWarning: true }));
        }
      } else {
        dispatch(
          notificationDataActions.setEmailData({
            data: {
              ...EmailDefaultValues(),
              ...notificationUser,
              ...emailData,
            },
            open: true,
            minimized: false,
          }),
        );
      }
    }
  };

  useEffect(() => {
    if (focusedHeight > 0) {
      const element = document.querySelector('.scroll-class');
      if (element) {
        element.scrollTop = focusedHeight;
      }
    }
  }, [focusedHeight]);

  // method to expand and collapse the accordian
  const onExpand = (type: 'isInternal' | 'isClient') => {
    setScrollPosition();
    dispatch(facilityActions.setIsTabExpanded({ type, value: !isContactsExpanded[type] }));
  };

  return (
    <LayoutGrid className={classes.root}>
      <LayoutGridItem className={classes.selectedContainer}>
        <div
          className={`${classes.selectedContact} ${selectedContactsLength > 0 ? '' : classes.disabled}`}
          onClick={handleCreateBulkEmail}
        >
          <Typography className={classes.selectedText}>{`${t('placement.placementDrawerCotactTab.selectedContacts')} ${
            selectedContactsLength > 0 ? '(' + selectedContactsLength + ')' : ''
          }`}</Typography>
          <div className={classes.bulkEMail}>
            <MailOutlineIcon className={classes.mailIcon} />
            <Typography className={classes.emailText}>{t('placement.placementDrawerCotactTab.bulkEmail')}</Typography>
          </div>
        </div>
        <Divider />
      </LayoutGridItem>
      <LayoutGridItem className={`${classes.contactContainer} scroll-class`}>
        {showEditInternalContacts.enabled && (
          <AccordianComponent
            title={`${t('placement.placementDrawerCotactTab.internalContacts')} ${
              internalContactsLength > 0 ? '(' + internalContactsLength + ')' : ''
            }`}
            isTransparent
            isExpanded={isContactsExpanded?.isInternal ?? true}
            setIsExpanded={() => onExpand(KeyContactType.Internal)}
            children={
              <InternalContacts
                disableEmailVoiceIcon={clientContactsLength > 0 || internalContactsLength > 0}
                setScrollPosition={setScrollPosition}
              />
            }
          ></AccordianComponent>
        )}
        <Divider />
        <AccordianComponent
          title={`${t('placement.placementDrawerCotactTab.clientContacts')} ${
            clientContactsLength > 0 ? '(' + clientContactsLength + ')' : ''
          }`}
          isTransparent
          isExpanded={isContactsExpanded?.isClient ?? true}
          setIsExpanded={() => onExpand(KeyContactType.Client)}
          children={!!keyClientContacts ? <ClientContacts /> : <></>}
        ></AccordianComponent>
      </LayoutGridItem>
    </LayoutGrid>
  );
};
