import React, { useEffect, useRef, useState } from 'react';
import { Grid, Avatar, IconButton, Divider, Typography, useTheme, Link } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { DrawerFitted } from 'app/components/Common/Drawer/Drawer';
import HomeIconOutlined from '@mui/icons-material/HomeOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import PublicIcon from '@mui/icons-material/Public';
import CloseIcon from 'app/assets/images/close-24px.svg';
import ClearIcon from '@mui/icons-material/Clear';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { LayoutGridItem } from 'app/layout/LayoutGrid';
import MailBoxIcon from 'app/assets/images/MailBox.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectEditFacilityRegion,
  selectFacility,
  selectFacilityRegionOptions,
} from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { openInNewTab } from 'app/helpers/openInNewTab';
import { missingField } from 'app/constants';
import { isStrNull } from 'utils/string/string';
import { InlineDropdown } from '../EditPage/InlineDropdown';
import { facilityActions } from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
import { getSearchLookups } from 'app/services/SharedServices/SharedServices';
import { selectUser } from 'oidc/user.selectors';
import { IFacilityRegionUpdateCommand } from '@AMIEWEB/Facility/FacilityStore/Facility.model';
import { usePromiseTracker } from 'react-promise-tracker';
import { useReadOnly, userRoles } from 'oidc/userRoles';
import { Authorized } from 'oidc/userHelper';
import { RegionSaveModal } from '@AMIEWEB/Facility/FacilityDetails/SidePanel/RegionSaveModal';
import { Row, RowContainer, RowContent } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/CustomComponents/styles';
import { CustomTooltip } from '../Tooltips/CustomTooltip';
import { useTranslation } from 'react-i18next';
import { IClientContactUnit } from 'app/models/Facility/FacilityDetails';

const useStyles = makeStyles()(theme => ({
  hide: {
    display: 'none !important',
  },
  divider: {
    margin: '0 0 12px 0',
    height: 2,
    'margin-bottom': '0',
  },
  avatarProfile: {
    height: '80px',
    width: '80px',
    marginRight: 10,
    fontSize: 30,
    backgroundColor: '#003C69',
    fontWeight: 500,
    textTransform: 'uppercase',
    position: 'inherit',
  },
  drawerRoot: {
    flexGrow: 1,
    width: 'inherit',
    overflowY: 'hidden',
  },
  profileSegment: {
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: '20px 0',
    width: '100% !important',
    overflowY: 'auto',
    overflowX: 'hidden',
    marginTop: '0',
    '&.top': {
      //'padding-bottom': '5px',
    },
    '&.bottom': {
      'padding-top': '5px',
    },
  },
  profileInfo: {
    padding: '0',
  },
  details: {
    wordBreak: 'keep-all',
    fontSize: '14px',

    padding: '4px 0px 4px 0px',

    display: 'flex',
  },
  closeIconPad: {
    fontSize: '1.5rem',
  },
  closeIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  profileInfoName: {
    fontSize: '18px',
    color: '#333333',
    fontWeight: 600,
    'line-height': '1.2',
  },
  bodyCandidateProfileDrawer: {
    paddingTop: '0',
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: '95vh',
  },
  profileDisplay: {
    display: 'flex',
  },
  avatarOrderProfile: {
    fontSize: '13px !important',
    textAlign: 'center',
    position: 'relative',
    'flex-direction': 'column',
    'text-align': 'center',
    padding: '0.5rem',
    'line-height': '1.2',
    'text-shadow': '2px 2px #003c69',
    '&.withGreenBoard': {
      border: `5px solid ${theme.palette.components.avatar.green.backgroundColor}`,
    },
  },
  lineText: {
    display: 'block',
    '&::before': {
      content: '""',
      display: 'block',
    },
  },
  headerLinkContent: {
    fontSize: 16,
    fontWeight: 450,
    margin: '0 5%',
    color: '#888888',
  },
  drawerOrderIdItem: {
    color: '#333333',
    'font-size': '1rem',
    'font-weight': '500',
  },
  drawerFacilityAddressSectionContainer: {
    padding: '0',
  },
  addressSectionEllipsis: {
    padding: '0px 0px 0px 6px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '220px',
    width: 'max-content',
    fontSize: '14px',
    color: `${theme.palette.framework.system.doveGray} !important`,
    opacity: 1,
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0px',
  },
  regionContainer: {
    wordBreak: 'break-all',
    fontSize: '14px',
    padding: '0px 0px 0px 6px',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0px',
    backgroundColor: `${theme.palette.framework.system.whisper} !important`,
  },
  drawerSidebarPhoneContainer: {
    padding: '0.5rem',
    'padding-left': '0',
    'padding-bottom': '0',
  },
  drawerSidebarPhone: {
    color: theme.palette.system.navyBlue,
    'font-weight': '600',
    'margin-left': '0.4rem',
  },
  inlineIcon: {
    'font-size': '1.25rem',
  },
  drawerSidebarWebsite: {
    marginLeft: '0.4rem',
    display: 'inline-block',
    width: '259px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  clickable: {
    cursor: 'pointer !important',
    color: `${theme.palette.system.navyBlue} !important`,
    fontWeight: 600,
    '&:hover': {
      'text-decoration': 'underline',
    },
  },
  filledOverride: {
    backgroundColor: theme.palette.framework.system.whisper,
    '&:hover, &.Mui-focused, &.Mui-disabled': {
      backgroundColor: theme.palette.framework.system.whisper,
    },
  },
}));

export interface IDrawerContactPartner {
  userId?: number;
  userRole: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  im?: string;
  description?: string;
  doNotCall?: number;
  statusId?: number;
  unitIds?: IClientContactUnit[];
  facilityId?: number;
  facilityName?: string;
  contactId?: number;
}

export interface IFacilityContacts {
  facilityId?: any;
  facilityName?: any;
  contactId?: number;
  userId?: number;
  title: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: any;
  doNotCall?: number;
  actions?: any;
  statusId?: number;
  units?: IClientContactUnit[];
}

export interface IDetailsDrawerComponent {
  closeProfileDrawer: () => void;
  openProfileDrawer: boolean;
  isBorderAvatar: boolean;
  avatarOffSet: string;
  avatarText: string;
  title: string;
  phone?: string;
  email?: string;
  address?: string;
  isTitleLink?: boolean;
  linkTo?: string;
  children?: React.ReactNode;
}

export interface IFacilityLocation {
  address1: string;
  address2: string;
  state: string;
  city: string;
  zipCode: string;
  region: string;
  county: string;
}

const RegionContainer = ({
  region,
  facilityId,
  classes,
  ...props
}: {
  region: string;
  facilityId: number;
  classes: any;
}) => {
  const regions = useSelector(selectFacilityRegionOptions);
  const editRegion = useSelector(selectEditFacilityRegion);
  const userDetails = useSelector(selectUser);
  const { readOnly } = useReadOnly();
  const dispatch = useDispatch();
  const [saveModal, setSaveModal] = useState<{
    isSaveModalOpen: boolean;
    updateCommand: IFacilityRegionUpdateCommand;
  }>({
    isSaveModalOpen: false,
    updateCommand: {
      facilityId: 0,
      regionId: 0,
      currentEmployeeId: 0,
      currentEmployeeName: '',
      IsPlacementOrderUpdateRequired: false,
    },
  });
  useEffect(() => {
    dispatch(facilityActions.getFacilityRegions());
  }, []);
  const authorized = React.useMemo(() => {
    return Authorized(
      [
        userRoles.accountManagement,
        userRoles.accountManagement_Leadership,
        userRoles.accountManagement_TeamMember,
        userRoles.clinical_ClientContractSpecialist,
      ],
      userDetails.userInfo,
    );
  }, [userDetails.userInfo]);

  const lockReason = !authorized ? (
    <>
      <Typography component="div" sx={theme => ({ color: theme.palette.framework.system.doveGray })}>
        Cannot be edited due to:
      </Typography>

      <Typography component="div" sx={theme => ({ color: theme.palette.framework.system.doveGray })}>
        <li>Role permissions</li>
      </Typography>
    </>
  ) : (
    ''
  );

  //translating the value to an object is required to feed the inlinedropdown the structure it expects
  const getDisplayedRegion = () => {
    let displayedRegion;
    if (editRegion?.id) {
      displayedRegion = { name: editRegion?.name, id: editRegion?.value };
    } else {
      displayedRegion = regions?.find(regionItem => regionItem.name === region);
    }
    return {
      id: displayedRegion?.value,
      name: displayedRegion?.name ?? '',
    };
  };

  const ignoreLookupSearchForSpecialCharacter = (searchKey: string) => {
    const lastCharacter = searchKey?.slice(-1);
    const specialCharacterPattern = /[!@#$%^&*()_+[\]{};:'"\\|,.<>/?]/;
    return specialCharacterPattern.test(lastCharacter);
  };

  const onChange = (input: { id: number; name: string; value: string }) => {
    dispatch(facilityActions.editFacilityRegion({ id: input.id, name: input.name }));
  };

  const onCancel = (key: string) => {
    dispatch(facilityActions.editFacilityRegion({ undefined }));
  };

  const fetchOptions = async (request: string) => {
    if (ignoreLookupSearchForSpecialCharacter(request)) return;
    const res = await getSearchLookups('facilityregion', request ?? '');
    return res;
  };

  const handleSaveFacilityRegion = () => {
    const updateCommand: IFacilityRegionUpdateCommand = {
      facilityId: facilityId,
      regionId: editRegion.id,
      currentEmployeeId: userDetails.userInfo.employeeId,
      currentEmployeeName: userDetails.userInfo?.firstName + ' ' + userDetails.userInfo?.lastName,
      IsPlacementOrderUpdateRequired: false,
    };
    dispatch(
      facilityActions.setFacilityRegionModalState({
        open: true,
        data: { updateCommand },
      }),
    );
  };

  const { promiseInProgress: isSaving } = usePromiseTracker({ area: 'update-facility-region' });
  return (
    <>
      <Grid container>
        {(readOnly || !!getDisplayedRegion()?.name) && (
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '12px !important', color: '#707070', fontWeight: 400 }}>Region</Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          {readOnly ? (
            <Typography>{getDisplayedRegion()?.name || missingField}</Typography>
          ) : (
            <InlineDropdown
              {...props}
              placeholder="Region"
              value={getDisplayedRegion()}
              onChange={onChange}
              fetchOptions={fetchOptions}
              options={regions}
              onApply={handleSaveFacilityRegion}
              isDirty={!!editRegion?.id && !editRegion?.helperText}
              helperText={editRegion?.helperText ?? ''}
              error={editRegion?.error ?? ''}
              isSaving={isSaving}
              lockReason={lockReason}
              removeGutters
              disabled={!authorized}
              inputRootClass={{ [classes.filledOverride]: true }}
              onCancel={onCancel}
            />
          )}
        </Grid>
      </Grid>
      <RegionSaveModal />
    </>
  );
};

const FacilityDrawerAddressContainer: React.FC<{
  location: IFacilityLocation;
  classes: any;
}> = ({ location, classes }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const addressLine1ContainerRef = useRef(null);
  const addressLine2ContainerRef = useRef(null);
  const addressLine3ContainerRef = useRef(null);

  const handleMouseEnter = () => {
    const { current: current1 } = addressLine1ContainerRef;
    const { current: current2 } = addressLine2ContainerRef;
    const { current: current3 } = addressLine3ContainerRef;
    setShowTooltip(
      current1?.scrollWidth > current1?.clientWidth ||
        current2?.scrollWidth > current2?.clientWidth ||
        current3?.scrollWidth > current3?.clientWidth,
    );
  };

  const generateAddressLine = (components: Array<{ value: string | null }>) =>
    components
      .map(({ value }) => isStrNull(value))
      .filter(Boolean)
      .join(' ');

  const firstLineAddress = location?.address1
    ? generateAddressLine([{ value: location.address1 }, { value: location.address2 }])
    : '';

  const secondLineAddress = location?.city
    ? generateAddressLine([{ value: `${location.city},` }, { value: location.state }, { value: location.zipCode }])
    : '';

  const thirdLineAddress = location?.county ? generateAddressLine([{ value: `${location.county} County` }]) : '';

  const tooltipContent = `${firstLineAddress}\n${secondLineAddress}\n${thirdLineAddress}`;

  return (
    <RowContainer>
      <Row>
        <RowContent>
          <CustomTooltip open={showTooltip} tooltipContent={tooltipContent}>
            {firstLineAddress ? (
              <div onMouseEnter={handleMouseEnter} onMouseLeave={() => setShowTooltip(false)}>
                <Typography ref={addressLine1ContainerRef} component="div" className={classes.addressSectionEllipsis}>
                  {firstLineAddress}
                </Typography>
                <Typography ref={addressLine2ContainerRef} component="div" className={classes.addressSectionEllipsis}>
                  {secondLineAddress}
                </Typography>
                <Typography ref={addressLine3ContainerRef} component="div" className={classes.addressSectionEllipsis}>
                  {thirdLineAddress}
                </Typography>
              </div>
            ) : (
              <div className={classes.addressSectionEllipsis}>{missingField}</div>
            )}
          </CustomTooltip>
        </RowContent>
      </Row>
    </RowContainer>
  );
};

export const HeaderContactDrawer = (props: IDetailsDrawerComponent) => {
  const {
    closeProfileDrawer,
    openProfileDrawer,
    isBorderAvatar,
    avatarOffSet,
    avatarText,
    title,
    phone,
    email,
    isTitleLink,
    linkTo,
    children,
  } = props;

  const facilityData = useSelector(selectFacility);
  const facilityWebsite = facilityData.facilityResponse.facilityWebsite
    ? `${facilityData.facilityResponse.facilityWebsite.trim()}`
    : '';
  const location = facilityData.facilityResponse.location;
  const { classes } = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <DrawerFitted
      open={openProfileDrawer}
      onDrawerClose={closeProfileDrawer}
      width={425}
      top={0}
      backgroundColor={theme.palette.framework.system.whisper}
    >
      <div className={classes.drawerRoot}>
        <LayoutGridItem container xs={12} lg={12} sm={12} md={12} direction="row" spacing={2} sx={{ paddingY: '16px' }}>
          <LayoutGridItem container xs={10} lg={10} sm={10} md={10}>
            <span className={`${classes.closeIcon} ${classes.headerLinkContent} ${classes.drawerOrderIdItem}`}>
              {t('facility.units.unitContacts.keyContacts')}
            </span>
          </LayoutGridItem>
          <LayoutGridItem container xs={2} lg={2} sm={2} md={2}>
            <IconButton
              color="primary"
              onClick={closeProfileDrawer}
              className={classes.closeIconPad}
              data-testid="dt-id-close-drawer"
              size="small"
            >
              <ClearIcon />
            </IconButton>
          </LayoutGridItem>
        </LayoutGridItem>
        <Divider orientation="horizontal" classes={{ root: classes.divider }} />
        <Grid item xs={12} className={classes.bodyCandidateProfileDrawer} id="body-contact">
          <Grid item xs={12} className={`${classes.profileSegment} top`}>
            <Grid item xs={3} sx={{ pl: '10px' }}>
              <Avatar
                className={`${classes.avatarProfile} ${isBorderAvatar ? `withGreenBoard` : ``} ${
                  classes.avatarOrderProfile
                }`}
              >
                <span className={classes.lineText}>{avatarOffSet}</span>
                <span className={classes.lineText}>{avatarText}</span>
              </Avatar>
            </Grid>
            <Grid item xs={9} className={classes.profileDisplay} justifyContent="space-between">
              <Grid item xs={12}>
                <div className={classes.profileInfo}>
                  <Grid item className={classes.profileInfoName}>
                    {isTitleLink ? <Link href={`${linkTo}`}>{title}</Link> : title}
                  </Grid>
                  <Grid item className={`${classes.details} ${!email ? classes.hide : ''}`}>
                    <Grid item xs={12} className={classes.profileDisplay} justifyContent="space-between" container>
                      <span className={classes.drawerSidebarPhoneContainer}>
                        <img
                          src={MailBoxIcon}
                          alt="MailBox"
                          color="disabled"
                          // fontSize="small"
                          className={classes.inlineIcon}
                        />
                        <span className={classes.drawerSidebarPhone}>{email}</span>
                      </span>
                    </Grid>
                  </Grid>
                  <Grid item className={`${classes.details} ${!phone ? classes.hide : ''}`}>
                    <Grid item xs={12} className={classes.profileDisplay} justifyContent="space-between" container>
                      <span className={classes.drawerSidebarPhoneContainer}>
                        <CallOutlinedIcon color="disabled" fontSize="small" className={classes.inlineIcon} />
                        <span className={classes.drawerSidebarPhone}>{phone}</span>
                      </span>
                    </Grid>
                  </Grid>
                  <Grid item className={classes.details}>
                    <Grid item xs={12} className={classes.profileDisplay} justifyContent="space-between" container>
                      <LanguageOutlinedIcon color="disabled" fontSize="small" className={classes.inlineIcon} />
                      <span className={classes.drawerSidebarWebsite}>
                        {facilityWebsite ? (
                          <span
                            className={classes.clickable}
                            onClick={event =>
                              facilityWebsite
                                ? openInNewTab(
                                    !facilityWebsite.startsWith('http') ? `http://${facilityWebsite}` : facilityWebsite,
                                  )
                                : event.stopPropagation()
                            }
                          >
                            {facilityWebsite}
                          </span>
                        ) : (
                          missingField
                        )}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid item className={classes.details}>
                    <Grid
                      item
                      xs={12}
                      className={`${classes.details} ${classes.drawerFacilityAddressSectionContainer}`}
                    >
                      <HomeIconOutlined color="disabled" fontSize="small" className={classes.inlineIcon} />
                      <Grid item xs={12}>
                        <FacilityDrawerAddressContainer classes={classes} location={location} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className={classes.details}>
                    <Grid item xs={12} className={classes.details}>
                      <PublicIcon color="disabled" fontSize="small" className={classes.inlineIcon} />
                      <Grid item xs={12} className={`${classes.regionContainer}`}>
                        <RegionContainer
                          classes={classes}
                          region={location.region}
                          facilityId={facilityData.facilityResponse.facilityId}
                          {...props}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item style={{ color: '#888888' }}>
                <MoreVertIcon />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={`${classes.profileSegment} bottom fullWidth resetRowLeftRightPadding`}>
            {children}
          </Grid>
        </Grid>
      </div>
    </DrawerFitted>
  );
};
