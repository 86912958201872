import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "types";

const selectDomain = (state: RootState) => state.facilityCommunicationHostory;

export const selectFacilityCommunicationLog = createSelector(
    [selectDomain],
    (domain) => domain?.FacilityCommunicationLog
);
export const selectGridLoading = createSelector(
    [selectDomain],
    (domain) => domain?.isGridLoading
);
