import { IAddScenarioRequestPayload } from '@AMIEWEB/MarginTool/Components/PayPackage/AddScenario/model';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import baseAxiosInstance from 'utils/BaseApi';

export class MarginToolService {
  private sharedBase: string;

  private cancellationToken = axios.CancelToken.source();

  private static currentInstance: MarginToolService;

  config?: AxiosRequestConfig = {};

  private constructor() {
    this.sharedBase = globalThis?.app?.env?.REACT_APP_TRACK_CHANGE_SERVICE_BASE_URL;
  }

  static createInstance(): MarginToolService {
    const activeInstance = new MarginToolService();
    // activeInstance.cancellationToken = axios.CancelToken.source();
    // if (activeInstance.config) activeInstance.config.cancelToken = activeInstance.cancellationToken.token;
    return activeInstance;
  }

  static getInstance(): MarginToolService {
    if (!this.currentInstance) this.currentInstance = this.createInstance();
    return this.currentInstance;
  }

  static cancelActiveRequests() {
    this.currentInstance.cancellationToken.cancel('RequestCancellation');
    // this.currentInstance = null;
  }

  cancelRequests() {
    this.cancellationToken.cancel('RequestCancellation');
    return MarginToolService.getInstance();
  }

  cancelAndRevokeInstance() {
    this.cancellationToken.cancel('RequestCancellation');
    this.cancellationToken = axios.CancelToken.source();
    if (this.config) this.config.cancelToken = this.cancellationToken.token;
  }

  getMarginToolData = () =>
    baseAxiosInstance.get(`${this.sharedBase}/placement/marginTool`).catch((error: AxiosError) => error.response);

  getMarginToolAddScenarioDetailsData = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.sharedBase}/placement/${placementId}/add-scenario-details`)
      .catch((error: AxiosError) => error.response);

  getMarginToolDetails = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.sharedBase}/placement/${placementId}/margin-details`)
      .catch((error: AxiosError) => error.response);

  getShiftData = () =>
    baseAxiosInstance.get(`${this.sharedBase}/shift/details`).catch((error: AxiosError) => error.response);

  getlistofPayPackageStatus = (status: number, category: number) =>
    baseAxiosInstance
      .get(`${this.sharedBase}/margin/status-list/${status}/${category}`)
      .catch((error: AxiosError) => error.response);

  postAddScenarioModal = (payload: IAddScenarioRequestPayload) =>
    baseAxiosInstance
      .post(`${this.sharedBase}/placement/add-scenario-details`, payload)
      .catch((error: AxiosError) => error.response);

  getMarginDetails = (placementId: number, scenarioId: number) =>
    baseAxiosInstance
      .get(`${this.sharedBase}/placement/${placementId}/scenario/${scenarioId}/basic-calculation-details`)
      .catch((error: AxiosError) => error.response);
}
