import { XGridDefaultColumn } from 'app/components/Common/XGrid/cells/XGridDefaults';

function extractText(node) {
  if (typeof node === 'string') {
    return node;
  } else if (Array.isArray(node)) {
    return node.map(extractText).join('');
  } else if (node?.props && node?.props?.children) {
    return extractText(node?.props?.children);
  }
  return '';
}

const CommunicationHistoryDefaultCell = {
  ...XGridDefaultColumn,
  flex: 0,
};

export const FacilityCommunicationHistoryViewInfo = t => [
  {
    ...CommunicationHistoryDefaultCell,
    field: 'communicationType',
    headerName: t('Communication Type'),
    width: 160,
    sortComparator: (v1, v2, param1, param2) => {
      if (param1.field === 'communicationType') {
        const text1 = extractText(v1.props.children[1]);
        const text2 = extractText(v2.props.children[1]);
        return text1.localeCompare(text2) * (param1.sort === 'asc' ? -1 : 1);
      }
      return 0;
    },
    valueFormatter: params => {
      return extractText(params.value.props.children[1]);
    },
  },
  {
    ...CommunicationHistoryDefaultCell,
    field: 'contactName',
    headerName: t('Contact Name'),
    width: 150,
  },
  {
    ...CommunicationHistoryDefaultCell,
    field: 'contactInfo',
    headerName: t('Contact Info'),
    width: 150,
  },
  {
    ...CommunicationHistoryDefaultCell,
    field: 'date',
    headerName: t('Date'),
    width: 180,
  },
  {
    ...CommunicationHistoryDefaultCell,
    field: 'from',
    headerName: t('From'),
    width: 150,
  },
  {
    ...CommunicationHistoryDefaultCell,
    field: 'userRole',
    headerName: t('User Role'),
    width: 350,
    sortable: false,
  },
  {
    ...CommunicationHistoryDefaultCell,
    field: 'status',
    headerName: t('Status'),
    width: 180,
  },
  {
    ...CommunicationHistoryDefaultCell,
    field: 'type',
    headerName: t('Type'),
    sortable: false,
    width: 150,
    valueFormatter: params => {
      return `Inbound: ${
        params.value.props.inboundCount === undefined ? 0 : params.value.props.inboundCount
      } , Outbound: ${
        params.value.props.outboundCount === undefined ? 0 : params.value.props.outboundCount
      } , Undelivered: ${params.value.props.undeliveredCount === undefined ? 0 : params.value.props.undeliveredCount}`;
    },
  },
  {
    ...CommunicationHistoryDefaultCell,
    field: 'notes',
    headerName: t('Notes/Subject'),
    width: 250,
    sortable: false,
    valueFormatter: params => {
      let notes = extractText(params.value?.props?.children) ?? extractText(params.value?.props?.children[0]);
      return notes === 'Add Note' ? '' : notes;
    },
  },
];

export const FacilityCommunicationHistoryVisibiltyModel = {
  communicationType: true,
  contactName: true,
  contactInfo: true,
  date: true,
  from: true,
  userRole: true,
  type: true,
  status: true,
  notes: true,
  placementId: true,
  recruiter: true,
};
