import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { GetMomentOfDate } from 'utils/dates/moment';
import { missingField } from 'app/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BoxContainer } from '../../CustomComponents/BoxContainer';
import { selectContractDetails, selectFacilityDetail } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { facilityActions } from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
import LaunchIcon from '@mui/icons-material/Launch';
import { Grid, Link } from 'amn-ui-core';
import { RowSection } from '../../GeneralTab/Utils';
import { LayoutGrid } from 'app/layout/LayoutGrid';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import { ContractDetailsWrapper } from './ContractDetailsWrapper';
import { openInNewTab } from 'app/helpers/openInNewTab';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { theme } from 'styles/global-styles';

const useStyles = makeStyles()({
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  smallIcon: {
    fontSize: '20px',
  },
  dialogContainer: {
    minWidth: '800px',
  },
  dialogContent: {
    padding: 0,
    overflow: 'hidden',
  },
});

export const ContractDetails = () => {
  const params = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const contractDetails = useSelector(selectContractDetails);
  const [fixedRowHeaderWidth, setFixedRowHeaderWidth] = React.useState(0);
  const facilityDetails = useSelector(selectFacilityDetail);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isPopupOpen, setPopupOpen] = useState<boolean>(false);
  const handleExpandChange = (expanded: boolean) => setIsExpanded(expanded);

  useEffect(() => {
    if (params?.id) dispatch(facilityActions.getFacilityContractAction(Number(params?.id)));
  }, [facilityDetails?.facilityId]);

  const handleMeasureWidth = (width: number) => {
    setFixedRowHeaderWidth(oldWidth => Math.max(oldWidth, width));
  };

  const getDate = (date: string) => {
    const moment = GetMomentOfDate(date);
    if (moment.isValid()) return moment.format('MM/DD/YYYY');
    return '';
  };

  const handleContractLink = () => {
    const contractUrl = `${globalThis?.app?.env.REACT_APP_FACILITY_CONTRACTS}${Number(params?.id)}`;
    openInNewTab(contractUrl);
  };

  return (
    <>
      <BoxContainer
        title={t('order.facility.rateCard.contractDetails.title')}
        iconToolTip={t('order.facility.rateCard.contractDetails.tooltipMessage')}
        onClick={() => setPopupOpen(true)}
        rightIcon={contractDetails && <LaunchIcon className={classes.smallIcon} />}
      >
        {contractDetails ? (
          <Grid container direction="row" spacing={theme.spacing(16)}>
            <Grid item>
              <RowSection
                header={t('order.facility.rateCard.contractDetails.contractStatus')}
                data={contractDetails.status || missingField}
                handleMeasureWidth={handleMeasureWidth}
                fixedHeaderWidth={fixedRowHeaderWidth}
              />
              <RowSection
                header={t('order.facility.rateCard.contractDetails.modDate')}
                data={
                  contractDetails.contractModDate ? getDate(contractDetails.contractModDate.toString()) : missingField
                }
                handleMeasureWidth={handleMeasureWidth}
                fixedHeaderWidth={fixedRowHeaderWidth}
              />
              <RowSection
                header={t('order.facility.rateCard.contractDetails.effectiveDate')}
                data={
                  contractDetails.contractEffectiveDate
                    ? getDate(contractDetails.contractEffectiveDate.toString())
                    : missingField
                }
                handleMeasureWidth={handleMeasureWidth}
                fixedHeaderWidth={fixedRowHeaderWidth}
              />
              <RowSection
                header={t('order.facility.rateCard.contractDetails.expirationDate')}
                data={
                  contractDetails.expirationDate ? getDate(contractDetails.expirationDate.toString()) : missingField
                }
                handleMeasureWidth={handleMeasureWidth}
                fixedHeaderWidth={fixedRowHeaderWidth}
              />
              <RowSection
                header={t('order.facility.rateCard.contractDetails.paymentTerms')}
                data={contractDetails.netTerms || missingField}
                handleMeasureWidth={handleMeasureWidth}
                fixedHeaderWidth={fixedRowHeaderWidth}
                hoverToolTipWidth={true}
              />
              <RowSection
                header={t('order.facility.rateCard.contractDetails.autoRenewal')}
                data={contractDetails.autoRenewal || missingField}
                handleMeasureWidth={handleMeasureWidth}
                fixedHeaderWidth={fixedRowHeaderWidth}
              />
            </Grid>
            <Grid item>
              <RowSection
                header={t('order.facility.rateCard.contractDetails.contractSource')}
                data={contractDetails.contractSource || missingField}
                handleMeasureWidth={handleMeasureWidth}
                fixedHeaderWidth={fixedRowHeaderWidth}
              />
              <RowSection
                header={t('order.facility.rateCard.contractDetails.amnDivisionSource')}
                data={contractDetails.amnDivisionSource || missingField}
                handleMeasureWidth={handleMeasureWidth}
                fixedHeaderWidth={fixedRowHeaderWidth}
                hoverToolTipWidth={true}
              />
              <RowSection
                header={t('order.facility.rateCard.contractDetails.fees.vmFee')}
                data={contractDetails?.vmFeeAmount ? _.concat(contractDetails.vmFeeAmount, ' %') : missingField}
                handleMeasureWidth={handleMeasureWidth}
                fixedHeaderWidth={fixedRowHeaderWidth}
              />
              <RowSection
                header={t('order.facility.rateCard.contractDetails.fees.otherFees')}
                data={contractDetails?.otherFeesAmount ? _.concat(contractDetails.otherFeesAmount, ' %') : missingField}
                handleMeasureWidth={handleMeasureWidth}
                fixedHeaderWidth={fixedRowHeaderWidth}
              />
              <RowSection
                header={t('order.facility.rateCard.contractDetails.contract')}
                data={
                  (
                    <Link onClick={() => handleContractLink()}>
                      {t('order.facility.rateCard.contractDetails.viewContract')}
                    </Link>
                  ) || missingField
                }
                handleMeasureWidth={handleMeasureWidth}
                fixedHeaderWidth={fixedRowHeaderWidth}
              />
            </Grid>
          </Grid>
        ) : (
          <LayoutGrid direction="column">
            <div className={classes.center}>{t('order.facility.rateCard.delightMessage')}</div>
          </LayoutGrid>
        )}
      </BoxContainer>
      {isPopupOpen && (
        <GenericDialog
          variant={'blue'}
          fullWidth
          classes={{
            paper: classes.dialogContainer,
          }}
          open={isPopupOpen}
          disablePortal
          disableEscapeKeyDown
          dialogTitleProps={{
            text: t('order.facility.rateCard.contractDetails.title'),
            closeButton: true,
            expandable: true,
            onExpandChange: handleExpandChange,
          }}
          dialogContentProps={{
            classes: { root: classes.dialogContent },
          }}
          onClose={() => setPopupOpen(false)}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ContractDetailsWrapper contractId={contractDetails?.contractId} isExpanded={isExpanded} />
            </Grid>
          </Grid>
        </GenericDialog>
      )}
    </>
  );
};
