import React, { useState } from 'react';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { AddEmailTemplate } from './AddEmailTemplate';
import { Button, Grid, Paper, Snackbar, Typography } from 'amn-ui-core';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { ISaveTemplate, ITemplate, IUpdateTemplate } from 'app/models/Notification/Notification';
import { ChannelType } from '@AMIEWEB/Notification/Constants';
import { TemplateUserTypes } from '../Common/helper';
import { selectUser } from 'oidc/user.selectors';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import {
  selectIsEdit,
  selectPacketFacilityTemplates,
  selectPacketFacilityTemplatesById,
} from 'store/redux-store/notification/notification.selector';
import _ from 'lodash';

const useStyles = makeStyles<{ isExpanded: boolean }>()((theme, { isExpanded = false }) => ({
  title: {
    fontSize: 20,
  },
  modalContent: {
    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .dialogContentRoot': {
      padding: 0,
    },
    overflow: 'hidden',
  },
  dialogContent2: {
    fontSize: '15px',
    overflow: 'hidden',
  },
  modalContainer: {
    padding: '24px',
    height: !isExpanded ? 554 : '100%',
    overflowY: 'auto',
  },
  closeDialog: {
    position: 'fixed',
    bottom: -50,
  },
  expandedCloseDialog: {
    position: 'fixed',
    bottom: 0,
  },
  changeTemplatePaper: {
    background: theme.palette.system.black,
    borderRadius: '5',
    margin: '2% 1% 0 0',
    height: '50px',
    padding: '5px',
    width: '440px',
  },
  action: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '7%',
  },
  actionBtn: {
    fontSize: 12,
    padding: 0,
    minWidth: 30,
  },
  actionNoBtn: {
    color: theme.palette.system.neutralGray,
    paddingRight: '2%',
  },
  actionYesBtn: {
    color: `${theme.palette.components.icons.secondary.backgroundColor} !important`,
  },
  container: {
    margin: '2%',
    alignItems: 'center',
  },
  content: {
    justify: 'center',
    color: theme.palette.system.white,
    paddingLeft: '2%',
    fontWeight: 'lighter',
  },
}));

interface IAddTemplateModal {
  open: boolean;
  onCancel: () => void;
  facilityId: number;
}

export enum TemplateCategory {
  PacketSubmission = 'Packet Submission',
}

export interface IError {
  templateSaveTitleError: string;
  templateSaveDescError: string;
  templateSaveCategoryError: string;
}

export const initialState: ITemplate = {
  title: '',
  id: '',
  body: '',
  description: '',
  category: TemplateCategory.PacketSubmission,
  isPrivate: false,
  substitutions: [],
};

const initialStateError: IError = {
  templateSaveTitleError: '',
  templateSaveDescError: '',
  templateSaveCategoryError: '',
};

export const AddTemplateModal = ({ open, onCancel, facilityId }: IAddTemplateModal) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const packetFacilityTemplatesById = useSelector(selectPacketFacilityTemplatesById);
  const isEdit = useSelector(selectIsEdit);
  const user = useSelector(selectUser);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { classes } = useStyles({ isExpanded });
  const [closeModal, setCloseModal] = useState<boolean>(false);
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const facilityTitles = useSelector(selectPacketFacilityTemplates);
  const [emailTemplates, setEmailTemplates] = useState<ITemplate>(isEdit ? packetFacilityTemplatesById : initialState);
  const [templateError, setTemplateError] = useState<IError>(initialStateError);
  const [isPreview, setIsPreview] = useState<boolean>(false);

  const handleExpandChange = (expanded: boolean) => setIsExpanded(expanded);

  const onCancelConfirmation = () => {
    cancelAndReset();
    setCloseModal(false);
  };

  const ConfirmCloseContent = () => {
    const SnackbarAction = () => (
      <div className={classes.action}>
        <Button
          variant="text"
          classes={{ root: classes.actionBtn, text: classes.actionNoBtn }}
          onClick={() => setCloseModal(false)}
        >
          {t('defaultPage.no')}
        </Button>
        <Button
          variant="text"
          classes={{ root: classes.actionBtn, text: classes.actionYesBtn }}
          onClick={onCancelConfirmation}
        >
          {t('defaultPage.yes')}
        </Button>
      </div>
    );

    return (
      <Snackbar
        open
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        className={isExpanded ? classes.expandedCloseDialog : classes.closeDialog}
        autoHideDuration={3000}
      >
        <Paper className={classes.changeTemplatePaper} elevation={15}>
          <Grid container spacing={2} className={classes.container}>
            <ReportProblemOutlinedIcon color="warning" />
            <Typography className={classes.content} variant="subtitle2">
              {t('notification.profileDrawer.closeConfirmation')}
            </Typography>
            <SnackbarAction />
          </Grid>
        </Paper>
      </Snackbar>
    );
  };

  const cancelAndReset = () => {
    setIsEdited(false);
    setIsPreview(false);
    setEmailTemplates(initialState);
    setTemplateError(initialStateError);
    onCancel();
    dispatch(notificationDataActions.setIsEdit(false));
  };

  const handleClose = () => {
    if (isEdited) {
      setCloseModal(true);
    } else {
      cancelAndReset();
    }
  };

  const createEmailTemplate = () => {
    const payload: ISaveTemplate = {
      id: emailTemplates.id,
      title: emailTemplates.title,
      description: emailTemplates.description,
      category: emailTemplates.category,
      body: emailTemplates.body,
      isPrivate: emailTemplates.isPrivate,
      substitutions: emailTemplates.substitutions,
      channel: ChannelType.email,
      userType: TemplateUserTypes.Facility.toString(),
      userId: facilityId?.toString(),
      createdBy: user.userInfo.employeeId?.toString(),
    };
    dispatch(notificationDataActions.createFacilityTemplate(payload));
    cancelAndReset();
  };

  const updateEmailTemplate = () => {
    const currentDate = new Date();
    const payload: IUpdateTemplate = {
      id: emailTemplates.id,
      title: emailTemplates.title,
      description: emailTemplates.description,
      category: emailTemplates.category,
      body: emailTemplates.body,
      isPrivate: emailTemplates.isPrivate,
      substitutions: emailTemplates.substitutions,
      channel: ChannelType.email,
      userType: TemplateUserTypes.Facility.toString(),
      userId: facilityId?.toString(),
      updatedBy: user.userInfo.employeeId?.toString(),
      updatedOn: currentDate,
    };
    dispatch(notificationDataActions.updateFacilityEmailTemplates(payload));
    cancelAndReset();
  };

  const handleSave = () => {
    !isEdit ? createEmailTemplate() : updateEmailTemplate();
  };

  const isDisabled = () => {
    return (
      !_.isEmpty(templateError.templateSaveTitleError) ||
      !_.isEmpty(templateError.templateSaveDescError) ||
      _.isEmpty(emailTemplates.title) ||
      _.isEmpty(emailTemplates.description) ||
      !isEdited
    );
  };

  return (
    <GenericDialog
      open={open}
      draggable
      disablePortal
      disableEscapeKeyDown
      dialogTitleProps={{
        text: isPreview
          ? !isEdit
            ? t('Preferences.previewTemplateTitle')
            : t('Preferences.previewEditTemplateTitle')
          : !isEdit
          ? t('Preferences.addTemplateTitle')
          : t('Preferences.editTemplateTitle'),
        expandable: true,
        classes: { root: classes.title },
        closeButton: true,
        onExpandChange: handleExpandChange,
      }}
      dialogContentProps={{
        classes: { root: classes.dialogContent2 },
      }}
      dialogActions={[
        {
          text: t('Preferences.cancel'),
          variant: 'contained',
          color: 'tertiary',
          onClick: handleClose,
        },
        {
          text: isPreview ? t('Preferences.editTemplate') : t('Preferences.previewTemplate'),
          variant: 'contained',
          color: 'tertiary',
          onClick: () => setIsPreview(!isPreview),
        },
        {
          text: t('Preferences.save'),
          variant: 'contained',
          color: 'primary',
          onClick: () => handleSave(),
          disabled: isDisabled(),
        },
      ]}
      fullWidth={true}
      className={classes.modalContent}
      maxWidth={'md'}
      variant={'blue'}
      onClose={handleClose}
    >
      <div className={classes.modalContainer}>
        <AddEmailTemplate
          expanded={isExpanded}
          emailTemplates={emailTemplates}
          templateError={templateError}
          setIsEdited={setIsEdited}
          setEmailTemplates={setEmailTemplates}
          setTemplateError={setTemplateError}
          isPreview={isPreview}
          facilityTitles={facilityTitles}
        />
      </div>
      {closeModal && <ConfirmCloseContent />}
    </GenericDialog>
  );
};
