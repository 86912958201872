import React from 'react';
import { TextField, Box } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import SearchIcon from '@mui/icons-material/Search';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { useDispatch, useSelector } from 'react-redux';
import { FacilityContactsActions } from 'app/components/Facility/FacilityDetails/FacilityTabs/ContactTab/Store/FacilityContacts.redux';
import {
  selectContactsGridData,
  selectContactsSourceData,
} from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/ContactTab/Store/FacilityContacts.selector';
import { UnitClientContactGrid } from '@AMIEWEB/Unit/ClientContact/UnitClientContactGrid';
import { useTranslation } from 'react-i18next';
import { getUpdatedFilteredValue } from '@AMIEWEB/Unit/ClientContact/UnitClientContactHeader';
import { gridSelectionActions } from '@AMIEWEB/Common/Grid/GridSelection/GridSelection.redux';
import { useFormContext } from 'react-hook-form';

export interface IClientContact {
  contactId: number;
  firstName: string;
  firstNameSortable: string;
  lastName: string;
  name: string;
  lastNameAttributes: any;
  title: string;
  email: string;
  goesBy: string;
  city: string;
  state: string;
  units: string;
  unitAttributes: any;
  unitsSortField: string;
  actions: string;
  actionAttributes: any;
  actionsSortField: string;
  actionsSort: number;
}

const useStyles = makeStyles()(theme => ({
  searchField: {
    height: '45px',
    width: '100%',
    borderBottom: `1px solid ${theme.palette.system.lightGrey}`,
  },
  wrapper: {
    backgroundColor: `1px solid ${theme.palette.components.box.container.backgroundColor}`,
    border: `1px solid ${theme.palette.system.lightGrey}`,
    borderRadius: 5,
  },
  noBorder: {
    border: 'hidden',
  },
  verticalScroll: {
    '& .MuiDataGrid-virtualScroller': {
      overflow: 'scroll !important',
    },
  },
  gridAlignment: {
    marginTop: '-6px',
    '& .MuiDataGrid-autoHeight': {
      borderRadius: '0px',
      minHeight: '0px',
    },
    '& .MuiDataGrid-main': {
      minHeight: '0px !important',
    },
    '& .MuiDataGrid-toolbarContainer': {
      height: '40px',
    },
    '& .MuiButtonBase-root.MuiCheckbox-root.Mui-checked': {
      color: `${theme.palette.system.main} !important`,
    },
    '& .MuiDataGrid-virtualScroller': {
      maxHeight: '375px !important',
    },
    '& .MuiDataGrid-cell': {
      outline: 'none !important',
    },
    '& .MuiDataGrid-columnHeader': {
      outline: 'none !important',
    },
  },
}));
export const ClientContactWrapper = ({ onChange, value }) => {
  const { getValues } = useFormContext();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const contactsGridSourceData = useSelector(selectContactsSourceData);
  const gridData = useSelector(selectContactsGridData);
  const handleChange = data => {
    let newValue = value
      ?.map(each => {
        if (each.clientContactId === data.clientContactId) {
          if (!data.selected) {
            return null;
          }
          return {
            clientContactId: data.clientContactId,
            isPrimary: data.isPrimary,
          };
        } else {
          return each;
        }
      })
      .filter(obj => obj !== null);
    if (value?.every(e => e.clientContactId !== data.clientContactId)) {
      if (data.selected) {
        newValue.push({
          clientContactId: data.clientContactId,
          isPrimary: data.isPrimary,
        });
        onChange(newValue);
      }
    } else {
      onChange(newValue);
    }
  };
  const handleAllSelection = selectedData => {
    onChange(selectedData);
  };
  const handleSearch = async event => {
    if (event.target.value?.length >= 1) {
      globalSearch(event.target.value.toLowerCase());
    } else {
      const updatedValue = getUpdatedFilteredValue(contactsGridSourceData, getValues('unitContacts'));
      dispatch(FacilityContactsActions.setFilteredData(updatedValue.filteredData));
      dispatch(
        gridSelectionActions.setGridSelections({
          selectedData: updatedValue.selectedIndices,
          isCompareGridOpen: false,
        }),
      );
    }
  };

  const globalSearch = searchInput => {
    const filteredData = contactsGridSourceData.filter(value => {
      return (
        value?.firstName?.props?.displayValue?.toLowerCase()?.includes(searchInput?.toLowerCase()) ||
        value?.lastName?.toLowerCase()?.includes(searchInput?.toLowerCase()) ||
        value?.goesBy?.toLowerCase()?.includes(searchInput?.toLowerCase()) ||
        value?.title?.toLowerCase()?.includes(searchInput?.toLowerCase()) ||
        value?.actionsSortField?.toLowerCase()?.includes(searchInput?.toLowerCase()) ||
        value?.unitsSortField?.toLowerCase()?.includes(searchInput?.toLowerCase()) ||
        value?.email?.toLowerCase()?.includes(searchInput?.toLowerCase()) ||
        value?.city?.toLowerCase()?.includes(searchInput?.toLowerCase()) ||
        value?.state?.toLowerCase()?.includes(searchInput?.toLowerCase())
      );
    });
    const updatedValue = getUpdatedFilteredValue(filteredData, getValues('unitContacts'));
    dispatch(FacilityContactsActions.setFilteredData(updatedValue.filteredData));
    dispatch(
      gridSelectionActions.setGridSelections({
        selectedData: updatedValue.selectedIndices,
        isCompareGridOpen: false,
      }),
    );
  };

  return (
    <Box className={classes.wrapper}>
      <LayoutGrid container direction="column" spacing={1}>
        <LayoutGridItem item xs={12}>
          <TextField
            variant="outlined"
            color="primary"
            placeholder="Search"
            autoComplete="on"
            onChange={handleSearch}
            fullWidth
            classes={{ root: classes.searchField }}
            InputProps={{
              startAdornment: (
                <SearchIcon style={{ opacity: 0.6, height: '20px', width: '17px', marginRight: '10px' }} />
              ),
              classes: { notchedOutline: classes.noBorder },
            }}
          />
        </LayoutGridItem>
        <LayoutGridItem
          item
          xs={12}
          className={`${classes.gridAlignment} ${gridData?.length > 10 ? classes.verticalScroll : ''}`}
        >
          {contactsGridSourceData && (
            <UnitClientContactGrid
              gridTitle={t('facility.units.unitContacts.titleNewEditUnitPage')}
              handleChange={handleChange}
              handleAllSelection={handleAllSelection}
            />
          )}
        </LayoutGridItem>
      </LayoutGrid>
    </Box>
  );
};
