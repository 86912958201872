import { createSelector } from '@reduxjs/toolkit';
import { ITypeAheadOption } from 'app/models/Orders/OrderDetails';
import { IConfirmation } from 'app/models/Placement/PlacementSummary';
import { RootState } from 'types';
import { isStrikOrder } from 'utils/common/Placements/Automation';
import { initialState } from './slice';
import { getGenderLabel } from './sagaHelper';

const selectDomain = (state: RootState) => state.placementSummary || initialState;

export const selectPlacementSummary = createSelector([selectDomain], summary => summary);

export const selectPlacementPeopleSoft = createSelector([selectDomain], state => state.peopleSoft);

export const selectSubmissionInfo = createSelector([selectDomain], summary => summary.submissionInfo);

export const selectInterviewInfo = createSelector([selectDomain], summary => summary.interviewInfo);

export const selectOfferInfo = createSelector([selectDomain], summary => summary.offerInfo);

export const selectBookingInfo = createSelector([selectDomain], summary => summary.bookingInfo);

export const selectExtensionInfo = createSelector([selectDomain], summary => summary.extensionsInfo);

export const selectPicklists = createSelector([selectDomain], summary => ({
  ...summary.offerInfo.transportation.picklists,
}));

export const selectServiceFailures = createSelector([selectDomain], summary => summary.serviceFailures);

export const selectIsStrikePlacement = createSelector([selectDomain], summary =>
  isStrikOrder({
    division: summary.orderInformation?.division,
    orderType: summary.orderInformation?.orderType,
  }),
);

export const selectPlacementOrderUnit = createSelector([selectDomain], summary => summary.orderInformation.unitId);

export const selectAuditDetails = createSelector([selectDomain], summary => summary.auditDetailsInfo);

export const selectReferenceAndEmploymentVerificationRecords = createSelector(
  [selectDomain],
  summary => summary.referenceAndEmploymentVerificationRecords,
);

const defaultConfirmation = { checked: false, lastUpdatedAt: null, lastUpdatedBy: null } as IConfirmation;

export const selectEditableRequirementTypes = createSelector([selectDomain], state => state.editableRequirementTypes);

export const selectSubmissionPacketDetail = createSelector([selectDomain], state => state.submissionPacketDetail);

export const selectEditableFields = createSelector(
  [selectDomain],
  ({
    submissionInfo: {
      orderReq: {
        candidate: { schedule },
        order: { schedule: orderSchedule },
      },
    },
    offerInfo: {
      confirmation: { datesConfirmed, travelConfirmed, dnsCleared },
      transportation: tData,
    },
    bookingInfo: {
      credentialing: { finalizedByQs, gtgComplete },
      confirmation: { arrived, directDeposit, wisely },
    },
  }) => ({
    requestedShift: schedule?.data?.requestedShift,
    placementShift: !!orderSchedule?.data?.shiftOfferedId
      ? {
          object: {
            id: orderSchedule?.data?.shiftOfferedId,
            name: orderSchedule?.data?.shiftOffered?.data,
            value: orderSchedule?.data?.shiftOfferedId,
          },
          label: orderSchedule?.data?.shiftOffered?.data,
        }
      : null,
    transportationChosen: {
      transportationId: tData?.transportation?.data?.transportationId,
      transportation: tData?.transportation?.data?.transportation,
    },
    travelTimePreferenceChosen: !tData?.travelTimePreference?.data?.travelTimePreferenceId
      ? null
      : ({
          object: {
            travelTimePreferenceId: tData?.travelTimePreference?.data?.travelTimePreferenceId,
            travelTimePreference: tData?.travelTimePreference?.data?.travelTimePreference,
          },
          label: tData?.travelTimePreference?.data?.travelTimePreference,
        } as ITypeAheadOption),
    gender: !tData?.gender?.data
      ? null
      : ({
          object: tData?.gender?.data,
          label: getGenderLabel(tData?.gender?.data),
        } as ITypeAheadOption),
    departureAirport: !tData?.departureAirport?.data
      ? null
      : ({
          object: tData?.departureAirport?.data,
          label: tData?.departureAirport?.data,
        } as ITypeAheadOption),
    arrivalDate: tData?.arrivalDate?.data,
    arrivalTime: tData?.arrivalTime?.data,
    finalizedByQs: finalizedByQs?.data || defaultConfirmation,
    gtgComplete: gtgComplete?.data || defaultConfirmation,
    datesConfirmed: datesConfirmed?.data || defaultConfirmation,
    dnsCleared: dnsCleared?.data || defaultConfirmation,
    travelConfirmed: travelConfirmed?.data || defaultConfirmation,
    arrived: arrived?.data || defaultConfirmation,
    directDeposit: directDeposit?.data || defaultConfirmation,
    wisely: wisely?.data || { checked: null, lastUpdatedAt: null },
  }),
);

export const selectClassicSubmissionPacketInOkToSendExists = createSelector(
  [selectDomain],
  state => state.classicPacketInOkToSendExists,
);
