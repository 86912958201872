import { createAction } from '@reduxjs/toolkit';
import { orderCreationNewSlice } from './OrderCreation.redux';
import { IOrderFreeText } from 'app/models/Orders/OrderDetails';
import { IEditOrderActionPayload } from '../types';
import { IOrderQualificationFreeText } from '../constants';
import { NewUnit } from 'app/models/Unit/CreateUnit';

export const getOptionsForPage2 = createAction('GET_OPTIONS_PAGE2');
export const setDefaultValuesPage2 = createAction('SET_DEFAULT_VALUES_PAGE2');
export const setDefaultFreeTextValuesPage3 = createAction('SET_DEFAULT_FREE_TEXT_VALUES_PAGE3');
export const setTypeAheadOptions = createAction('SET_TYPEAHEAD_OPTIONS');
export const getSkillsetForSelectedUnitAction = createAction<{
  unitId: number;
}>('GET_SKILLSETS_FOR_SELECTED_UNIT');
const onRequestEditOrder = createAction<{ orderId: string }>('ORDER_ON_REQUEST_EDIT');
export const onSubmitOrder = createAction<any>('ON_SUBMIT_ORDER');
export const onEditOrder = createAction<IEditOrderActionPayload>('ON_EDIT_ORDER');
export const onEditOrderInheritUnit = createAction('ON_EDIT_ORDER_INHERIT_UNIT');
export const onEditQualifications = createAction<IOrderFreeText>('ON_EDIT_QUALIFICATIONS');
export const initOrderFromUnitPage = createAction<{ unitId: number; updatedUnit?: NewUnit; changeRoute?: () => void }>(
  'INIT_ORDER_FROM_UNIT_PAGE',
);
export const onUpdateQualificationFreeText = createAction<IOrderQualificationFreeText>(
  'ON_UPDATE_QUALIFICATIONS_FREE_TEXT',
);
export const getOrderLicenseCompactStatusOptions = createAction('GET_ORDER_LICENSE_COMPACT_STATUS_OPTIONS');
export const getOrderLicenseOptions = createAction<string>('GET_ORDER_LICENSE_OPTIONS');

const getClientContacts = createAction<{
  facilityId: number;
  unitId: number;
}>('GET_CLIENT_CONTACTS');
export const orderCreationNewActions = {
  ...orderCreationNewSlice.actions,
  getOptionsForPage2,
  setDefaultValuesPage2,
  setDefaultFreeTextValuesPage3,
  setTypeAheadOptions,
  getSkillsetForSelectedUnitAction,
  onRequestEditOrder,
  onSubmitOrder,
  onEditOrder,
  onEditQualifications,
  onUpdateQualificationFreeText,
  getClientContacts,
  onEditOrderInheritUnit,
  initOrderFromUnitPage,
  getOrderLicenseCompactStatusOptions,
  getOrderLicenseOptions,
};
