import { IFacilityLogs } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/CommunicationHistory/store/FacilityCommunicationHistory.redux';
import { IGetActivityFeedQuery } from 'app/models/ActivityFeed/IGetActivityFeed';
import { IUpdateActivityFeedReadStatusCommand } from 'app/models/ActivityFeed/IUpdateActivityFeedReadStatus';
import {
  ICommunicationLimitsPayload,
  IInboxQuery,
  IInboxReadStatusCommand,
} from 'app/models/Notification/Notification';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import baseAxiosInstance from 'utils/BaseApi';

export class NotificationService {
  private notificationBase: string;

  private cancellationToken = axios.CancelToken.source();

  private static currentInstance: NotificationService;

  config?: AxiosRequestConfig = {};

  private constructor() {
    this.notificationBase = globalThis?.app?.env?.REACT_APP_NOTIFICATION_SERVICE_BASE_URL;
  }

  static createInstance(): NotificationService {
    const activeInstance = new NotificationService();
    // activeInstance.cancellationToken = axios.CancelToken.source();
    // if (activeInstance.config) activeInstance.config.cancelToken = activeInstance.cancellationToken.token;
    return activeInstance;
  }

  static getInstance(): NotificationService {
    if (!this.currentInstance) this.currentInstance = this.createInstance();
    return this.currentInstance;
  }

  static cancelActiveRequests() {
    this.currentInstance.cancellationToken.cancel('RequestCancellation');
    // this.currentInstance = null;
  }

  cancelRequests() {
    this.cancellationToken.cancel('RequestCancellation');
    return NotificationService.getInstance();
  }

  cancelAndRevokeInstance() {
    this.cancellationToken.cancel('RequestCancellation');
    this.cancellationToken = axios.CancelToken.source();
    if (this.config) this.config.cancelToken = this.cancellationToken.token;
  }

  getCommunicationLimitsData = (communicationLimitsPayload: ICommunicationLimitsPayload) =>
    baseAxiosInstance
      .post(`${this.notificationBase}/Suppressions/suppressions-query`, communicationLimitsPayload)
      .catch((error: AxiosError) => error.response);

  getUnreadCount = (phoneNumbers: string[]) =>
    baseAxiosInstance
      .post(`${this.notificationBase}/Inbox/unread-count-query`, { phoneNumbers: phoneNumbers })
      .catch((error: AxiosError) => error.response);

  getInboxMessages = (inboxQuery: IInboxQuery) =>
    baseAxiosInstance.post(`${this.notificationBase}/inbox`, inboxQuery).catch((error: AxiosError) => error.response);

  GetActivityFeedUnreadCounts = (data: IGetActivityFeedQuery) =>
    baseAxiosInstance
      .post(`${this.notificationBase}/alert/unread-counts-query`, data)
      .catch((error: AxiosError) => error.response);

  GetActivityFeed = (data: IGetActivityFeedQuery) =>
    baseAxiosInstance
      .post(`${this.notificationBase}/alert/activity-feed-query`, data)
      .catch((error: AxiosError) => error.response);

  UpdateNotificationReadStatus = (data: IUpdateActivityFeedReadStatusCommand) =>
    baseAxiosInstance
      .post(`${this.notificationBase}/Alert/read-status-update`, data)
      .catch((error: AxiosError) => error.response);

  getCandidateConsents = (candidateIds: Array<string>) =>
    baseAxiosInstance
      .post(`${this.notificationBase}/Consents/onetrust-query`, { contactIds: candidateIds })
      .catch((error: AxiosError) => error.response);

  updateMessageReadStatus = (data: IInboxReadStatusCommand) =>
    baseAxiosInstance
      .post(`${this.notificationBase}/logs/readstatus`, data)
      .catch((error: AxiosError) => error.response);

  getFacilityLogs = (data: IFacilityLogs) =>
    baseAxiosInstance
      .post(`${this.notificationBase}/logs/facility`, data)
      .catch((error: AxiosError) => error.response);
}
