import { createAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Education,
  Employment,
  Experience,
  ExperienceEditType,
  ICandidateSaveStatus,
  ICandidateDetails,
  IEditExperience,
  IEmrList,
  IHearsayCertification,
  IHearsayCertificationRequest,
  IPersonalInformation,
  IPostEmrRequest,
  IReferenceData,
  IUserCommunicationLog,
  License,
  Placement,
  PlacementInfo,
  SaveStatus,
  ISupervisorData,
  IPostSupervisorData,
  IHearsayLicense,
  IDeleteHearsayLicense,
  IAuditPostData,
  IAuditItems,
  ISupervisorSkillsets,
  IDeleteHearsayCertification,
  IDeleteSupervisorSkillsets,
  ICandidatePreferences,
  ITaxLetterInfo,
  IEditAuditData,
  IDeleteTravelerPayroll,
  IDeleteTravelerTaxInfo,
  ITaxData,
  IPostTaxLetterInfo,
  ISkillsetTreeDiscipline,
  IDeleteSkillSet,
  IUpdateSkillsetTreeData,
  IDeleteAudit,
  ISkillsetTree,
} from 'app/models/Candidate/CandidateProfile';
import { Credentials, IAttachments } from 'app/models/Credentialing/Credentials';
import { GetUserLogs, getKpis, GetUserLogsSlim } from 'app/services/NotificationServices/NotificationService';
import { CommunicationLogPayload } from 'app/models/Candidate/CandidateProfile';
import { getCredentialLookups, getRequirementTypes } from 'app/services/CredentialingServices/CredentialingServices';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { IPlacementChangeHistory } from 'app/models/Placement/PlacementChangeHistory';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { ISaveSellingNotesTemplate } from 'app/models/Candidate/SellingNotes';
import { ITypeAheadOption } from '../CandidateTabPanel/PreferencesTab/CustomComponents/ControlledTypeAhead';
import {
  ISkillCheckListItem,
  ISkillChecklistPassport,
} from 'app/components/Credentialing/CredentialingAttachmentsViewer/AttachmentsViewer/ViewSkillCheckList/Config/model';
import { ICandidateTag, IPostTags, IReferenceRequest } from 'app/models/Candidate/Tags';
import { IPostRequestTimeOff } from '../CandidateTabPanel/ProfileTab/Cards/RequestTimeOff/IRequest';
import { ICandidateAddressDetails } from '../CandidateHeader/CandidateHeader';

export const initialChangeHistory = {
  rows: undefined,
  loading: false,
};

export const credentialsInitialState = {
  candidateId: 0,
  licenses: [],
  clinicalQualifications: [],
  certifications: [],
  educationOnlineForms: [],
  humanResourcesCredentials: [],
  employeeHealth: [],
  miscellaneous: [],
  reloadData: false,
  isPreview: false,
  attachments: undefined,
  credentialingLookups: [],
  requirementTypes: [],
  contactDrawerOpened: false,
};
export const candidateWorkExperienceInitialState = {
  totalYearsOfExp: 0,
  totalMonthsOfExp: 0,
  skillsets: [],
};

export const IUserCommunicationLogInitialState = {
  pageSize: 1,
  pageNumber: 1,
  totalRecords: 0,
  results: [],
  nextPageExists: false,
};

const initialSaveStatus: ICandidateSaveStatus = {
  dobSaveStatus: SaveStatus.Undefined,
  ssnSaveStatus: SaveStatus.Undefined,
};

export const initialState: ICandidateDetails = {
  travelerId: 0,
  candidateGuid: '',
  brandId: 0,
  brand: '',
  name: {
    prefix: '',
    first: '',
    middle: null,
    last: '',
  },
  goesBy: '',
  adpId: 0,
  nuid: '',
  other: '',
  primaryPhoneNumber: null,
  mobilePhoneNumber: null,
  primaryEmail: '',
  secondaryEmail: '',
  verifiedSkills: [],
  unVerifiedSkills: [],
  groupedSkillsSets: [],
  address: {
    address1: '',
    city: '',
    stateProvinceId: '',
    state: '',
    country: '',
    zip: '',
  },
  phoneNumbers: [],
  candidateStatusId: 0,
  candidateStatus: '',
  audits: [],
  placementStatus: '',
  recruiter: {
    recruiterId: 0,
    first: '',
    last: '',
    email: '',
  },
  availableOn: '',
  lastContactedOn: '',
  gender: '',
  dateOfBirth: '',
  socialSecurityNumber: '',
  candidateSellingNotes: '',
  candidateSummary: '',
  chatHistory: IUserCommunicationLogInitialState,
  licenses: [],
  experience: {
    totalYearsOfExperience: 0,
    totalMonthsOfExperience: 0,
    totalApprovedReferenceCount: 0,
    education: [],
    employment: [],
    skillsets: [],
    maxEmploymentGap: 0,
    candidateWorkExperience: candidateWorkExperienceInitialState,
  },
  supervisor: {
    interested: false,
    availabilityDate: '',
    maxNumber: 0,
    available: 0,
    skillsets: [],
    caseLoad: 0,
  },

  investigationSuspension: false,
  investigationSuspensionExplanation: undefined,
  rightToWorkVerification: false,
  visaExplaination: undefined,
  emergencyContact: {
    name: '',
    phone: '',
    relationship: '',
    extensionNotes: '',
    countryDialingCode: '',
  },
  placement: {
    placementDays: 0,
    weeksBooked: 0,
    fileSent: 0,
    placementInfo: [],
  },
  credentials: credentialsInitialState,
  communicationLog: IUserCommunicationLogInitialState,
  gridLogsData: IUserCommunicationLogInitialState,
  channels: [],
  newAttachments: [],
  existingAttachments: [],
  activePlacements: undefined,
  hasActivePlacements:false,
  loading: false,
  disableLoadMore: false,
  scrolled: false,
  isGridLoading: false,
  isEducationActive: false,
  kpis: {},
  shiftsRequested: [],
  timeOffRequests: [],
  contactDrawerOpened: false,
  primaryEmailLastUpdated: '',
  secondaryEmailLastUpdated: '',
  primaryPhoneNumberLastUpdated: '',
  mobilePhoneNumberLastUpdated: '',
  callBackDate: '',
  covidVaccinationstatus: '',
  inactivityReasonId: 0,
  inactivityReason: '',
  skillSetTree: [],
  addSkillSetTree: [],
  skillsChecklist: undefined,
  masterCopyDropDowns: {
    states: [],
    countries: [],
    degrees: [],
    graduationMonth: [],
    graduationYear: [],
    majors: [],
    disciplines: [],
    specialties: [],
    brands: [],
    discSpecMapping: [],
  },
  disciplineData: [],
  specialtyData: [],
  isAddLicense: false,
  addUnverifiedCredType: '',
  auditData: null,
  referenceData: null,
  changeHistory: initialChangeHistory,
  references: null,
  interviewReasonList: [],
  emrList: [],
  equipmentsProcedure: [],
  bestTimeToContactId: undefined,
  preferredTimeToReach: [],
  sellingNotesItems: [],
  raceEthnicity: {
    ID: '',
    Description: '',
  },
  raceEthnicityOptions: [],
  httpStatus: {
    statusCode: undefined,
    pageName: undefined,
  },
  genderSelection: {
    ID: '',
    Description: '',
  },
  genderOptions: [],
  viewSkillsChecklistPassport: undefined,
  tagsList: undefined,
  candidateSaveStatus: initialSaveStatus,
  ssnTimestamp: '',
  candidateSSN: undefined,
  candidateDateOfBirth: undefined,
  candidateNUID: undefined,
  placementSkillsetExperience: undefined,
  requestReferenceSaveStatus: undefined,
  requestTimeOff: [],
  passportSkillChecklist: undefined,
  referenceGrid: { isLoading: false, data: [] },
  openSkillChecklistDocument: false,
  contactTabSaveStatus: {
    primaryPhoneNumberSaveResult: '',
    secondaryPhoneNumberSaveResult: '',
    primaryEmailSaveResult: '',
    secondaryEmailSaveResult: '',
    primaryPhoneExtNotesSaveResult: '',
    secondaryPhoneExtNotesSaveResult: '',
  },
  editExperience: {
    isModalOpen: false,
    type: ExperienceEditType.None,
    workExperience: undefined,
    timeOff: undefined,
    rowData: undefined,
  },
  hearsayCertifications: [],
  isFilterApplied: false,
  channelValues: [],
  taxData: [],
  taxLetterInfo: [],
  preferences: null,
  editAuditData: {
    editAuditDataDetails: undefined,
    rowData: undefined,
  },
};
export interface IAvailableChannelFiltersPayload {
  contactId: string;
  brandId?: string;
}

export const loadUserLogs = createAsyncThunk(
  'candidateDetails/logs',
  async ({ payload, existingLogs }: { payload: CommunicationLogPayload; existingLogs?: any }, { dispatch }) => {
    try {
      dispatch(candidateDetailActions.setDisableMore(true));
      if (existingLogs === undefined) {
        dispatch(candidateDetailActions.setLoading(true));
      }
      const response = await GetUserLogs(payload).catch(err => {
        dispatch(
          globalActions.setSnackBar({
            message: `Failed to fetch user logs`,
            severity: 'error',
          }),
        );
        dispatch(candidateDetailActions.setLoading(false));
      });
      if (existingLogs !== undefined) {
        response.results = [...response?.results, ...existingLogs];
      }
      dispatch(candidateDetailActions.updateCommunicationLogs(response));
      dispatch(candidateDetailActions.setLoading(false));
      dispatch(candidateDetailActions.setDisableMore(false));
      return true;
    } catch (error) {
      dispatch(candidateDetailActions.setLoading(false));
      dispatch(candidateDetailActions.setDisableMore(false));
      trackException({
        exception: error,
        properties: {
          name: ExceptionType.APIRequestError,
          functionName: 'loadUserLogs',
          area: 'src/app/components/Candidate/CandidateProfile/Profile/CandidateDetails.redux.ts',
        },
      });
    }
  },
);

export const loadKpis = createAsyncThunk('candidateDetails/kpis', async (payload: any, { dispatch }) => {
  try {
    dispatch(candidateDetailActions.setLoading(true));
    const response = await getKpis(payload);
    dispatch(candidateDetailActions.setKpis(response));
    dispatch(candidateDetailActions.setLoading(false));
  } catch (error) {
    dispatch(candidateDetailActions.setLoading(false));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'loadKpis',
        area: 'src/app/components/Candidate/CandidateProfile/Profile/CandidateDetails.redux.ts',
      },
    });
  }
});

export const loadGridUserLogs = createAsyncThunk('candidateDetails/logsGrid', async (payload: any, { dispatch }) => {
  if (!payload.channel || !payload.channel.length) {
    payload.channel = ['Email', 'Conversation', 'Voice'];
  }
  try {
    dispatch(candidateDetailActions.setGridLoading(true));
    const response = await GetUserLogs(payload);
    dispatch(candidateDetailActions.updateGridLogsData(response));
    dispatch(candidateDetailActions.setGridLoading(false));
    return response;
  } catch (error) {
    dispatch(candidateDetailActions.setLoading(false));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'loadGridUserLogs',
        area: 'src/app/components/Candidate/CandidateProfile/Profile/CandidateDetails.redux.ts',
      },
    });
  }
});

export const loadChatHistory = createAsyncThunk('candidateDetails/chatHistory', async (payload: any, { dispatch }) => {
  try {
    const response = await GetUserLogsSlim(payload);
    dispatch(candidateDetailActions.setChatHistory(response));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'loadChatHistory',
        area: 'src/app/components/Candidate/CandidateProfile/Profile/CandidateDetails.redux.ts',
      },
    });
  }
});

export const loadCredentialingLookups = createAsyncThunk(
  'credentialing/credentialingLookups',
  async (payload: any, { dispatch }) => {
    try {
      const response = await getCredentialLookups('all');
      dispatch(candidateDetailActions.setCredentialingLookups(response));
    } catch (error) {
      trackException({
        exception: error,
        properties: {
          name: ExceptionType.APIRequestError,
          functionName: 'loadCredentialingLookups',
          area: 'src/app/components/Candidate/CandidateProfile/Profile/CandidateDetails.redux.ts',
        },
      });
    }
  },
);

export const loadRequirementTypes = createAsyncThunk(
  'credentialing/requirementtypes',
  async (payload: any, { dispatch }) => {
    try {
      const response = await getRequirementTypes();
      dispatch(candidateDetailActions.setRequirementTypes(response));
    } catch (error) {
      trackException({
        exception: error,
        properties: {
          name: ExceptionType.APIRequestError,
          functionName: 'loadRequirementTypes',
          area: 'src/app/components/Candidate/CandidateProfile/Profile/CandidateDetails.redux.ts',
        },
      });
    }
  },
);

const getChangeHistory = createAction<{
  candidateId: number;
  candidateGuid: string;
}>('GET_CANDIDATE_CHANGE_HISTORY');

const getEMRData = createAction<{ candidateId: number; brandId: number }>('GET_EMR_DATA');
const postEMRData = createAction<IPostEmrRequest>('POST_EMR_DATA');
const getPreferredTimeToReach = createAction('PREFERRED_TIME_TO_REACH');
const postSellingNotesTemplate = createAction<ISaveSellingNotesTemplate>('SELLING_NOTES_TEMPLATE');
const getMajors = createAction('MAJORS');
const getDegree = createAction('DEGREE');
const getDiscipline = createAction('DISCIPLINE');
const getSpecialty = createAction('SPECIALTY');
const getTravelCompany = createAction('TRAVEL_COMPANY');
const getDipsSpecialtyMap = createAction('DIPS_SPECIALTY_MAP');
const postRaceEthnicity = createAction<ITypeAheadOption>('RACE_ETHNICITY');
const getRaceEthnicity = createAction('GET_RACE_ETHNICITY');
const postGender = createAction<ITypeAheadOption>('GENDER');
const getGenderOptions = createAction('GET_GENDER_OPTIONS');
const postCandidateReference = createAction<{ requestBody: IReferenceData }>('POST_CANDIDATE_REFERENCE');
const postCandidatePersonalInfo = createAction<{ requestBody: IPersonalInformation; formData: any; onClose: any }>(
  'POST_CANDIDATE_PERSONAL_INFO',
);
const deleteEducationRecord = createAction<{ requestBody: any }>('DELETE_EDUCATION_RECORD');
const getTagsList = createAction<{ travelerId?: any; brandId?: any }>('GET_TAGS');
const postTags = createAction<IPostTags>('POST_TAGS');
const getPlacementSkillsetExperience = createAction<{
  placementId: number;
  placementOrderType: string;
}>('GET_PLACEMENT_SKILLSET_EXPERIENCE');
const postReferenceRequest = createAction<IReferenceRequest>('POST_REFERENCE_REQUEST');
const requestTimeOffData = createAction('REQUEST_TIME_OFF');
const getSupervisorData = createAction('GET_SUPERVISOR_DATA');
const getTaxData = createAction<{ travelerId?: string }>('GET_TAX_DATA');
const getPermanentTaxLetterData = createAction<{ travelerId?: string }>('GET_PERMANENT_TAX_LETTER_DATA');
const postSupervisorData = createAction<{ reqBody: IPostSupervisorData; skillsets: ISupervisorSkillsets[] }>(
  'POST_SUPERVISOR_DATA',
);
const postRequestTimeOff = createAction<IPostRequestTimeOff>('POST_REQUEST_TIME_OFF');
const deleteWorkExperienceRecord = createAction<{
  workHistoryRecordId: number;
  workExperienceList: any;
  requestBody: any;
}>('DELETE_WORK_EXPERIENCE_RECORD');
const getAllSkillChecklists = createAction('GET_ALL_SKILL_CHECKLISTS');

const getRequestReferences = createAction('GET_REQUEST_REFERENCES');
const initiateReferenceGridData = createAction<any>('GET_REFERENCE_GRID');
const postUnverifiedHearsayCertification = createAction<IHearsayCertificationRequest>(
  'POST_UNVERIFIED_HEARSAY_CERTIFICATION',
);
const postUnverifiedHearsayLicense = createAction<IHearsayLicense>('POST_UNVERIFIED_HEARSAY_LICENSE');
const deleteUnverifiedHearsayLicense = createAction<IDeleteHearsayLicense>('DELETE_UNVERIFIED_HEARSAY_LICENSE');

const deleteUnverifiedHearsayCertification = createAction<IDeleteHearsayCertification>(
  'DELETE_UNVERIFIED_HEARSAY_CERTIFICATION',
);
const getAvailableChannelFilters = createAction<IAvailableChannelFiltersPayload>('GET_AVAILABLE_CHANNEL_FILTERS');
const postCandidateAudit = createAction<{ reqBody: IAuditPostData; auditDetails: IAuditItems }>('POST_CANDIDATE_AUDIT');
const updateCandidateAddress = createAction<{ requestBody: ICandidateAddressDetails }>('UPDATE_CANDIDATE_ADDRESS');
const deleteSupervisorSkillsets = createAction<IDeleteSupervisorSkillsets>('DELETE_SUPERVISOR_SKILLSETS');
const getCandidatePreferenceData = createAction<{ travelerId?: string }>('GET_CANDIDATE_PREFERENCES');
const deleteTravelerPayroll = createAction<IDeleteTravelerPayroll>('DELETE_TRAVElER_PAYROLL');
const deleteTravelerTaxData = createAction<IDeleteTravelerTaxInfo>('DELETE_TAX_INFO');
const deleteCandidateAudit = createAction<IDeleteAudit>('DELETE_CANDIDATE_AUDIT');
const updateTaxData = createAction<ITaxData>('UPDATE_TAX_DATA');
const postPermanentTaxData = createAction<IPostTaxLetterInfo>('POST_PERMANENT_TAX_DATA');
const updatePreferencesData = createAction<ICandidatePreferences>('UPDATE_PREFERENCE_DATA');
const deleteCandidateSkillset = createAction<IDeleteSkillSet>('DELETE_CANDIDATE_SKILLSET');
const updateCandidateSkillsetTree = createAction<{ data: IUpdateSkillsetTreeData; customSaveMessage?: string }>(
  'UPDATE_CANDIDATE_SKILLSET_TREE',
);
const CandidateDetailSlice = createSlice({
  name: 'candidateDetails',
  initialState: initialState,
  reducers: {
    setCandidateDetails(state, action: PayloadAction<ICandidateDetails>) {
      return {
        ...state,
        ...action.payload,
      };
    },
    updateCommunicationLogs(state, action: PayloadAction<IUserCommunicationLog | any>) {
      state.communicationLog = action.payload;
    },
    updateLastContactedOn(state, action: PayloadAction<string>) {
      state.lastContactedOn = action.payload;
    },
    updateGridLogsData(state, action: PayloadAction<any>) {
      state.gridLogsData = action.payload;
    },
    setLicenses(state, action: PayloadAction<License[]>) {
      state.licenses = action.payload;
    },
    setChannels(state, action: PayloadAction<string[]>) {
      state.channels = action.payload;
    },
    setExperience(state, action: PayloadAction<Experience>) {
      state.experience = action.payload;
    },
    setPlacement(state, action: PayloadAction<Placement>) {
      state.placement = action.payload;
    },
    setActivePlacements(state, action: PayloadAction<PlacementInfo[] | null>) {
      state.activePlacements = action.payload;
    },
    setActivePlacementForCandidate(state, action: PayloadAction<boolean>) {
      state.hasActivePlacements = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setGridLoading(state, action: PayloadAction<boolean>) {
      state.isGridLoading = action.payload;
    },
    setFilterApplied(state, action: PayloadAction<boolean>) {
      state.isFilterApplied = action.payload;
    },
    setCredentials(state, action: PayloadAction<Credentials>) {
      return {
        ...state,
        credentials: {
          ...action.payload,
          reloadData: !state?.credentials?.reloadData,
        },
      };
    },
    setEducation(state, action: PayloadAction<Education[]>) {
      state.experience.education = action.payload;
    },
    setEmployment(state, action: PayloadAction<Employment[]>) {
      state.experience.employment = action.payload;
    },
    setSupervisor(state, action: PayloadAction<ISupervisorData>) {
      state.supervisor = action.payload;
    },
    setTaxLetterInfo(state, action: PayloadAction<any>) {
      state.taxLetterInfo = action.payload;
    },
    setReferenceGrid(state, action: PayloadAction<any>) {
      state.referenceGrid.data = action.payload;
      state.referenceGrid.isLoading = false;
    },
    setReferenceGridLoadingStatus(state, action) {
      state.referenceGrid = { ...state.referenceGrid, isLoading: action.payload };
    },
    setAttachments(state, action: PayloadAction<IAttachments[]>) {
      state.credentials.attachments = action.payload;
    },
    setNewAttachments(state, action: PayloadAction<any[]>) {
      state.newAttachments = action.payload;
    },
    appendNewAttachment(state, action: PayloadAction<any>) {
      state.newAttachments = [...(state.newAttachments || []), action.payload];
    },
    setExistingAttachments(state, action: PayloadAction<any[]>) {
      state.existingAttachments = action.payload;
    },
    setSelPreviewAttachments(state, action: PayloadAction<any[]>) {
      state.selPreviewAttachments = action.payload;
    },
    setHistoryAttachments(state, action: PayloadAction<any[]>) {
      state.historyAttachments = action.payload;
    },
    setReLoading(state, action: PayloadAction<boolean>) {
      state.reLoading = action.payload;
    },
    setKpis(state, action: PayloadAction<any>) {
      state.kpis = action.payload;
    },
    setChatHistory(state, action: PayloadAction<any>) {
      state.chatHistory = action.payload;
    },
    setCredentialingLookups(state, action: PayloadAction<any>) {
      state.credentials.credentialingLookups = action.payload;
    },
    setRequirementTypes(state, action: PayloadAction<any>) {
      state.credentials.requirementTypes = action.payload;
    },
    setCandidatePreferences(
      state,
      action: PayloadAction<{ timeOffRequests: { startDate: string; endDate: string }[] }>,
    ) {
      state.timeOffRequests = action.payload.timeOffRequests;
    },
    setAvailableChannel(state, action: PayloadAction<any>) {
      state.channels = action.payload;
    },
    setAddressData(state, action: PayloadAction<any>) {
      state.addressData = action.payload;
      state.originalAddressData = action.payload;
    },
    setSkillSetTree(state, action: PayloadAction<ISkillsetTree>) {
      state.skillSetTree = action.payload;
    },
    setAddSkillSetTree(state, action: PayloadAction<ISkillsetTree>) {
      state.addSkillSetTree = action.payload;
    },
    setIsPreview(state, action: PayloadAction<boolean>) {
      state.isPreview = action.payload;
    },
    setSkillsChecklist(state, action: PayloadAction<any>) {
      state.skillsChecklist = action.payload;
    },
    setEducationActive(state, action: PayloadAction<boolean>) {
      state.isEducationActive = action.payload;
    },
    setMasterCopyDropDowns(state, action: PayloadAction<any>) {
      state.masterCopyDropDowns = action.payload;
    },
    setDisciplineData(state, action: PayloadAction<any>) {
      state.disciplineData = action.payload;
    },
    setSpecialtyData(state, action: PayloadAction<any>) {
      state.specialtyData = action.payload;
    },
    setIsAddLicense(state, action: PayloadAction<any>) {
      state.isAddLicense = action.payload.isAddLicense;
      state.addUnverifiedCredType = action.payload.type;
    },
    setAuditData(state, action: PayloadAction<any>) {
      state.auditData = action.payload;
    },
    setReferenceData(state, action: PayloadAction<any>) {
      state.referenceData = action.payload;
    },
    setChangeHistoryLoading(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        changeHistory: {
          ...state.changeHistory,
          loading: action.payload,
        },
      };
    },
    setChangeHistory(state, action: PayloadAction<IPlacementChangeHistory[]>) {
      return {
        ...state,
        changeHistory: {
          ...state.changeHistory,
          rows: action.payload,
        },
      };
    },
    setReferences(state, action: PayloadAction<any>) {
      state.references = action.payload;
    },
    setInterviewReasonList(state, action: PayloadAction<any>) {
      state.interviewReasonList = action.payload;
    },
    setEMRList(state, action: PayloadAction<IEmrList[]>) {
      state.emrList = action.payload;
    },
    savedEquipmentsProcedure(state, action: PayloadAction<IEmrList[]>) {
      state.equipmentsProcedure = action.payload;
    },
    setPreferredTimeToReach(state, action: PayloadAction<[]>) {
      state.preferredTimeToReach = action.payload;
    },
    setSellingNotesItems(state, action: PayloadAction<any>) {
      state.sellingNotesItems = action.payload;
    },
    setRaceEthnicity(
      state,
      action: PayloadAction<{
        ID: string;
        Description: string;
      }>,
    ) {
      state.raceEthnicity = action.payload;
    },
    setRaceEthnicityOptions(state, action: PayloadAction<ITypeAheadOption[]>) {
      state.raceEthnicityOptions = action.payload;
    },
    setGender(
      state,
      action: PayloadAction<{
        ID: string;
        Description: string;
      }>,
    ) {
      state.genderSelection = action.payload;
    },
    setGenderOptions(state, action: PayloadAction<ITypeAheadOption[]>) {
      state.genderOptions = action.payload;
    },
    setHttpStatus(
      state,
      action: PayloadAction<{
        statusCode: number | undefined;
        pageName: string | undefined;
      }>,
    ) {
      state.httpStatus = action.payload;
    },
    getInterviews(state, action) {},
    setInterviews(state, action) {
      state.interviews = action.payload;
    },
    updateEmergencyContactCountryCode(state, action: PayloadAction<{ countryCode: string; phone: string }>) {
      state.emergencyContact.countryDialingCode = action.payload.countryCode;
      state.emergencyContact.phone = action.payload.phone;
    },
    setEmergencyContact(state, action) {
      state.emergencyContact = action.payload;
    },
    setCandidateSkillChecklistFromPassport(state, action: PayloadAction<ISkillCheckListItem>) {
      state.viewSkillsChecklistPassport = action.payload;
    },
    setTagsList(state, action: PayloadAction<ICandidateTag[]>) {
      state.tagsList = action.payload;
    },
    updateCandidateSSN(state, action) {},
    setCandidateSSN(state, action) {
      state.candidateSSN = action.payload;
    },
    updateCandidateDateOfBirth(state, action) {},
    setCandidateDateOfBith(state, action) {
      state.candidateDateOfBirth = action.payload;
    },
    setCandidateNUID(state, action) {
      state.candidateNUID = action.payload;
    },
    setCandidateSaveStatus(state, action: PayloadAction<ICandidateSaveStatus>) {
      state.candidateSaveStatus = action.payload;
    },
    setPlacementSkillsetExperience(state, action: PayloadAction<any>) {
      state.placementSkillsetExperience = action.payload;
    },
    setRequestReferenceSaveStatus(state, action: PayloadAction<any>) {
      state.requestReferenceSaveStatus = action.payload;
    },
    setTimeOffRequest(state, action: PayloadAction<{ id: number; key: string }[]>) {
      state.requestTimeOff = action.payload;
    },
    updateWorkExperienceGrid(state, action) {},
    setAllSkillChecklistFromPassport(state, action: PayloadAction<ISkillChecklistPassport>) {
      state.passportSkillChecklist = action.payload;
    },
    openSkillChecklistDoc(state, action: PayloadAction<boolean>) {
      state.openSkillChecklistDocument = action.payload;
    },
    setContactTabSaveStatus(state, action: PayloadAction<any>) {
      state.contactTabSaveStatus = { ...state.contactTabSaveStatus, ...action.payload };
    },
    setDefaultChannelValues(state, action: PayloadAction<any[]>) {
      state.defaultChannels = action.payload || [];
    },
    setChannelValues(state, action: PayloadAction<any[]>) {
      state.channelValues = action.payload || [];
    },
    setEditExperience(state, action: PayloadAction<IEditExperience>) {
      state.editExperience = action.payload;
    },
    setHearsayCertification(state, action: PayloadAction<IHearsayCertification[]>) {
      state.hearsayCertifications = action.payload;
    },
    setTaxData(state, action: PayloadAction<any>) {
      state.taxData = action.payload;
    },
    setDisableMore(state, action: PayloadAction<boolean>) {
      state.disableLoadMore = action.payload;
    },
    setScrolled(state, action: PayloadAction<boolean>) {
      state.scrolled = action.payload;
    },
    setPreferences(state, action: PayloadAction<ICandidatePreferences>) {
      state.preferences = action.payload;
    },
    setEditAuditData(state, action: PayloadAction<IEditAuditData>) {
      state.editAuditData = action.payload;
    },
    updateTaxData(state, action: PayloadAction<ITaxData>) {},
  },
});

const { actions } = CandidateDetailSlice;
export const { reducer: candidateDetailReducer, name: candidateDetailSliceKey } = CandidateDetailSlice;

export const candidateDetailActions = {
  ...actions,
  getChangeHistory,
  getEMRData,
  postEMRData,
  getPreferredTimeToReach,
  postSellingNotesTemplate,
  getMajors,
  getDegree,
  getDiscipline,
  getSpecialty,
  getTravelCompany,
  getDipsSpecialtyMap,
  postRaceEthnicity,
  getRaceEthnicity,
  postGender,
  getGenderOptions,
  postCandidateReference,
  postCandidatePersonalInfo,
  deleteEducationRecord,
  getTagsList,
  postTags,
  getPlacementSkillsetExperience,
  postReferenceRequest,
  requestTimeOffData,
  postRequestTimeOff,
  postSupervisorData,
  postUnverifiedHearsayCertification,
  postUnverifiedHearsayLicense,
  deleteWorkExperienceRecord,
  getAllSkillChecklists,
  getRequestReferences,
  initiateReferenceGridData,
  getAvailableChannelFilters,
  getSupervisorData,
  deleteUnverifiedHearsayLicense,
  deleteUnverifiedHearsayCertification,
  postCandidateAudit,
  getTaxData,
  updateCandidateAddress,
  deleteSupervisorSkillsets,
  getPermanentTaxLetterData,
  getCandidatePreferenceData,
  deleteTravelerPayroll,
  deleteTravelerTaxData,
  updateTaxData,
  postPermanentTaxData,
  updatePreferencesData,
  deleteCandidateSkillset,
  updateCandidateSkillsetTree,
  deleteCandidateAudit,
};
