import React from 'react';
import {
  Avatar,
  Tooltip,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  ListItemSecondaryAction,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import MailBoxIcon from 'app/assets/images/MailBox.svg';
import MessageIcon from 'app/assets/images/Message.svg';
import PhoneIcon from 'app/assets/images/Phone.svg';
import EmailDisabled from 'app/assets/images/CandidateHeader/email-Disabled.svg';
import PhoneDisabled from 'app/assets/images/CandidateHeader/PhoneDisabled.svg';
import MessageDisabled from 'app/assets/images/CandidateHeader/Text-Disabled.svg';
import {
  useEnableEmailCommunication,
  useEnableSmsCommunication,
  useEnableVoiceCommunication,
} from 'oidc/CommunicationEnabler';
import { colors } from 'styles/styleVariables';
import { missingField } from '@AMIEWEB/Notification/MultiChannel/Sms/CredentialingAnalyst/PlacementDisplay';
import {
  ChannelType,
  FormName,
  IEmailData,
  IVoiceData,
  SendType,
  UseSubType,
  UseType,
} from '@AMIEWEB/Notification/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { IEmployee, IToken } from 'app/models/Notification/Notification';
import { Concatenate } from 'utils/string/string';
import { selectUser } from 'oidc/user.selectors';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { EmailDefaultValues } from 'app/models/Email/IEmail';
import _ from 'lodash';
import { useHeaderOptions } from '../Buttons/UseHeaderOptions';
import { searchNotificationData } from '@AMIEWEB/GlobalSearch/helper';
import { getDefaultSender } from '@AMIEWEB/Candidate/CandidateProfile/CandidateHeader/NotificationHelper';

const useStyles = makeStyles<{ normalOrder: boolean; showCheckbox: boolean }>()(
  (theme, { normalOrder, showCheckbox }) => ({
    listItemText: {
      display: 'flex',
      flexDirection: normalOrder ? 'column' : 'column-reverse',
    },
    secondaryText: {
      // Specified as per container width
      maxWidth: 280,
    },
    secondaryTextWithAvatar: {
      // Specified as per container width
      maxWidth: showCheckbox ? 190 : 250,
    },
    title: {
      color: colors.darkText,
      fontSize: 16,
      fontWeight: 500,
    },
    designation: {
      color: '#707070',
      fontSize: 12,
    },
    description: {
      color: colors.darkText,
      fontSize: 12,
    },
    tooltip: {
      display: 'grid',
    },
    tooltipArrow: {
      color: '#fff',
      '&::before': {
        border: '1px solid #555555',
      },
    },
    tooltipBackground: {
      backgroundColor: '#fff',
      color: '#555555',
      border: '1px solid #555555',
      fontSize: '12px',
    },
    userAvatar: {
      width: '44px',
      height: '44px',
      borderRadius: '80px',
      opacity: 1,
      color: 'white',
      backgroundColor: '#555555',
    },
    userAvatarDissable: {
      width: '44px',
      height: '44px',
      borderRadius: '80px',
      opacity: 1,
      color: 'white',
      backgroundColor: '#CCCCCC',
    },
    actionContainer: {
      right: 0,
    },
  }),
);

export const ContactItem = props => {
  const {
    avatarText,
    title,
    designation,
    description,
    hideMailboxIcon = false,
    hidePhoneIcon = false,
    hideMessageIcon = false,
    hideAvatar = false,
    disableMailIcon = false,
    disablePhoneIcon = false,
    disableMessageIcon = false,
    disableAvatar = false,
    enableCommunication = false,
    placementClientContact = false,
    placementId,
    showCheckbox = false,
    checkbox,
    normalOrder = false,
    facilityId,
    unitId,
  } = props;
  const { classes } = useStyles({ normalOrder, showCheckbox });
  const { userInfo } = useSelector(selectUser);
  const notificationData = useSelector(notificationSelection);
  const dispatch = useDispatch();
  const { getEmailSubject } = useHeaderOptions();

  const enableEmailCommunication = useEnableEmailCommunication();
  const enableSmsCommunication = useEnableSmsCommunication();
  const enableVoiceCommunication = useEnableVoiceCommunication();

  const handleCommunication = (props: { channelType: ChannelType; contactData: any }) => {
    const notificationUser = searchNotificationData({ userInfo });
    const { contactData } = props;
    const associatedRecords = placementClientContact
      ? [
          {
            name: 'placementId',
            value: placementId?.toString(),
          },
          {
            name: 'clientContactId',
            value: contactData?.id,
          },
          {
            name: 'facilityId',
            value: facilityId?.toString(),
          },
        ]
      : [
          {
            name: 'partnerDesignation',
            value: contactData?.designation,
          },
          {
            name: 'userId',
            value: contactData?.id,
          },
        ];
    const sender: IEmployee = getDefaultSender({ userInfo });
    const tos = {
      contactId: parseInt(contactData?.id),
      designation: contactData?.designation,
      email: contactData?.email,
      phoneNumber: contactData?.phoneNumber?.replace(/^\+\d+\s\(/, '('),
      name:
        contactData?.title && typeof contactData.title === 'string'
          ? contactData.title
          : contactData.title?.props.title,
      clientContactDetails: {
        statusId: contactData?.statusId?.toString(),
        unitIds: [unitId?.toString()],
      },
    };
    if (props.channelType === ChannelType.voice) {
      const VoiceData: IVoiceData = {
        manualLog: false,
        channel: props.channelType,
        associatedRecords: associatedRecords,
        sender: sender,
        sendType: SendType.one_to_one,
        tos: [tos],
        useType: placementClientContact ? UseType.ClientContacts : UseType.Placement,
        userRole: Concatenate(userInfo?.roles || [], ','),
        isInternal: !placementClientContact,
      };

      if (placementClientContact) {
        VoiceData.formName = FormName[FormName.Contact];
        VoiceData.UseSubType = UseSubType.ClientContactGrid;
      }

      if (!notificationData.voice.open && notificationData.voice.minimized) {
        dispatch(
          notificationDataActions.setVoiceInteraction({
            open: true,
            minimized: false,
            showDisposition: true,
          }),
        );
        if (notificationData.voice.data.tos.find(Boolean).contactId !== contactData?.id) {
          dispatch(notificationDataActions.setSnackBarData({ channel: 'voice', manual: false, changeWarning: true }));
        }
      } else {
        dispatch(
          notificationDataActions.setVoiceData({
            data: VoiceData,
            open: true,
            minimized: false,
          }),
        );
      }
    } else {
      const currentUrl = window.location.href?.split('?')[0];
      const body = placementClientContact
        ? `<p><br><br><br>{{signature}}</p>`
        : `<p>&nbsp;</p><p>&nbsp;</p><p>Link To Profile : <a href=${currentUrl}>${currentUrl}</a><br><br>{{signature}}</p>`;
      const substitutions: Array<IToken> = [
        {
          name: 'Signature',
          input: '{{signature}}',
        },
      ];
      let emailData: IEmailData = {
        substitutions: substitutions,
        useType: placementClientContact ? UseType.ClientContacts : UseType.Placement,
        emailType: SendType.one_to_one,
        body: body,
        isInternal: !placementClientContact,
        associatedRecords: associatedRecords,
        subject: placementClientContact ? '' : getEmailSubject(),
        tos: [tos],
        sender: sender,
        userRole: Concatenate(userInfo?.roles || [], ','),
        disableGroupEmailCheckbox: true,
      };
      if (placementClientContact) {
        emailData = {
          ...emailData,
          useSubType: UseSubType.ClientContactGrid,
          sendType: SendType.one_to_one,
        };
      }
      if (!notificationData.email.open && notificationData.email.minimized) {
        dispatch(
          notificationDataActions.setEmailInteraction({
            open: true,
            minimized: false,
          }),
        );
        if (!_.isEqual(notificationData.email.data!.tos, [])) {
          dispatch(notificationDataActions.setSnackBarData({ channel: 'email', manual: false, changeWarning: true }));
        }
      } else {
        dispatch(
          notificationDataActions.setEmailData({
            data: {
              ...EmailDefaultValues(),
              ...notificationUser,
              ...emailData,
            },
            open: true,
            minimized: false,
          }),
        );
      }
    }
  };

  const getDescription = React.useMemo(() => {
    return description ? (
      typeof description === 'string' ? (
        <Typography classes={{ root: classes.description }} data-testid="description">
          {description}
        </Typography>
      ) : (
        description
      )
    ) : undefined;
  }, [description]);

  return (
    <ListItem alignItems="flex-start" disableGutters>
      {showCheckbox && checkbox}
      {!hideAvatar && (
        <ListItemAvatar>
          <Avatar data-avatar className={!disableAvatar ? classes.userAvatar : classes.userAvatarDissable}>
            {avatarText || 'UNK'}
          </Avatar>
        </ListItemAvatar>
      )}
      <ListItemText
        primary={title || missingField}
        classes={{
          root: classes.listItemText,
          primary: classes.title,
          secondary: hideAvatar ? classes.secondaryText : classes.secondaryTextWithAvatar,
        }}
        secondary={
          <React.Fragment>
            <Typography variant="body2" classes={{ root: classes.designation }} data-testid="designation">
              {designation}
            </Typography>
            {getDescription}
          </React.Fragment>
        }
      />
      <ListItemSecondaryAction classes={{ root: classes.actionContainer }}>
        {!hideMailboxIcon &&
          (enableEmailCommunication && !disableMailIcon && !!props?.email && props?.email !== '' ? (
            <Tooltip
              title={
                <span className={classes.tooltip}>
                  <span>{props?.email || ''}</span>
                </span>
              }
              disableHoverListener={!props?.email}
              arrow
              placement="bottom"
              classes={{ tooltip: classes.tooltipBackground, arrow: classes.tooltipArrow }}
            >
              <img
                src={MailBoxIcon}
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  enableCommunication &&
                  handleCommunication({ channelType: ChannelType.email, contactData: { ...props } })
                }
                alt="MailBoxIcon"
              />
            </Tooltip>
          ) : (
            <img src={EmailDisabled} style={{ marginLeft: 6 }} alt="EmailDisabled" />
          ))}
        {(placementClientContact || !hidePhoneIcon) &&
          (enableVoiceCommunication && !disablePhoneIcon && !!props?.phoneNumber && props?.phoneNumber !== '' ? (
            <Tooltip
              title={
                <span className={classes.tooltip}>
                  <span>{props?.phoneNumber || ''}</span>{' '}
                </span>
              }
              disableHoverListener={!props?.phoneNumber}
              arrow
              placement="bottom"
              classes={{ tooltip: classes.tooltipBackground, arrow: classes.tooltipArrow }}
            >
              <img
                src={PhoneIcon}
                style={{ marginLeft: 6, cursor: 'pointer' }}
                onClick={() =>
                  enableCommunication &&
                  handleCommunication({ channelType: ChannelType.voice, contactData: { ...props } })
                }
                alt="PhoneIcon"
              />
            </Tooltip>
          ) : (
            <img src={PhoneDisabled} style={{ marginLeft: 6 }} alt="PhoneDisabled" />
          ))}
        {!hideMessageIcon &&
          (enableSmsCommunication && !disableMessageIcon ? (
            <Tooltip
              title={
                <span className={classes.tooltip}>
                  <span>{props?.message || ''}</span>{' '}
                </span>
              }
              disableHoverListener={!props?.message}
              arrow
              placement="bottom"
              classes={{ tooltip: classes.tooltipBackground, arrow: classes.tooltipArrow }}
            >
              <img src={MessageIcon} style={{ marginLeft: 6 }} alt="MessageIcon" />
            </Tooltip>
          ) : (
            <img src={MessageDisabled} style={{ marginLeft: 6 }} alt="MessageDisabled" />
          ))}
      </ListItemSecondaryAction>
    </ListItem>
  );
};
