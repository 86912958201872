import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IFacilityCommunicationHistory {
  FacilityCommunicationLog: IFacilityCommunicationLog;
  isGridLoading: boolean;
}
export interface IFacilityCommunicationLog {
  totalRecords: number | undefined;
  pageSize: number;
  pageNumber: number;
  results: any[];
  nextPageExists: boolean;
  lastContactedOn?: string | Date;
}

export interface IFacilityLogs {
  facilityId: number;
  pageSize?: number;
  pageNumber?: number;
}
export const IFacilityCommunicationLogInitialState = {
  pageSize: 50,
  pageNumber: 1,
  totalRecords: 0,
  results: [],
  nextPageExists: false,
};

export const initialState: IFacilityCommunicationHistory = {
  FacilityCommunicationLog: IFacilityCommunicationLogInitialState,
  isGridLoading: false,
};
const getFacilityLogs = createAction<IFacilityLogs>('GET_FACILITY_LOGS');

export const FacilityCommunicationHostory = createSlice({
  name: 'facilityCommunicationHostory',
  initialState: initialState,
  reducers: {
    updateFacilityCommunicationLogs(state, action: PayloadAction<IFacilityCommunicationLog | any>) {
      state.FacilityCommunicationLog = action.payload;
    },
    setGridLoading(state, action: PayloadAction<boolean>) {
      state.isGridLoading = action.payload;
    },
  },
});

const { actions } = FacilityCommunicationHostory;

export const { reducer: FacilityCommunicationHostoryReducer, name: FacilityCommunicationHistorySliceKey } =
  FacilityCommunicationHostory;

export const facilityCommunicationHostoryActions = {
  ...actions,
  getFacilityLogs,
};
