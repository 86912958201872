import React from 'react';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { formatPhoneNumber } from 'app/helpers/phoneHelpers';

export const getContactInfo = log => {
  const channel = log?.channel;
  const tos = log?.message?.tos || [];

  switch (channel) {
    case 'Email': {
      return tos
        .map(to => to?.email)
        .filter(Boolean)
        .join(', ');
    }
    case 'Voice': {
      return tos
        .map(to => {
          const phoneNumber = to?.phoneNumber;
          if (phoneNumber) {
            return phoneNumber.length > 10 && phoneNumber.startsWith('71')
              ? formatPhoneNumber(phoneNumber.substring(2))
              : formatPhoneNumber(phoneNumber);
          }
          return null;
        })
        .filter(Boolean)
        .join(', ');
    }
    case 'Conversation': {
      return tos
        .map(to => {
          const phoneNumber = to?.phoneNumber;
          if (phoneNumber) {
            return phoneNumber?.length > 10 && phoneNumber.startsWith('+1')
              ? formatPhoneNumber(phoneNumber.substring(2))
              : formatPhoneNumber(phoneNumber);
          }
          return null;
        })
        .filter(Boolean)
        .join(', ');
    }
    default: {
      return '';
    }
  }
};

export function getConatctNames(log) {
  if (!log || !log?.message || !Array.isArray(log?.message?.tos)) {
    return '';
  }
  const tosNames = log?.message?.tos
    .filter(tosEntry => tosEntry?.name)
    .map(tosEntry => tosEntry?.name)
    .join(', ');

  return tosNames;
}

export const identifyCommunicationIcon = (
  communicationLog: any,
  fontSize: string = '1.5rem',
  marginBottom: string = '7px',
  actAsBoolean: boolean = false,
) => {
  switch (communicationLog?.channel) {
    case 'Conversation':
      return actAsBoolean ? true : <ChatOutlinedIcon style={{ fontSize: fontSize, marginBottom: marginBottom }} />;
    case 'Voice':
      return actAsBoolean ? true : <CallOutlinedIcon style={{ fontSize: fontSize, marginBottom: marginBottom }} />;
    case 'Email':
      return actAsBoolean ? true : <EmailOutlinedIcon style={{ fontSize: fontSize, marginBottom: marginBottom }} />;

    default:
      return actAsBoolean ? false : null;
  }
};

export const communicationNotesOrSubject = (log, index, getNotes, getEmailNotes, missingField) => {
  const notes = (log && log?.response && log?.response?.notes) || '';
  const emailSubject = (log && log?.message && log?.message?.subject) || '';
  switch (log.channel) {
    case 'Voice': {
      if (notes !== '') {
        return getNotes(notes, log, index);
      }
    }
    case 'Conversation': {
      if (notes !== '') {
        return getNotes(notes, log, index);
      }
    }
    case 'Email': {
      if (emailSubject !== '') {
        return getEmailNotes(emailSubject, index, log.channel);
      } else return missingField;
    }
    default:
      return missingField;
  }
};

export const getDelieveryStatusMessage = (obj, missingField) => {
  if (obj?.deliveryStatus) {
    return obj?.deliveryStatus[0]?.toUpperCase() + obj?.deliveryStatus.slice(1);
  } else {
    return missingField;
  }
};

export const FacilityCommunictionHistoryFlattenData = items =>
  items.map((item, index) => {
    const data = {
      id: `${item.rowID}-${index}`,
      indexCounter: index,
      contactName: item?.contactName,
      communicationType: item?.communicationType,
      contactInfo: item?.contactInfo,
      date: item?.date,
      from: item?.from,
      userRole: item?.userRole,
      status: item?.status,
      type: item?.type,
      notes: item?.notes,
    };
    return data;
  });
