/* eslint-disable tss-unused-classes/unused-classes */
/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Button, Divider, Grid, Skeleton } from 'amn-ui-core';
import React, { FC, useEffect, useRef, useState } from 'react';
import { FacilityStatus } from 'app/enums/Facility';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { ResponsiveColumnContainer } from 'app/layout/ResposiveColumnContainer';
import PersonIcon from '@mui/icons-material/Person';
import { HeaderContactDrawer } from 'app/components/Common/Drawer/HeaderContactDrawer';
import {
  HeaderContactDrawerContent,
  IFacilitySidePanelTabs,
} from 'app/components/Common/Drawer/HeaderContactDrawerContent';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import { trackEvent } from 'app-insights/appInsightsTracking';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { IRecentHistoryFacility } from 'app/models/Global/RecentHistoryModels';
import { userDevicePreferenceActions } from 'oidc/UserDevicePreference/userPreference.redux';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import HeaderButton from '@AMIEWEB/Common/Buttons/HeaderButton';
import { facilityActions } from '@AMIEWEB/Facility/FacilityStore/Facility.redux';
import { selectFacility, selectIsFacilityByIdRequestSuccess } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { useFacilityHeaderStyles } from './FacilityHeader.styles';
import { missingField } from 'app/constants';
import { useDecision } from '@optimizely/react-sdk';
import { ff_facility_ui_view_new_status_design } from 'app/constants/FeatureFlags/Facility/Keys';
import {
  IHeaderRow,
  formatPhoneNumber,
  getDate,
  getFacilityHeaderAddressTemplate,
} from './HeaderUtils';
import { PreferenceCosmosKeys } from 'oidc/UserDevicePreference/utils';

export const FacilityHeader = () => {
  const { classes } = useFacilityHeaderStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const facilityData = useSelector(selectFacility);
  const userPreference = useSelector(selectUserPreference);
  const facilityDetailsResponse = facilityData?.facilityResponse;
  const facilityIdd = facilityDetailsResponse?.facilityId;
  const [showNewStatusIndicator] = useDecision(ff_facility_ui_view_new_status_design);
  const facilityLoading = useSelector(selectIsFacilityByIdRequestSuccess);

  const closeProfileDrawer = () => {
    dispatch(
      facilityActions.setFacilityDrawerVisibility({ open: false, tabId: IFacilitySidePanelTabs.CONTACTS }),
    );
    dispatch(globalActions.setDetailsPageDrawer({ open: false }));
  };

  const setFacilityStatusColor = (statusText: string) => {
    switch (statusText) {
      case FacilityStatus.Active:
        return classes.facilityStatusGreen;
      case FacilityStatus.Prospect:
      case FacilityStatus.Pending:
        return classes.facilityStatusOrange;
      case FacilityStatus.Closed:
      case FacilityStatus.Delete:
      case FacilityStatus.Inactive:
        return classes.facilityStatusRed;
      default:
        return;
    }
  };

  const setOldFacilityStatusColor = (statusText: string) => {
    switch (statusText) {
      case FacilityStatus.Active:
        return classes.facilityStatusActive;
      case FacilityStatus.Prospect:
      case FacilityStatus.Pending:
        return classes.facilityStatusProspect;
      case FacilityStatus.Closed:
      case FacilityStatus.Delete:
      case FacilityStatus.Inactive:
        return classes.facilityStatusFailed;
      default:
        return;
    }
  };

  React.useEffect(() => {
    if (facilityDetailsResponse?.facilityId && userPreference) {
      const recentHistoryPrefs = userPreference?.recentHistory?.value;
      let newRecentHistoryPrefs = _.cloneDeep(recentHistoryPrefs);
      let facilityArray: IRecentHistoryFacility[] = recentHistoryPrefs?.facility
        ? [...recentHistoryPrefs?.facility]
        : [];
      let facilityId = facilityDetailsResponse?.facilityId;
      let name = facilityDetailsResponse?.facilityName?.substring(0, 10);
      if (
        (facilityArray?.length > 0 && facilityArray[0].facilityId !== facilityId) ||
        (facilityArray?.length === 0 && recentHistoryPrefs !== null)
      ) {
        const facilityVal: IRecentHistoryFacility = {
          facilityId: facilityIdd,
          name: name,
        };
        let index = facilityArray.findIndex(item => item.facilityId === facilityId);

        if (index === -1) {
          facilityArray.unshift(facilityVal);
          if (facilityArray.length > 5) {
            facilityArray = facilityArray.slice(0, 5);
          }
        } else {
          facilityArray.splice(index, 1);
          facilityArray.unshift(facilityVal);
          if (facilityArray?.length > 5) {
            facilityArray = facilityArray.slice(0, 5);
          }
        }
        newRecentHistoryPrefs = { ...newRecentHistoryPrefs, facility: facilityArray };

        dispatch(
          userDevicePreferenceActions.saveUserRecentHistoryPreferenceAction({
            id: PreferenceCosmosKeys.recentHistory,
            value: newRecentHistoryPrefs,
          }),
        );
      }
    }
  }, [facilityDetailsResponse?.facilityId, userPreference]);

  const headerRowData: IHeaderRow[] = [
    {
      rowHead: t('facility.facilityHeader.staffingVendor'),
      rowContent: facilityDetailsResponse?.staffingVendor,
      classes: classes,
      id: 'facilityHeaderStaffingVendor',
    },
    {
      rowHead: t('facility.facilityHeader.amnDivision'),
      rowContent: facilityDetailsResponse?.division,
      classes: classes,
      id: 'facilityHeaderAMNDivision',
    },
    {
      rowHead: t('facility.facilityHeader.businessRelationship'),
      rowContent: facilityDetailsResponse?.businessRelationship,
      classes: classes,
      id: 'facilityHeaderBusinessRelationship',
    },
    {
      rowHead: t('facility.facilityHeader.contractAffiliate'),
      rowContent: facilityDetailsResponse?.affiliationName,
      classes: classes,
      id: 'facilityHeaderContractAffiliate',
    },
    {
      rowHead: t('facility.facilityHeader.parent'),
      rowContent: facilityDetailsResponse?.healthSystemName,
      classes: classes,
      id: 'facilityHeaderParent',
    },
    {
      rowHead: t('facility.facilityHeader.mspClient'),
      rowContent: facilityDetailsResponse?.mspClient,
      classes: classes,
      id: 'facilityHeaderMSPClient',
    },
    {
      rowHead: t('facility.facilityHeader.phone'),
      rowContent: facilityDetailsResponse?.phone,
      classes: classes,
      id: 'facilityHeaderPhone',
      type: 'phone',
    },
    {
      rowHead: t('facility.facilityHeader.status'),
      rowContent: facilityDetailsResponse?.facilityStatus,
      classes: classes,
      id: 'facilityHeaderStatus',
      type: 'status',
      isFeatureFlagEnabled: showNewStatusIndicator?.enabled,
    },
    {
      rowHead: t('facility.facilityHeader.lastUpdated'),
      rowContent: getDate(facilityDetailsResponse?.lastUpdated),
      classes: classes,
      id: 'facilityHeaderLastUpdated',
    },
    {
      rowHead: t('facility.facilityHeader.lastUpdatedBy'),
      rowContent: `${facilityDetailsResponse?.lastUpdatedBy.firstName} ${facilityDetailsResponse?.lastUpdatedBy.lastName}`,
      classes: classes,
      id: 'facilityHeaderLastUpdatedBy',
    },
  ];

  const openFacilityDrawer = () => {
    dispatch(globalActions.resetAllRecentHistoryDrawers());
    dispatch(
      facilityActions.setFacilityDrawerVisibility({ open: true, tabId: IFacilitySidePanelTabs.CONTACTS }),
    );
    dispatch(globalActions.setDetailsPageDrawer({ open: true }));
  };

  const StatusRowContent: FC<IHeaderRow> = props => {
    return (
      <Avatar
        variant="rounded"
        className={
          props?.isFeatureFlagEnabled
            ? `${props.classes.facilityStatus} ${setFacilityStatusColor(props.rowContent)}`
            : `${props.classes.previousFacilityStatusDesign} ${setOldFacilityStatusColor(props.rowContent)}`
        }
      >
        <>
          <span
            className={props?.isFeatureFlagEnabled ? props.classes.candidateStatusText : props.classes.oldStatusText}
          >
            {props.rowContent}
          </span>
          <span>
            <ExpandMoreIcon />
          </span>
        </>
      </Avatar>
    );
  };

  const HeaderRow: FC<IHeaderRow> = props => {
    const [showTooltip, setShowTooltip] = useState(false);
    const rowSectionRef = useRef(null);
    const rowHeadRef = useRef(null);

    useEffect(() => {
      if (rowHeadRef.current && typeof props.onMeasureWidth === 'function') {
        // Measure and send this to a parent component
        const width = rowHeadRef.current.offsetWidth;
        props.onMeasureWidth(width);
      } else {
        console.warn('rowHeadRef.current or props.onMeasureWidth is not available');
      }
    }, []);
    useEffect(() => {
      // setShowTooltip(rowSectionRef?.current?.scrollWidth > rowSectionRef?.current?.clientWidth);
      setShowTooltip(props.rowContent?.length >= 25); //Enabling to handle longer value
    }, [rowSectionRef.current]);

    return (
      <Grid container className={classes.fieldContainer} data-testid="table-test-id" id={props.id}>
        <Grid item className={classes.fieldHead} data-id="content">
          <div ref={rowHeadRef} style={{ minWidth: `${props.fixedWidth}px` }}>
            {props.rowHead}
          </div>
        </Grid>
        <Grid
          item
          className={classes.fieldContentContainer}
          data-id="content"
          sx={theme => ({
            ml: { md: theme.spacing(), lg: theme.spacing(2), xl: theme.spacing(3) },
          })}
        >
          <CustomTooltip hideToolTip={!showTooltip} placement="bottom" arrow={true} tooltipContent={props.rowContent}>
            <div
              className={props.type === 'phone' && props.rowContent ? classes.headerPhoneNumber : classes.fieldContent}
              ref={rowSectionRef}
            >
              {props.type === 'status' ? (
                <StatusRowContent {...props} />
              ) : props.type === 'phone' && props.rowContent ? (
                formatPhoneNumber(props.rowContent)
              ) : props.rowContent ? (
                props.rowContent
              ) : (
                missingField
              )}
            </div>
          </CustomTooltip>
        </Grid>
      </Grid>
    );
  };

  const HeaderSkeletonLarge = () => {
    return (
      <>
        <Grid container className={classes.fieldContainer}>
          <Grid item className={classes.fieldHead}>
            <Skeleton width={100} height={25} />
          </Grid>
          <Grid item className={classes.fieldContentContainer}>
            <Skeleton width={150} height={25} />
          </Grid>
        </Grid>
        <Grid container className={classes.fieldContainer}>
          <Grid item className={classes.fieldHead}>
            <Skeleton width={80} height={25} />
          </Grid>
          <Grid item className={classes.fieldContentContainer}>
            <Skeleton width={160} height={25} />
          </Grid>
        </Grid>
        <Grid container className={classes.fieldContainer}>
          <Grid item className={classes.fieldHead}>
            <Skeleton width={100} height={25} />
          </Grid>
          <Grid item className={classes.fieldContentContainer}>
            <Skeleton width={120} height={25} />
          </Grid>
        </Grid>
      </>
    );
  };

  const HeaderSkeletonSmall = () => {
    return (
      <>
        <Grid container className={classes.fieldContainer}>
          <Grid item className={classes.fieldHead}>
            <Skeleton width={70} height={25} />
          </Grid>
          <Grid item className={classes.fieldContentContainer}>
            <Skeleton width={70} height={25} />
          </Grid>
        </Grid>
        <Grid container className={classes.fieldContainer}>
          <Grid item className={classes.fieldHead}>
            <Skeleton width={40} height={25} />
          </Grid>
          <Grid item className={classes.fieldContentContainer}>
            <Skeleton width={100} height={25} />
          </Grid>
        </Grid>
        <Grid container className={classes.fieldContainer}>
          <Grid item className={classes.fieldHead}>
            <Skeleton width={50} height={25} />
          </Grid>
          <Grid item className={classes.fieldContentContainer}>
            <Skeleton width={80} height={25} />
          </Grid>
        </Grid>
      </>
    );
  };

  const HeaderColumn: FC<{ rowData: IHeaderRow[] }> = props => {
    const [minWidth, setMinWidth] = useState(0);
    const handleMeasureWidth = (width: number) => {
      setMinWidth(oldWidth => Math.max(oldWidth, width));
    };

    return (
      <Grid className={classes.fieldSection} direction="column">
        {props.rowData.map((item, index) => {
          return (
            <HeaderRow
              key={index}
              rowHead={item.rowHead}
              rowContent={item.rowContent}
              classes={item.classes}
              id={item.id}
              onMeasureWidth={handleMeasureWidth}
              fixedWidth={minWidth}
              type={item.type}
              isFeatureFlagEnabled={item?.isFeatureFlagEnabled}
            />
          );
        })}
      </Grid>
    );
  };

  return (
    <>
      <Grid data-testid="header-id" container xs={12} direction="row" classes={{ container: classes.container }}>
        <Grid item direction="column" classes={{ root: classes.containerAvatar }}>
          {facilityLoading ? (
            <Grid item direction="row">
              <Skeleton variant="rounded" width={200} height={40} />
            </Grid>
          ) : (
            <Grid item direction="row" classes={{ root: classes.myAvatarAlignment }}>
              <HeaderButton
                headerAbbr=""
                headerAbbrBackground="#05528B"
                headerId={`FID ${facilityDetailsResponse?.facilityId}`}
                headerIdBackground="#003C69"
              />
            </Grid>
          )}
          {facilityLoading ? (
            <Grid item direction="row" mt={2}>
              <Skeleton variant="rounded" width={200} height={40} />
            </Grid>
          ) : (
            <Grid item classes={{ root: classes.contactDrawer }}>
              <Button
                variant="outlined"
                color="primary"
                className={classes.contactBtn}
                startIcon={<PersonIcon />}
                onClick={() => {
                  trackEvent({ type: 'click', name: 'Key Contacts', properties: { pageName: 'Facility Details' } });
                  openFacilityDrawer();
                }}
              >
                {t('placement.profile.header.keyContacts')}
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid item direction="column" classes={{ root: classes.detailsContainerWrap }}>
          {facilityLoading ? (
            <Grid item mr={1}>
              <Skeleton width={400} />
            </Grid>
          ) : (
            <Grid item direction="row">
              <div className={classes.alignCenterDiv}>
                <span className={classes.headerLinkContent} data-testid="facility-name" id={'facilityHeaderName'}>
                  {facilityDetailsResponse?.facilityName}
                </span>
              </div>
            </Grid>
          )}
          <Grid item container direction="row" classes={{ root: classes.headerInfo }} xs={12}>
            <Grid
              item
              className={classes.firstRowMaxWidth}
              sx={{
                maxWidth: '25%',
              }}
            >
              {facilityLoading ? (
                <Grid item xs="auto">
                  <HeaderSkeletonLarge />
                </Grid>
              ) : (
                <Grid item xs="auto">
                  <HeaderColumn rowData={headerRowData.slice(0, 3)} />
                </Grid>
              )}
            </Grid>
            <Divider
              orientation="vertical"
              flexItem
              sx={theme => ({
                m: { md: `0 ${theme.spacing()}`, lg: `0 ${theme.spacing(2)}`, xl: `0 ${theme.spacing(6)}` },
              })}
            />
            <Grid item className={classes.secondRowMaxWidth} sx={{ maxWidth: { md: '25%', lg: '30%' } }}>
              {facilityLoading ? (
                <Grid item xs="auto">
                  <HeaderSkeletonLarge />
                </Grid>
              ) : (
                <Grid item xs="auto">
                  <HeaderColumn rowData={headerRowData.slice(3, 6)} />
                </Grid>
              )}
            </Grid>
            <Divider
              orientation="vertical"
              flexItem
              sx={theme => ({
                m: { md: `0 ${theme.spacing()}`, lg: `0 ${theme.spacing(2)}`, xl: `0 ${theme.spacing(6)}` },
              })}
            />
            <Grid item>
              {facilityLoading ? (
                <Grid item>
                  <HeaderSkeletonSmall />
                </Grid>
              ) : (
                <Grid item xs={'auto'}>
                  <HeaderColumn rowData={headerRowData.slice(6, 8)} />
                </Grid>
              )}
            </Grid>
            <Divider
              orientation="vertical"
              flexItem
              sx={theme => ({
                m: { md: `0 ${theme.spacing()}`, lg: `0 ${theme.spacing(2)}`, xl: `0 ${theme.spacing(6)}` },
              })}
            />
            <Grid item>
              {facilityLoading ? (
                <Grid item>
                  <HeaderSkeletonSmall />
                </Grid>
              ) : (
                <Grid item xs={'auto'}>
                  <HeaderColumn rowData={headerRowData.slice(8, 10)} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item direction="row" classes={{ root: classes.actionContainer }}>
        {facilityLoading ? (
          <Grid mt={0.5} gap={3} display={'flex'}>
            <Skeleton variant="rectangular" width={89} height={19} />
            <Skeleton variant="rectangular" width={223} height={19} />
          </Grid>
        ) : (
          <Grid item style={{ display: 'flex' }}>
            <Grid item style={{ display: 'flex' }}>
              <table style={{ display: 'table-caption' }}>
                <tbody>
                  <ResponsiveColumnContainer
                    id={'facilityHeaderAddress'}
                    rowHead={`FID ADDRESS:`}
                    rowContent={getFacilityHeaderAddressTemplate(facilityDetailsResponse?.location)}
                    classes={classes}
                    customRowHeadClass={classes.rowHeadCustom}
                    customRowContentClass={`${classes.rowContentCustom} ${classes.rowContentDefaultColor}`}
                  />
                </tbody>
              </table>
            </Grid>
          </Grid>
        )}
      </Grid>
      {facilityData?.facilityDrawerState?.open && (
        <HeaderContactDrawer
          closeProfileDrawer={closeProfileDrawer}
          openProfileDrawer={facilityData?.facilityDrawerState?.open}
          isBorderAvatar={false}
          avatarOffSet={`FID`}
          avatarText={`${facilityDetailsResponse.facilityId}`}
          title={facilityDetailsResponse.facilityName}
          phone={facilityDetailsResponse.phone ?? 'N/A'}
          children={<HeaderContactDrawerContent />}
        />
      )}
    </>
  );
};
