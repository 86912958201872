import { PayloadAction } from "@reduxjs/toolkit";
import { trackException } from "app-insights/appInsightsTracking";
import { ExceptionType } from "app/enums/Common";
import { NotificationService } from "app/services/NotificationServices/notification-service";
import { httpSuccess } from "app/services/serviceHelpers";
import { trackPromise } from "react-promise-tracker";
import { call, getContext, put, takeLatest } from "redux-saga/effects";
import { facilityCommunicationHostoryActions, IFacilityLogs } from "./FacilityCommunicationHistory.redux";

const TrackFacilityLogs = (fn, ...args) => trackPromise(fn(...args), 'send-inbox-read-status');

export function* getFacilityLogs(action: PayloadAction<IFacilityLogs>) {
    const notificationService: NotificationService = yield getContext('notificationService');
    try {
        yield put(facilityCommunicationHostoryActions.setGridLoading(true));
        var response = yield call(TrackFacilityLogs, notificationService.getFacilityLogs, action.payload);
        if (httpSuccess(response?.status)) {
            yield put(facilityCommunicationHostoryActions.updateFacilityCommunicationLogs(response?.data));
        }
        yield put(facilityCommunicationHostoryActions.setGridLoading(false));
    } catch (error) {
        yield put(facilityCommunicationHostoryActions.setGridLoading(false));
        trackException({
            exception: error,
            properties: {
                name: ExceptionType.APIRequestError,
                functionName: 'getFacilityLogs',
                area: 'src/app/components/Facility/FacilityDetails/FacilityTabs/CommunicationHistory/store/FacilityCommunicationHistory.saga.ts',
            },
        });
    }
};

export function* FacilityCommunicationHistorySaga() {
    yield takeLatest(facilityCommunicationHostoryActions.getFacilityLogs.type, getFacilityLogs);

}