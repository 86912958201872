import { FormatDate } from '@AMIEWEB/Notification/Tasks/Common/FormatData';
import { convertMultipleAssignedUserData, convertUserData } from './CreateTask/FormatData';
import { ITypeAheadOption } from 'app/models/Orders/OrderDetails';
import _cloneDeep from 'lodash/cloneDeep';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { getSharedDeskAssociatedUser } from './EditTask/helper';
import { ActionType, TaskDescription } from 'app/models/Tasks/Tasks';
import { theme } from 'styles/global-styles';

export const formatTaskUpdateDetails = (UpdatedDateValue, details, userInfo) => {
  const customDateFormat = UpdatedDateValue?.setHours(23, 59, 59);
  const intCC = details?.cc;
  const convertedAssignedTo = convertMultipleAssignedUserData(details?.assignedTo);
  const assignedToUsers = convertedAssignedTo?.map((a: ITypeAheadOption) => a?.object).map(d => d?.empId);
  const convertedCC = convertUserData(intCC);
  const cc = convertedCC?.map((a: ITypeAheadOption) => a?.object).map(d => d?.empId);

  const initialState = {
    assignedTo: convertedAssignedTo,
    cc: cc,
    task: details?.task,
    taskName: details?.taskName,
    taskCategory: details?.taskCategory,
    description: details?.description,
    dueDate: new Date(details?.dueDate),
    onBehalfOf: null,
  };

  const updatedTaskDetails  = {
    assignedToIds: assignedToUsers,
    ccIds: cc,
    companyId: details?.companyId,
    description: details?.description?.replace(/\r?\n/g, '\r\n'),
    dueDate: FormatDate(customDateFormat),
    task: details?.task,
    taskCategory: details?.taskCategoryId,
    taskCategoryText: details?.taskCategory,
    taskId: details?.taskId,
    taskNameId: details?.taskNameId,
    sharedDeskAssociatedUser: isNullOrUndefined(userInfo?.sharedProfile) ? undefined : getSharedDeskAssociatedUser(userInfo, ActionType.EditTask, TaskDescription.EditTask),
  };
  return { updatedTaskDetails, initialState };
};

export const formatSubtaskUpdateDetails = (details, parentTaskDetails, UpdatedDateValue, userInfo) => {
  const intCC = details?.ccs;
  const convertedAssignedTo = convertMultipleAssignedUserData(details?.tos);
  const assignedToUsers = convertedAssignedTo?.map((a: ITypeAheadOption) => a?.object).map(d => d?.empId);
  const convertedCC = convertUserData(intCC);
  const cc = convertedCC?.map((a: ITypeAheadOption) => a?.object).map(d => d?.empId);
  const updatedComment = _cloneDeep(details);
  updatedComment.tos = assignedToUsers;
  updatedComment.ccs = cc;
  updatedComment.companyId = parentTaskDetails?.companyId;
  updatedComment.comment = details?.comment?.replace(/\r?\n/g, '\r\n');
  updatedComment.dueDate = FormatDate(UpdatedDateValue);
  updatedComment.subject = updateSubject(details?.subject);
  updatedComment.taskCategory = parentTaskDetails?.taskCategoryId;
  updatedComment.taskCategoryText = parentTaskDetails?.taskCategory;
  updatedComment.originalTaskId = parentTaskDetails?.taskId;
  updatedComment.rootTaskId = parentTaskDetails?.rootTaskId;
  updatedComment.createdBy = details?.createdBy;
  updatedComment.taskNameId = parentTaskDetails?.taskNameId;
  updatedComment.sharedDeskAssociatedUser = isNullOrUndefined(userInfo?.sharedProfile)
    ? undefined
    : getSharedDeskAssociatedUser(userInfo, ActionType.EditTask, TaskDescription.EditTask);

  return updatedComment;
};

export const updateSubject = subject => {
  if (!subject) return subject;
  const hyphenIndex = subject.indexOf('-');
  if (hyphenIndex !== -1) {
    return subject.slice(hyphenIndex + 1).trimStart();
  }
  return subject;
};

export const getPayPackageStatusChipColor = (val: number) => {
  switch (val) {
    case 1:
      return `${theme.palette.framework?.system?.lightOrange}`;
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      return `${theme.palette.framework?.system?.lightBlue}`;
    case 7:
      return `${theme.palette.framework?.system?.lightGreen}`;
    default:
      return `${theme.palette.framework?.system?.lightOrange}`;
  }
};

export const getPayPackageStatusChipTextColor = (val: number) => {
  switch (val) {
    case 1:
      return `${theme.palette?.system?.orangeBrown}`;
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      return `${theme.palette.framework?.system?.navyBlue}`;
    case 7:
      return `${theme.palette?.system?.irishGreen}`;
    default:
      return `${theme.palette?.system?.orangeBrown}`;
  }
};


// temporary function to fetch the status name. Will be removed once pay package scenario data will be integrated 
export const getPayPackageStatusName = (val: number) => {
  switch (val) {
    case 1:
      return 'Pending';
    case 2:
      return 'R Approval';
    case 3:
      return 'AM Approval';
    case 4:
      return 'CCA Approval';
    case 5:
      return 'AM Confirmation';
    case 6:
      return 'CC Approval';
    case 7:
      return 'Verified';
    default:
      return 'Pending';
  }
};

export const getPayPackageStatusTooltip = (val: number) => {
  switch (val) {
    case 1:
      return 'Pending';
    case 2:
      return 'Recruitment Approval';
    case 3:
      return 'Account Management Approval';
    case 4:
      return 'Client Contract Admin Approval';
    case 5:
      return 'Account Management Confirmation';
    case 6:
      return 'Candidate Contract Approval';
    case 7:
      return 'Verified';
    default:
      return 'Pending';
  }
};
