import { selectFacility } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { Divider, List, ListItem, ListItemSecondaryAction, ListItemText, Tooltip, Typography } from 'amn-ui-core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { getKeyClientContacts } from '../FacilityHeader/HeaderUtils';
import { useEnableEmailCommunication, useEnableVoiceCommunication } from 'oidc/CommunicationEnabler';
import MailBoxIcon from 'app/assets/images/MailBox.svg';
import PhoneIcon from 'app/assets/images/Phone.svg';
import EmailDisabled from 'app/assets/images/CandidateHeader/email-Disabled.svg';
import PhoneDisabled from 'app/assets/images/CandidateHeader/PhoneDisabled.svg';
import { ChannelType, FormName, SendType, UseSubType, UseType } from '@AMIEWEB/Notification/Constants';
import { IEmployee, IToken } from 'app/models/Notification/Notification';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { selectUser } from 'oidc/user.selectors';
import { Concatenate } from 'utils/string/string';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { searchNotificationData } from '@AMIEWEB/GlobalSearch/helper';
import { EmailDefaultValues } from 'app/models/Email/IEmail';
import _ from 'lodash';
import { IDrawerContactPartner } from '@AMIEWEB/Common/Drawer/HeaderContactDrawer';
import { getDefaultSender } from '@AMIEWEB/Candidate/CandidateProfile/CandidateHeader/NotificationHelper';

const useStyles = makeStyles()(theme => ({
    tooltip: {
      display: 'grid',
    },
    tooltipArrow: {
      color: theme.palette.framework.system.white,
      '&::before': {
        border: `1px solid ${theme.palette.framework.system.lightDoveGray}`,
      },
    },
    tooltipBackground: {
      backgroundColor: theme.palette.framework.system.white,
      color: theme.palette.framework.system.lightDoveGray,
      border: `1px solid ${theme.palette.framework.system.lightDoveGray}`,
      fontSize: '12px',
    },
    root: {
      padding: 0,
    },
    customProfileList: {
      'padding-right': '2rem',
      '&.customProfileListKeyClient': {
        'padding-right': '0.5rem',
        'padding-left': '0.5rem',
      },
      '& .MuiListItemAvatar-alignItemsFlexStart': {
        'min-width': 'auto',
      },
      '& .MuiDivider-root': {
        'background-color': 'rgb(0 0 0 / 6%)',
      },
      '& .MuiListItem-secondaryAction': {
        'padding-right': '60px',
      },
    },
    customProfileUserName: {
      color: theme.palette.framework.system.midnightBlue,
      'font-size': '16px',
      'font-weight': '500',
    },
    secondaryTextWithOutAvatar: {
      maxWidth: 225,
      position: 'relative',
      top: '-2px',
    },
    designation: {
      color: theme.palette.framework.system.charcoal,
      fontSize: 12,
      fontWeight: 600,
    },
    description: {
      color: theme.palette.framework.system.charcoal,
      fontSize: 12,
      'font-weight': '400',
      'line-height': '1.2',
    },
    actionContainer: {
      right: 0,
    },
  }));
  
type ClientContactsProps = {
    // Define the props for your component here
};

const ClientContacts: React.FC<ClientContactsProps> = (props) => {
    const { classes } = useStyles();
    const dispatch = useDispatch();
    
    const facilityData = useSelector(selectFacility);
    const notificationData = useSelector(notificationSelection);
    const { userInfo } = useSelector(selectUser);

    const facilityDetailsResponse = facilityData?.facilityResponse;
    const keyClientContacts = getKeyClientContacts(facilityDetailsResponse);
    const enableEmailCommunication = useEnableEmailCommunication();
    const enableVoiceCommunication = useEnableVoiceCommunication();

    const handleCommunication = (props: { channelType: ChannelType; contactData: IDrawerContactPartner }) => {
        const { contactData } = props;
        const associatedRecords = [
          {
            name: 'clientContactId',
            value: `${contactData?.userId}`,
          },
          { name: 'facilityId', value: contactData?.facilityId?.toString() },
          { name: 'facilityName', value: contactData?.facilityName?.toString() },
        ];
        const sender: IEmployee = getDefaultSender({ userInfo });
        const tos = {
          contactId: contactData?.userId?.toString(),
          designation: contactData?.userRole,
          email: contactData?.email,
          phoneNumber: contactData?.phone?.replace(/^\+\d+\s\(/, '('),
          name: `${contactData?.firstName} ${contactData?.lastName}`,
          clientContactDetails: {
            statusId: contactData?.statusId?.toString(),
            unitIds: [],
          },
        };
        if (props.channelType === ChannelType.voice) {
          const VoiceData = {
            manualLog: false,
            channel: props.channelType,
            associatedRecords: associatedRecords,
            sender: sender,
            sendType: SendType.one_to_one,
            tos: [tos],
            useType: UseType.ClientContacts,
            userRole: Concatenate(userInfo?.roles || [], ','),
            formName: FormName[FormName.Contact],
            UseSubType: UseSubType.ClientContactGrid,
          };
          if (!notificationData?.voice?.open && notificationData?.voice?.minimized) {
            dispatch(
              notificationDataActions.setVoiceInteraction({
                open: true,
                minimized: false,
                showDisposition: true,
              }),
            );
            if (notificationData?.voice?.data?.tos.find(Boolean).contactId !== contactData?.contactId) {
              dispatch(notificationDataActions.setSnackBarData({ channel: 'voice', manual: false, changeWarning: true }));
            }
          } else {
            dispatch(
              notificationDataActions.setVoiceData({
                data: VoiceData,
                open: true,
                minimized: false,
              }),
            );
          }
        } else {
          const notificationUser = searchNotificationData({ userInfo });
          const body = `<p><br><br><br>{{signature}}</p>`;
          const substitutions: Array<IToken> = [
            {
              name: 'Signature',
              input: '{{signature}}',
            },
          ];
          const emailData = {
            substitutions: substitutions,
            useType: UseType.ClientContacts,
            emailType: SendType.one_to_one,
            body: body,
            associatedRecords: associatedRecords,
            subject: '',
            tos: [tos],
            sender: sender,
            userRole: Concatenate(userInfo?.roles || [], ','),
            useSubType: UseSubType.ClientContactGrid,
            sendType: SendType.one_to_one,
            disableGroupEmailCheckbox: true,
          };
          if (!notificationData?.email?.open && notificationData?.email?.minimized) {
            dispatch(
              notificationDataActions.setEmailInteraction({
                open: true,
                minimized: false,
              }),
            );
            if (!_.isEqual(notificationData?.email?.data?.tos, [])) {
              dispatch(notificationDataActions.setSnackBarData({ channel: 'email', manual: false, changeWarning: true }));
            }
          } else {
            dispatch(
              notificationDataActions.setEmailData({
                data: {
                  ...EmailDefaultValues(),
                  ...notificationUser,
                  ...emailData,
                },
                open: true,
                minimized: false,
              }),
            );
          }
        }
      };
    
    return (
        <List className={`${classes.root} ${classes.customProfileList} customProfileListKeyClient`}>
        {keyClientContacts?.map((contact, index) =>
          contact?.firstName && contact?.lastName ? (
            <>
              <ListItem alignItems="flex-start" disableGutters key={index}>
                <ListItemText
                  primary={`${contact.firstName} ${contact.lastName}`}
                  classes={{
                    primary: classes.customProfileUserName,
                    secondary: classes.secondaryTextWithOutAvatar,
                  }}
                  secondary={
                    <React.Fragment>
                      <Typography variant="body2" classes={{ root: classes.designation }} data-testid="designation">
                        {contact.userRole}
                      </Typography>
                      <Typography classes={{ root: classes.description }} data-testid="description">
                        {contact.description}
                      </Typography>
                    </React.Fragment>
                  }
                />
                <ListItemSecondaryAction classes={{ root: classes.actionContainer }}>
                  {enableEmailCommunication && !!contact?.email && contact?.email !== '' ? (
                    <Tooltip
                      title={
                        <span className={classes.tooltip}>
                          <span>{contact.email}</span>{''}
                        </span>
                      }
                      disableHoverListener={!contact.email}
                      arrow
                      placement="bottom"
                      classes={{ tooltip: classes.tooltipBackground, arrow: classes.tooltipArrow }}
                    >
                      <img
                        src={MailBoxIcon}
                        alt="MailBox"
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          handleCommunication({ channelType: ChannelType.email, contactData: { ...contact } })
                        }
                      />
                    </Tooltip>
                  ) : (
                    <img src={EmailDisabled} alt="MailBox" />
                  )}
                  {enableVoiceCommunication &&
                  contact?.phone?.length > 0 &&
                  contact?.doNotCall === 0 ? (
                    <Tooltip
                      title={
                        <span className={classes.tooltip}>
                          <span>{contact?.phone}</span>{''}
                        </span>
                      }
                      disableHoverListener={false}
                      arrow
                      placement="bottom"
                      classes={{ tooltip: classes.tooltipBackground, arrow: classes.tooltipArrow }}
                    >
                      <img
                        src={PhoneIcon}
                        alt="Phone"
                        style={{ marginLeft: 6 }}
                        onClick={() =>
                          handleCommunication({ channelType: ChannelType.voice, contactData: { ...contact } })
                        }
                      />
                    </Tooltip>
                  ) : (
                    <img src={PhoneDisabled} style={{ marginLeft: 6 }} alt="PhoneDisabled" />
                  )}
                </ListItemSecondaryAction>
              </ListItem>
              {index !== keyClientContacts.length - 1 && <Divider variant="fullWidth" component="li" />}
            </>
          ) : (
            <></>
          ),
        )}
      </List>
    );
};

export default ClientContacts;