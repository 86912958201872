import { PayloadAction } from '@reduxjs/toolkit';
import { trackException } from 'app-insights/appInsightsTracking';
import { EChangeHistoryName, missingField } from 'app/constants';
import {
  deleteCandidateEducation,
  deleteCandidateWorkExperienceRecord,
  deleteUnverifiedHearsayCertification,
  deleteUnverifiedHearsayLicense,
  fetchAllSkillChecklistData,
  getCandidateById,
  getCandidateRequestReference,
  getEMRList,
  getSSNValueByTravelerId,
  getSupervisorData,
  getTagsListByCandidateId,
  getTimeOffRequest,
  postCandidateAudit,
  postCandidatePersonalInfo,
  postCandidateReference,
  postEMRData,
  postReferenceRequest,
  postSellingNotes,
  postSupervisorData,
  postTagsList,
  postTimeOffRequest,
  postUnverifiedHearsayCertification,
  postUnverifiedHearsayLicense,
  updateCandidateDOB,
  updateCandidateNUID,
  updateCandidatePersonalInfo,
  updateCandidateSSN,
  updateCandidateAddress,
  deleteSupervisorSkillSet,
  getPermanentTaxLetterData,
  getTaxData,
  getPreferencesData,
  deleteTravelerPayroll,
  deleteTravelerTaxInfo,
  postTaxData,
  postTaxLetterInfo,
  postPreferencesData,
  deleteCandidateSkillSet,
  getCandidateSkillsetTree,
  updateCandidateSkillsetTree,
  deleteCandidateAudit,
  getCandidateAddress,
} from 'app/services/CandidateServices/CandidateServices';
import { ExceptionType } from 'app/enums/Common';
import {
  getChangeHistory,
  getDisciplineSpecialtyData,
  getEthnicityLookups,
  getSearchFilterOptions,
  getSearchLookups,
  getTravelerCompanyList,
} from 'app/services/SharedServices/SharedServices';
import { trackPromise } from 'react-promise-tracker';
import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import { GetMomentOfDate } from 'utils/dates/moment';
import { candidateDetailActions } from './CandidateDetails.redux';
import {
  IAuditItems,
  IAuditPostData,
  ICandidateDateOfBirth,
  ICandidateNUID,
  ICandidateSSN,
  IDeleteHearsayCertification,
  IDeleteHearsayLicense,
  IEmrListToTransform,
  IHearsayCertificationRequest,
  IHearsayLicense,
  IPostSupervisorData,
  IReferenceData,
  ISupervisorData,
  ISupervisorSkillsets,
  SaveStatus,
  IDeleteSupervisorSkillsets,
  IDeleteSupervisorSkillsetData,
  IDeleteTravelerPayroll,
  IDeleteTravelerTaxInfo,
  ITaxData,
  IPostTaxLetterInfo,
  ICandidatePreferences,
  IDeleteSkillSet,
  IUpdateSkillsetTreeData,
  IDeleteAudit,
} from 'app/models/Candidate/CandidateProfile';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import moment from 'moment';
import { isArray } from 'lodash';
import { ISaveSellingNotesTemplate } from 'app/models/Candidate/SellingNotes';
import {
  candidateDetailsSelection,
  candidateEmployementSelection,
  candidateSaveStatusSelector,
  referenceGridSelector,
  selectMasterCopyData,
  selectReferenceData,
  selectReferences,
} from './CandidateDetails.selector';
import { parseDropDown } from '../CandidateTabPanel/ProfileTab/Education/EducationHelper';
import {
  GetDisciplineOptions,
  GetSpecialtyOptions,
} from 'app/components/Order/OrderCreationForm/OrderDetails/FormatData';
import { httpSuccess } from 'app/services/serviceHelpers';
import { ITypeAheadOption } from '../CandidateTabPanel/PreferencesTab/CustomComponents/ControlledTypeAhead';
import { formatTypeAheadOptions } from '../CandidateTabPanel/PreferencesTab/FormatData';
import { ICandidateAddressDetails, ICandidateHeaderDetails } from '../CandidateHeader/CandidateHeader';
import { selectUser } from 'oidc/user.selectors';
import { processSsnAndDOB } from '../CandidateTabPanel/PersonalInfoTab/Form/PersonalInfoHelper';
import { processSsn } from '../CandidateTabPanel/PersonalInfoTab/Form/PersonalInfoForm';
import { EMRFilterName } from '../CandidateTabPanel/ProfileTab/Cards/EMR/EMRFilterConfig';
import { getGender } from './helper';
import { IPostTags, IReferenceRequest } from 'app/models/Candidate/Tags';
import { PlacementService } from 'app/services/PlacementServices/placement-service';
import { convertToPST } from 'app/helpers/dateHelper';
import { formatRequestedTimeOffList } from '../CandidateTabPanel/ProfileTab/Cards/RequestTimeOff/helper';
import { isPositiveNumber } from 'app/helpers/componentHelpers';
import _ from 'lodash';
import { IPostRequestTimeOff } from '../CandidateTabPanel/ProfileTab/Cards/RequestTimeOff/IRequest';
import { formatData } from './Helpers/ReferenceGridHelper';
import { getAvailableChannels } from 'app/services/NotificationServices/NotificationService';
import { viewDOB } from '../CandidateTabPanel/ChangeHistoryTab/helper';
import { APIStatus } from 'app/enums/ApiStatus';
import i18next from 'i18next';

const TrackChangeHistoryWrapper = (fn, ...args) => trackPromise(fn(...args), 'placement-change-history');
const TrackSaveSellingNotesTemplate = (fn, ...args) => trackPromise(fn(...args), 'save-selling-notes-template');
const TrackRaceEthnicity = (fn, ...args) => trackPromise(fn(...args), 'race-ethnicity');
const TrackGender = (fn, ...args) => trackPromise(fn(...args), 'gender');
const TrackProfileSummaryDetails = (fn, ...args) => trackPromise(fn(...args), 'profile-summary-details');
const TrackProfileTimeOffRequest = (fn, ...args) => trackPromise(fn(...args), 'profile-time-off-request');
const TrackDeleteWorkExperience = (fn, ...args) => trackPromise(fn(...args), 'delete-work-exp-record');
export function* getCandidateChangeHistory(action: PayloadAction<{ candidateId: number; candidateGuid: string }>) {
  try {
    const user = yield select(selectUser);
    yield put(candidateDetailActions.setChangeHistoryLoading(true));
    const response = yield call(TrackChangeHistoryWrapper, getChangeHistory, {
      entityId: `${action.payload.candidateId}`,
      entityGUID: `${action.payload.candidateGuid}`,
    });

    const hasNumber = value => {
      return /[a-z]/g.test(value.slice(value.length - 5, value.length));
    };

    const formatData = changeHistory => {
      return changeHistory.map((c, idx) => {
        const createdDate = GetMomentOfDate(c.created);
        const oldValue = c.oldValue.split('<LB>').length > 1 ? c.oldValue.split('<LB>') : c.oldValue;
        const newValue = c.newValue.split('<LB>').length > 1 ? c.newValue.split('<LB>') : c.newValue;
        const history = {
          id: idx,
          userId: c.userId,
          userName: c.userName,
          created: createdDate.isValid()
            ? c.externalSystem === EChangeHistoryName.amieWeb
              ? moment.utc(c.created).tz('America/Los_Angeles').format('MM/DD/YYYY hh:mm:ss A')
              : moment(c.created.replace('+00:00', '')).format('MM/DD/YYYY hh:mm:ss A')
            : missingField,

          newValue:
            hasNumber(newValue) && c.fieldChanged == 'Candidate Header: PhoneNumber'
              ? [missingField]
              : c.fieldChanged?.includes?.('DOB')
              ? viewDOB(c.newValue, user)
              : newValue,

          oldValue:
            hasNumber(oldValue) && c.fieldChanged == 'Candidate Header: PhoneNumber'
              ? [missingField]
              : c.fieldChanged?.includes?.('DOB')
              ? viewDOB(c.oldValue, user)
              : oldValue,

          fieldChanged: c.fieldChanged,
          externalSystem: c.externalSystem ? c.externalSystem : 'AMIE Web',
        };

        return history;
      });
    };
    yield put(
      candidateDetailActions.setChangeHistory(
        formatData(response || []).filter(
          x =>
            (isArray(x.newValue) && x.oldValue != x.newValue[0]) || (!isArray(x.newValue) && x.oldValue != x.newValue),
        ),
      ),
    );
    yield put(candidateDetailActions.setChangeHistoryLoading(false));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'getCandidateChangeHistory',
        area: 'src/app/components/Candidate/CandidateProfile/Profile/CandidateDetails.saga.ts',
      },
    });
  }
}

/**
 *  Method to transform get EMR data
 * @param transFormEmrList
 */
export const transformEMRData = (transFormEmrList: IEmrListToTransform[]) => {
  return transFormEmrList.map((emrItem: IEmrListToTransform) => ({
    id: emrItem.equipmentID,
    value: emrItem.description,
    name: emrItem.description,
    labelPrefix: 'EMR',
    brandId: emrItem.brandId,
    candidateId: emrItem.candidateId,
  }));
};

/**
 *  Generator function to get EMR Data
 * @param action
 */
export function* fetchEMRData(action) {
  try {
    const { candidateId, brandId } = action.payload as { candidateId: number; brandId: number };
    const response = yield call(() => getEMRList(candidateId, brandId));
    const { data } = response;
    const transFormedEmrList = transformEMRData(data);
    const savedEmr = transFormedEmrList.filter(emrItem => emrItem.candidateId && emrItem.brandId);
    yield put(candidateDetailActions.savedEquipmentsProcedure(savedEmr?.length ? savedEmr : []));
    yield put(candidateDetailActions.setEMRList(transFormedEmrList));
  } catch (err) {
    console.log('candidate-details-fetchEMRData');
  }
}

/**
 *  Method to post Equipment and Procedure data
 * @param action
 */
export function* saveEMRData(action) {
  try {
    const response = yield call(postEMRData, action.payload);
    const { data } = response;
    if (httpSuccess(response.status) && data) {
      yield put(
        globalActions.setSnackBar({
          message: 'Equipement Procedures has been updated successfully',
          severity: 'success',
        }),
      );
    }
    yield put(
      candidateDetailActions.setHttpStatus({
        statusCode: response?.status,
        pageName: EMRFilterName.Procedure,
      }),
    );
  } catch (err) {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  }
}

export function* fetchPreferredTimeToReach() {
  try {
    const response = yield call(() => getSearchFilterOptions('besttimetocontact'));
    yield put(candidateDetailActions.setPreferredTimeToReach(response));
  } catch (err) {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  }
}

/**
 *  Method to handle selling notes template save
 * @param action ISaveSellingNotesTemplate
 */
export function* handleSellingNotesTemplateSave(action) {
  const postReqBody: ISaveSellingNotesTemplate = action.payload;
  const candidateDetails = action.payload.candidateDetails;
  delete action.payload.candidateDetails;
  const { sellingnotes } = action.payload;

  try {
    const response = yield call(TrackSaveSellingNotesTemplate, postSellingNotes, postReqBody);
    const { data } = response;
    if (data) {
      yield put(
        candidateDetailActions.setCandidateDetails({ ...candidateDetails, candidateSellingNotes: sellingnotes }),
      );
      yield put(
        globalActions.setSnackBar({
          message: 'Selling Notes template have been added successfully',
          severity: 'success',
        }),
      );
    }
  } catch (err) {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  }
}

/**
 * Method to get majors option list
 */
export function* fetchMajorsList() {
  try {
    const masterCopyDropDowns = yield select(selectMasterCopyData);
    const majorListResponse = yield call(() => getSearchFilterOptions('majors'));
    const transformedMajor = parseDropDown(majorListResponse);
    if (majorListResponse?.length) {
      yield put(candidateDetailActions.setMasterCopyDropDowns({ ...masterCopyDropDowns, majors: transformedMajor }));
    }
  } catch (err) {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  }
}

/**
 * Method to get degree option list
 */
export function* fetchDegreeList() {
  try {
    const masterCopyDropDowns = yield select(selectMasterCopyData);
    const degreeResponse = yield call(() => getSearchFilterOptions('degrees'));
    const transformedDegrees = parseDropDown(degreeResponse);
    if (degreeResponse?.length) {
      yield put(candidateDetailActions.setMasterCopyDropDowns({ ...masterCopyDropDowns, degrees: transformedDegrees }));
    }
  } catch (err) {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  }
}

/**
 * Method to get discipline option list
 */
export function* fetchDisciplineList() {
  try {
    const masterCopyDropDowns = yield select(selectMasterCopyData);
    const disciplineResponse = yield call(() => GetDisciplineOptions());
    if (disciplineResponse?.length) {
      yield put(
        candidateDetailActions.setMasterCopyDropDowns({ ...masterCopyDropDowns, disciplines: disciplineResponse }),
      );
    }
  } catch (err) {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  }
}

/**
 * Method to get Specialty option list
 */
export function* fetchSpecialty() {
  try {
    const masterCopyDropDowns = yield select(selectMasterCopyData);
    const specialtyResponse = yield call(() => GetSpecialtyOptions());
    if (specialtyResponse?.length) {
      yield put(
        candidateDetailActions.setMasterCopyDropDowns({ ...masterCopyDropDowns, specialties: specialtyResponse }),
      );
    }
  } catch (err) {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  }
}

/**
 * Method to get TravelCompany option list
 */
export function* fetchTravelCompany() {
  try {
    const masterCopyDropDowns = yield select(selectMasterCopyData);
    const travelCompanyResponse = yield call(() => getTravelerCompanyList());
    if (travelCompanyResponse?.length) {
      yield put(
        candidateDetailActions.setMasterCopyDropDowns({ ...masterCopyDropDowns, brands: travelCompanyResponse?.data }),
      );
    }
  } catch (err) {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  }
}

export function* fetchDipsSpecialtyMap() {
  try {
    const masterCopyDropDowns = yield select(selectMasterCopyData);
    const disciplineSpecialtyResponse = yield call(() => getDisciplineSpecialtyData());
    if (disciplineSpecialtyResponse?.length) {
      yield put(
        candidateDetailActions.setMasterCopyDropDowns({
          ...masterCopyDropDowns,
          discSpecMapping: disciplineSpecialtyResponse?.data.disciplineSpecialties,
        }),
      );
    }
  } catch (err) {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  }
}

/**
 *  Method to watch save request for race/ethnicity
 * @param action
 */
export function* postRaceEthnicityData(action: PayloadAction<ITypeAheadOption>) {
  try {
    const user = yield select(selectUser);
    const candidateDetails = yield select(candidateDetailsSelection);
    const selectedEthnicityItem: ITypeAheadOption = action.payload;
    const selectedEthnicityId = selectedEthnicityItem?.object.ID;
    // Post request body for personal info
    const mappedPersonalInfoRequest: ICandidateHeaderDetails = {
      candidateId: candidateDetails?.travelerId,
      brandId: candidateDetails?.brandId,
      firstName: candidateDetails?.name.first || '',
      lastName: candidateDetails?.name.last || '',
      middleName: candidateDetails?.name.middle,
      goesBy: candidateDetails.goesBy,
      phoneNumbers: candidateDetails.phoneNumbers,
      primaryEmail: candidateDetails.primaryEmail,
      secondaryEmail: candidateDetails?.secondaryEmail?.length > 0 ? candidateDetails?.secondaryEmail : null,
      currentEmployeeId: user.userInfo?.employeeId || 0,
      currentEmployeeName: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
      timestamp: candidateDetails?.amieTravelerTimestamp as string,
      ethnicityId: selectedEthnicityId,
      gender: getGender(candidateDetails.gender),
      ethnicityDescription: selectedEthnicityItem?.object.Description,
      emergencyContactInfo: candidateDetails.emergencyContact,
    };
    const response = yield call(TrackRaceEthnicity, updateCandidatePersonalInfo, mappedPersonalInfoRequest);
    if (response) {
      yield put(
        candidateDetailActions.setCandidateDetails({
          ...candidateDetails,
          raceEthnicity: selectedEthnicityItem?.object,
          amieTravelerTimestamp: response.timeStamp,
          ethnicityId: selectedEthnicityId,
        }),
      );
    }
    yield put(candidateDetailActions.setRaceEthnicity(selectedEthnicityItem?.object));
  } catch (err) {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  }
}

export function* postGenderData(action: PayloadAction<ITypeAheadOption>) {
  const saveStatus = yield select(candidateSaveStatusSelector);
  try {
    const user = yield select(selectUser);
    const candidateDetails = yield select(candidateDetailsSelection);
    const selectedGenderItem: ITypeAheadOption = action.payload;
    const selectedGender = selectedGenderItem?.object.Description;

    const replaceYesOrNo = (value: boolean | string) => {
      switch (value) {
        case true:
          return 'Y';
        case false:
          return 'N';
        case 'Y':
          return 'Y';
        case 'N':
          return 'N';
        default:
          return '';
      }
    };
    const latestCandidateDetail = yield call(() =>
      getCandidateById(candidateDetails?.travelerId, candidateDetails?.brandId),
    );
    // Post request body for personal info
    const mappedPersonalInfoRequest: ICandidateHeaderDetails = {
      candidateId: candidateDetails?.travelerId,
      brandId: candidateDetails?.brandId,
      firstName: candidateDetails?.name.first || '',
      lastName: candidateDetails?.name.last || '',
      middleName: candidateDetails?.name.middle,
      goesBy: candidateDetails.goesBy,
      phoneNumbers: candidateDetails.phoneNumbers,
      primaryEmail: candidateDetails.primaryEmail,
      secondaryEmail: candidateDetails?.secondaryEmail?.length > 0 ? candidateDetails?.secondaryEmail : null,
      currentEmployeeId: user.userInfo?.employeeId || 0,
      currentEmployeeName: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
      timestamp:
        latestCandidateDetail && latestCandidateDetail.amieTravelerTimestamp
          ? (latestCandidateDetail.amieTravelerTimestamp as string)
          : (candidateDetails?.amieTravelerTimestamp as string),
      gender: selectedGender,
      ethnicityDescription: candidateDetails.ethnicityDescription,
      emergencyContactInfo: candidateDetails.emergencyContact?.phone != null ? candidateDetails.emergencyContact : null,
      applicationQuestions: {
        licenseInvestigationSuspension: replaceYesOrNo(candidateDetails?.investigationSuspension),
        investigationSuspensionExplanation: candidateDetails?.investigationSuspensionExplanation || '',
        usRightToWorkVerification: replaceYesOrNo(candidateDetails?.rightToWorkVerification),
        visaType: candidateDetails?.visaExplaination || '',
      },
    };
    yield put(candidateDetailActions.setCandidateSaveStatus({ ...saveStatus, genderSaveStatus: APIStatus.isLoading }));
    const response = yield call(TrackGender, updateCandidatePersonalInfo, mappedPersonalInfoRequest);
    if (response) {
      yield put(
        candidateDetailActions.setCandidateDetails({
          ...candidateDetails,
          gender: selectedGender,
          amieTravelerTimestamp: response.timeStamp,
        }),
      );
      yield put(
        candidateDetailActions.setCandidateSaveStatus({ ...saveStatus, genderSaveStatus: APIStatus.Completed }),
      );
      yield put(candidateDetailActions.setGender(selectedGender));
    } else {
      yield put(candidateDetailActions.setCandidateSaveStatus({ ...saveStatus, genderSaveStatus: APIStatus.Error }));
    }
  } catch (err) {
    yield put(candidateDetailActions.setCandidateSaveStatus({ ...saveStatus, genderSaveStatus: APIStatus.Error }));
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  } finally {
    yield call(delay, 4000);
    yield put(candidateDetailActions.setCandidateSaveStatus({ ...saveStatus, genderSaveStatus: undefined }));
  }
}

/**
 * Method to get the race/ethnicity data
 */
export function* getRaceEthnicityData() {
  try {
    const raceEthnicityResponse: [{ name: string; value: string }] = yield call(() => getEthnicityLookups());
    const raceEthnicityDropDown = raceEthnicityResponse.map(ethnicityItem => ({
      ID: ethnicityItem.value,
      Description: ethnicityItem.name,
    }));
    const raceEthnicityOptions: ITypeAheadOption[] = formatTypeAheadOptions(raceEthnicityDropDown);
    if (raceEthnicityOptions?.length) {
      yield put(candidateDetailActions.setRaceEthnicityOptions(raceEthnicityOptions));
    }
  } catch (err) {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  }
}

export function* getGenderOptionsData() {
  try {
    const genderOptionsResponse: [{ name: string; value: string }] = yield call(() => getSearchLookups('gender', ''));
    const genderDropDown = genderOptionsResponse.map(genderItem => ({
      ID: genderItem.value,
      Description: genderItem.name,
    }));
    const genderOptions: ITypeAheadOption[] = formatTypeAheadOptions(genderDropDown);
    if (genderOptions?.length) {
      yield put(candidateDetailActions.setGenderOptions(genderOptions));
    }
  } catch (err) {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  }
}

export function* postCandidateReferenceData(action: PayloadAction<any>) {
  try {
    const data: IReferenceData = action.payload?.requestBody;
    const candidateDetailsSel = yield select(candidateDetailsSelection);
    const response = yield call(TrackProfileSummaryDetails, postCandidateReference, data);
    if (response && httpSuccess(response?.status)) {
      const experience = _.cloneDeep(candidateDetailsSel?.experience?.employment)?.filter(
        item => item.workHistoryId === data.workHistoryID,
      );
      const referenceList = _.cloneDeep(candidateDetailsSel?.references?.items);
      const referenceDataList = _.cloneDeep(candidateDetailsSel?.referenceData?.items);
      const newReference = {
        ageCompetency: [],
        amhTitle: null,
        amhTitleID: null,
        averageRating: null,
        avgPatientCaseLoad: null,
        bestTimeToReach: data.bestTimeToReach,
        bestTimeToReachID: data.bestTimeToReachID,
        changedByUser: data.currentEmployeeName,
        changedByUserID: data.currentEmployeeId,
        changedDate: convertToPST(new Date(), 'MM/DD/YYYY'),
        chargeExperience: null,
        comment: data.comments,
        contactPreferenceType: data.contactPreferenceType,
        contactPreferenceTypeID: data.contactPreferenceTypeID,
        eligibleForRehire: null,
        email: data.email,
        experienceEmployment: experience,
        explanation: null,
        imageID: null,
        insertedByUser: data.currentEmployeeName,
        insertedByUserID: data.currentEmployeeId,
        mayShareWithTraveler: null,
        notes: null,
        numberOfBeds: null,
        phoneNumbers: data.phoneNumbers,
        positionHeld: null,
        referenceComplete: false,
        referenceDate: data.referenceDate,
        referenceEvaluation: [],
        referenceType: data.referenceType,
        referenceTypeID: data.referenceTypeID,
        sharedWithTraveler: null,
        shiftWorked: null,
        status: 'New',
        statusID: 1,
        supervisorName: data.supervisorName,
        timestamp: null,
        title: data.title,
        travelerID: data.candidateId,
        travelerReferenceID: null,
        unitDescription: null,
        unitName: null,
        unitSpecialty: null,
        workHistoryID: data.workHistoryID,
      };
      referenceList.push(newReference);
      referenceDataList.push(newReference);
      yield put(
        candidateDetailActions.setCandidateDetails({
          ...candidateDetailsSel,
          referenceData: {
            ...candidateDetailsSel?.referenceData,
            items: referenceDataList,
          },
          references: {
            ...candidateDetailsSel?.references,
            items: referenceList,
          },
        }),
      );
      yield put(
        globalActions.setSnackBar({
          message: `Reference has been added successfully.`,
          severity: 'success',
        }),
      );
    } else {
      yield put(
        globalActions.setSnackBar({
          message: `Oops, something went wrong. Please try again`,
          severity: 'error',
        }),
      );
    }
  } catch (e) {}
}

/**
 * Method to post candidate personal information
 */
export function* postCandidatePersonalInformation(action: PayloadAction<any>) {
  try {
    const requestBody = action.payload?.requestBody;
    const formData = action.payload?.formData;
    let candidateDetailsSel = yield select(candidateDetailsSelection);
    const user = yield select(selectUser);
    const candidateId = requestBody?.candidateId;
    const brandId = requestBody?.brandId;
    let latestCandidateDetail = yield call(() => getCandidateById(candidateId, brandId));

    const response = yield call(TrackProfileSummaryDetails, postCandidatePersonalInfo, {
      ...requestBody,
      timeStamp: latestCandidateDetail?.amieTravelerTimestamp?.toString() || requestBody?.timeStamp,
    });

    if (response && response?.data.name && httpSuccess(response?.status) && isPositiveNumber(candidateId)) {
      candidateDetailsSel = yield select(candidateDetailsSelection);
      yield put(
        candidateDetailActions.setCandidateDetails({
          ...candidateDetailsSel,
          name: {
            prefix: response?.data.name?.prefix,
            first: response?.data.name?.first,
            middle: response?.data.name?.middle,
            last: response?.data.name?.last,
          },
          goesBy: response?.data.goesBy,
          phoneNumbers: response?.data.phoneNumbers,
          primaryEmail: response?.data.primaryEmail,
          secondaryEmail: response?.data.secondaryEmail,
          amieTravelerTimestamp: response?.data.timeStamp,
          ethnicityId: response?.data?.ethnicityId,
          gender: response?.data?.gender,
          investigationSuspension: requestBody.applicationQuestions?.licenseInvestigationSuspension,
          investigationSuspensionExplanation: requestBody.applicationQuestions?.investigationSuspensionExplanation,
          rightToWorkVerification: requestBody.applicationQuestions?.usRightToWorkVerification,
          visaExplaination: requestBody.applicationQuestions?.visaType,
          emergencyContact: {
            name: requestBody?.emergencyContactInfo.name,
            relationship: requestBody?.emergencyContactInfo?.relationship,
            phone: requestBody?.emergencyContactInfo?.phone,
            extensionNotes: requestBody?.emergencyContactInfo?.extensionNotes,
            countryDialingCode: requestBody?.emergencyContactInfo?.countryDialingCode,
          },
        }),
      );

      const secondRes = processSsnAndDOB(
        { ...candidateDetailsSel, amieTravelerTimestamp: response?.data.timeStamp },
        formData,
        user?.userInfo,
      );

      if (
        isPositiveNumber(candidateId) &&
        (formData?.piSSN?.replaceAll('-', '') !== candidateDetailsSel.socialSecurityNumber?.replaceAll('-', '') ||
          formData?.piDOB !== candidateDetailsSel.dateOfBirth ||
          formData?.piNUID !== candidateDetailsSel.nuid)
      ) {
        const ssnRegex = /\d\d\d-\d\d-\d\d\d\d/i;
        const isNewSsnValid = ssnRegex.test(secondRes?.ssn);

        if (
          isNewSsnValid &&
          formData?.piSSN?.replaceAll('-', '') !== candidateDetailsSel?.socialSecurityNumber?.replaceAll('-', '')
        ) {
          const ssnResponse = yield call(() => getSSNValueByTravelerId(candidateId));
          const candidateSSN: ICandidateSSN = {
            candidateId: candidateId,
            brandId: brandId,
            currentEmployeeId: user.userInfo.employeeId,
            currentEmployeeName: user.userInfo.ntUserName,
            ssn: secondRes.ssn ?? candidateDetailsSel?.socialSecurityNumber,
            timestamp: ssnResponse?.timestamp,
            ignoreTimestamp: false,
          };

          const secondSsnResult = yield call(() => updateCandidateSSN(candidateSSN));

          if (secondSsnResult === undefined || secondSsnResult === null) {
            yield put(
              globalActions.setSnackBar({
                message: `Oops, something went wrong. Please try again`,
                severity: 'error',
              }),
            );
          }
        }

        if (formData?.piDOB !== candidateDetailsSel?.dateOfBirth) {
          const ssnResponse = yield call(() => getSSNValueByTravelerId(candidateId));

          const candidateDOB: ICandidateDateOfBirth = {
            candidateId: candidateId,
            currentEmployeeId: user.userInfo?.employeeId || 0,
            currentEmployeeName: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
            dob: secondRes.dob ?? candidateDetailsSel?.dateOfBirth,
            timestamp: ssnResponse?.timestamp,
            ignoreTimestamp: false,
          };
          candidateDetailsSel = yield select(candidateDetailsSelection);
          const secondDobResult = yield call(() => updateCandidateDOB(candidateDOB));

          if (secondDobResult === undefined || secondDobResult === null) {
            yield put(
              globalActions.setSnackBar({
                message: `Oops, something went wrong. Please try again`,
                severity: 'error',
              }),
            );
          }
        }

        if (formData?.piNUID !== candidateDetailsSel?.candidateNUID) {
          const candidateNUID: ICandidateNUID = {
            travelerId: candidateId,
            nuid: secondRes.nuid ?? candidateDetailsSel.nuid,
          };
          candidateDetailsSel = yield select(candidateDetailsSelection);

          const secondNUIDResult = yield call(() => updateCandidateNUID(candidateNUID));

          if (secondNUIDResult === undefined || secondNUIDResult === null) {
            yield put(
              globalActions.setSnackBar({
                message: `Oops, something went wrong. Please try again`,
                severity: 'error',
              }),
            );
          }
        }

        latestCandidateDetail = yield call(() => getCandidateById(candidateId, brandId));
        candidateDetailsSel = yield select(candidateDetailsSelection);
        yield put(
          candidateDetailActions.setCandidateDetails({
            ...candidateDetailsSel,
            name: {
              prefix: response?.data.name?.prefix,
              first: response?.data.name?.first,
              middle: response?.data.name?.middle,
              last: response?.data.name?.last,
            },
            goesBy: response?.data.goesBy,
            nuid: formData['piNUID'] || candidateDetailsSel?.nuid,
            phoneNumbers: response?.data.phoneNumbers,
            primaryEmail: response?.data.primaryEmail,
            secondaryEmail: response?.data.secondaryEmail,
            ethnicityId: response?.data?.ethnicityId,
            gender: response?.data?.gender,
            amieTravelerTimestamp: latestCandidateDetail?.amieTravelerTimestamp,
            dateOfBirth: formData['piDOB'] || candidateDetailsSel?.dateOfBirth,
            socialSecurityNumber:
              formData['piSSN'] && !formData['piSSN'].includes('*') && isNewSsnValid
                ? processSsn(formData['piSSN'])
                : candidateDetailsSel?.socialSecurityNumber,
            emergencyContact: {
              name: requestBody?.emergencyContactInfo.name,
              relationship: requestBody?.emergencyContactInfo?.relationship,
              phone: requestBody?.emergencyContactInfo?.phone,
              extensionNotes: requestBody?.emergencyContactInfo?.extensionNotes,
              countryDialingCode: requestBody?.emergencyContactInfo?.countryDialingCode,
            },
            investigationSuspension: requestBody.applicationQuestions?.licenseInvestigationSuspension,
            investigationSuspensionExplanation: requestBody.applicationQuestions?.investigationSuspensionExplanation,
            rightToWorkVerification: requestBody.applicationQuestions?.usRightToWorkVerification,
            visaExplaination: requestBody.applicationQuestions?.visaType,
          }),
        );
        yield put(
          globalActions.setSnackBar({
            message: i18next.t('candidate.successChanges'),
            severity: 'success',
          }),
        );
        action.payload?.onClose?.();
      } else {
        yield put(
          candidateDetailActions.setEmergencyContact({
            name: requestBody?.emergencyContactInfo.name,
            relationship: requestBody?.emergencyContactInfo?.relationship,
            phone: requestBody?.emergencyContactInfo?.phone,
            extensionNotes: requestBody?.emergencyContactInfo?.extensionNotes,
            countryDialingCode: requestBody?.emergencyContactInfo?.countryDialingCode,
          }),
        );
      }
    } else if (response?.status === 409) {
      yield put(
        globalActions.setSnackBar({
          message: response.data,
          severity: 'error',
        }),
      );
    } else {
      yield put(
        globalActions.setSnackBar({
          message: `Oops, something went wrong. Please try again`,
          severity: 'error',
        }),
      );
    }
  } catch (e) {}
}

export function* deleteEducationRecord(action: PayloadAction<any>) {
  try {
    const user = yield select(selectUser);
    const candidateDetails = yield select(candidateDetailsSelection);
    const educationDetails = action.payload?.requestBody;
    const mappedDeleteEducationRequest: any = {
      candidateId: candidateDetails?.travelerId,
      currentEmployeeId: user.userInfo?.employeeId || 0,
      currentEmployeeName: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
      ...educationDetails,
    };
    const response = yield call(deleteCandidateEducation, mappedDeleteEducationRequest);
    if (response) {
      yield put(
        candidateDetailActions.setCandidateDetails({
          ...candidateDetails,
          experience: {
            ...candidateDetails.experience,
            education: candidateDetails.experience.education.filter(
              item => item.schoolId !== educationDetails.schoolId,
            ),
          },
        }),
      );
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('candidate.educationCard.deleteSuccessEducation'),
          severity: 'success',
        }),
      );
    }
  } catch (err) {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  }
}

/**
 * Method to get tags list
 */
export function* fetchTagsList(action: PayloadAction<{ travelerId?: any; brandId?: any }>) {
  const travelerId = action.payload.travelerId;
  const brandId = action.payload.brandId;
  if (isPositiveNumber(travelerId) && isPositiveNumber(brandId)) {
    const response = yield call(() => getTagsListByCandidateId(travelerId, brandId));
    const { data } = response;
    yield put(candidateDetailActions.setTagsList(data));
  }
}

export function* updateCandidateSSNTask(action: PayloadAction<ICandidateSSN>) {
  const candidateDetails = yield select(candidateDetailsSelection);
  const saveStatus = yield select(candidateSaveStatusSelector);

  try {
    if (isPositiveNumber(action.payload.candidateId)) {
      yield put(candidateDetailActions.setCandidateSaveStatus({ ...saveStatus, ssnSaveStatus: SaveStatus.InProgress }));
      const latestSSNDetails = yield call(() => getSSNValueByTravelerId(action.payload.candidateId));

      yield call(() =>
        updateCandidateSSN({
          ...action.payload,
          timestamp: latestSSNDetails?.timestamp?.toString() || action.payload?.timestamp,
        }),
      );
      const ssnResponse = yield call(() => getSSNValueByTravelerId(action.payload.candidateId));
      yield put(
        candidateDetailActions.setCandidateDetails({
          ...candidateDetails,
          ssnTimestamp: ssnResponse.timestamp,
          socialSecurityNumber: ssnResponse.ssn,
        }),
      );
      yield put(candidateDetailActions.setCandidateSaveStatus({ ...saveStatus, ssnSaveStatus: SaveStatus.Success }));
    }
  } catch (error) {
    yield put(candidateDetailActions.setCandidateSaveStatus({ ...saveStatus, ssnSaveStatus: SaveStatus.Failure }));
  } finally {
    yield call(delay, 4000);
    yield put(candidateDetailActions.setCandidateSaveStatus({ ...saveStatus, ssnSaveStatus: SaveStatus.Undefined }));
  }
}

const delay = (time: number) => new Promise(resolve => setTimeout(resolve, time));

export function* updateCandidateDateOfBirthTask(action: PayloadAction<ICandidateDateOfBirth>) {
  const candidateDetails = yield select(candidateDetailsSelection);
  const saveStatus = yield select(candidateSaveStatusSelector);

  try {
    if (isPositiveNumber(action.payload.candidateId)) {
      yield put(candidateDetailActions.setCandidateSaveStatus({ ...saveStatus, dobSaveStatus: SaveStatus.InProgress }));
      const latestSSNDetails = yield call(() => getSSNValueByTravelerId(action.payload.candidateId));

      yield call(() =>
        updateCandidateDOB({
          ...action.payload,
          timestamp: latestSSNDetails?.timestamp?.toString() || action.payload?.timestamp,
        }),
      );

      const ssnResponse = yield call(() => getSSNValueByTravelerId(action.payload.candidateId));
      yield put(
        candidateDetailActions.setCandidateDetails({
          ...candidateDetails,
          ssnTimestamp: ssnResponse.timestamp,
          dateOfBirth: action.payload.dob || '',
        }),
      );
      yield put(candidateDetailActions.setCandidateSaveStatus({ ...saveStatus, dobSaveStatus: SaveStatus.Success }));
    }
  } catch (error) {
    yield put(candidateDetailActions.setCandidateSaveStatus({ ...saveStatus, dobSaveStatus: SaveStatus.Failure }));
  } finally {
    yield call(delay, 4000);
    yield put(candidateDetailActions.setCandidateSaveStatus({ ...saveStatus, dobSaveStatus: SaveStatus.Undefined }));
  }
}

/**
 * Method to post tags
 */
export function* postTagsData(action: PayloadAction<IPostTags>) {
  const response = yield call(() => postTagsList(action.payload));
  if (httpSuccess(response.status)) {
    yield put(candidateDetailActions.setTagsList(action.payload.candidateTags));
  }
}

export function* getPlacementSkillsetExperienceData(
  action: PayloadAction<{ placementId?: any; placementOrderType?: any }>,
) {
  const placementService: PlacementService = yield getContext('placementService');
  const placementId = action.payload.placementId;
  const placementOrderType = action.payload.placementOrderType;
  if (placementId && placementOrderType) {
    const response = yield call(() =>
      placementService.getPlacementSummaryExperience_Req(placementId, placementOrderType),
    );
    const { data } = response;
    yield put(candidateDetailActions.setPlacementSkillsetExperience(data));
  }
}

export function* getAllTaxData(action: PayloadAction<{ travelerId?: string }>) {
  const travelerId = action.payload.travelerId;
  if (travelerId) {
    const response = yield call(() => getTaxData(travelerId));
    const { data } = response;
    yield put(candidateDetailActions.setTaxData(data));
  }
}

/**
 * Method to post reference request data
 */
export function* postReferenceRequestData(action: PayloadAction<IReferenceRequest>) {
  const response = yield call(() => postReferenceRequest(action.payload));
  yield put(candidateDetailActions.setRequestReferenceSaveStatus(response));
  const referenceGrid = yield select(referenceGridSelector);
  if (httpSuccess(response.status)) {
    yield put(
      candidateDetailActions.setReferenceGrid([
        ...referenceGrid,
        { ...action.payload, referenceRequestStatusID: 6, id: referenceGrid?.length },
      ]),
    );
    yield put(
      globalActions.setSnackBar({
        message: 'Reference request has been added successfully',
        severity: 'success',
      }),
    );
  }
}

export function* postAuditData(action: PayloadAction<{ reqBody: IAuditPostData; auditDetails: IAuditItems }>) {
  const response = yield call(() => postCandidateAudit(action.payload.reqBody));
  if (httpSuccess(response.status)) {
    const candidateDetails = yield select(candidateDetailsSelection);
    let auditItems = candidateDetails?.auditData?.items;
    const newAudit = { ...action.payload?.auditDetails, auditId: response.data };
    const requestedAuditId = action.payload.reqBody.auditId;
    if (isPositiveNumber(requestedAuditId)) {
      auditItems = auditItems.map(item => {
        if (item.auditId === requestedAuditId) {
          return {
            ...action.payload.auditDetails,
          };
        } else {
          return item;
        }
      });
    } else {
      auditItems = [...auditItems, newAudit];
    }

    yield put(
      candidateDetailActions.setAuditData({
        items: auditItems,
        totals: {
          fyionly:
            newAudit.status === 'FYI only' && !isPositiveNumber(requestedAuditId)
              ? candidateDetails?.auditData?.totals.fyionly + 1
              : candidateDetails?.auditData?.totals.fyionly,
          holdFileOut:
            newAudit.holdFileOut && !isPositiveNumber(requestedAuditId)
              ? candidateDetails?.auditData?.totals.holdFileOut + 1
              : candidateDetails?.auditData?.totals.holdFileOut,
          ongoing:
            newAudit.status === 'Ongoing' && !isPositiveNumber(requestedAuditId)
              ? candidateDetails?.auditData?.totals.ongoing + 1
              : candidateDetails?.auditData?.totals.ongoing,
          total: !isPositiveNumber(requestedAuditId)
            ? candidateDetails?.auditData?.totals.total + 1
            : candidateDetails?.auditData?.totals.total + 1,
        },
      }),
    );
    yield put(
      globalActions.setSnackBar({
        message: isPositiveNumber(requestedAuditId)
          ? 'Audit data has been updated successfully'
          : 'Audit added successfully',
        severity: 'success',
      }),
    );
  }
}

export function* deleteCandidateAuditData(action: PayloadAction<IDeleteAudit>) {
  try {
    if (isPositiveNumber(action.payload.travelerId)) {
      const candidateDetails = yield select(candidateDetailsSelection);

      const response = yield call(deleteCandidateAudit, action.payload);

      if (response.status) {
        const existingAudits = _.cloneDeep(candidateDetails?.auditData?.items);
        const deletedAudit = existingAudits?.filter(item => item.auditId === action.payload.auditId);

        const filtered = existingAudits?.filter(item => item.auditId !== action.payload.auditId);

        const requestedAuditId = action.payload.auditId;
        yield put(
          globalActions.setSnackBar({
            message: 'Audit has been deleted successfully',
            severity: 'success',
          }),
        );
        yield put(
          candidateDetailActions.setAuditData({
            items: filtered,
            totals: {
              fyionly:
                deletedAudit[0].status === 'FYI only' && isPositiveNumber(requestedAuditId)
                  ? candidateDetails?.auditData?.totals?.fyionly - 1
                  : candidateDetails?.auditData?.totals.fyionly,
              holdFileOut:
                deletedAudit[0].holdFileOut && isPositiveNumber(requestedAuditId)
                  ? candidateDetails?.auditData?.totals?.holdFileOut - 1
                  : candidateDetails?.auditData?.totals.holdFileOut,
              ongoing:
                deletedAudit[0].status === 'Ongoing' && isPositiveNumber(requestedAuditId)
                  ? candidateDetails?.auditData?.totals?.ongoing - 1
                  : candidateDetails?.auditData?.totals.ongoing,
              total: isPositiveNumber(requestedAuditId)
                ? candidateDetails?.auditData?.totals.total - 1
                : candidateDetails?.auditData?.totals.total - 1,
            },
          }),
        );
      } else {
        yield put(
          globalActions.setSnackBar({
            message: i18next.t('common.failedLoad'),
            severity: 'error',
          }),
        );
      }
    }
  } catch (err) {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  }
}

/**
 * Method to fetch request time off data
 */
export function* fetchRequestTimeOff() {
  const candidateDetails = yield select(candidateDetailsSelection);
  const response = yield call(() => getTimeOffRequest(candidateDetails?.travelerId, candidateDetails?.brandId));
  if (httpSuccess(response.status)) {
    const formattedRequestTimeOff = formatRequestedTimeOffList(response?.data.length ? response?.data : []);
    yield put(candidateDetailActions.setTimeOffRequest(formattedRequestTimeOff));
  }
}

/**
 * Method to post request time off data
 */
export function* postRequestTimeOffData(action: PayloadAction<IPostRequestTimeOff>) {
  if (isPositiveNumber(action.payload?.candidateId)) {
    const response = yield call(TrackProfileTimeOffRequest, postTimeOffRequest, action.payload);
    if (httpSuccess(response.status)) {
      yield put(
        globalActions.setSnackBar({
          message: 'Requested time off updated successfully',
          severity: 'success',
        }),
      );
      const response = yield call(() => getTimeOffRequest(action.payload?.candidateId, action.payload?.brandId));
      if (httpSuccess(response.status)) {
        const formattedRequestTimeOff = formatRequestedTimeOffList(response?.data.length ? response?.data : []);
        yield put(candidateDetailActions.setTimeOffRequest(formattedRequestTimeOff));
      }
    }
  }
}

export function* fetchSupervisorData() {
  const candidateDetails = yield select(candidateDetailsSelection);

  const response = yield call(() => getSupervisorData(candidateDetails?.travelerId));
  if (httpSuccess(response.status)) {
    yield put(
      candidateDetailActions.setSupervisor({
        ...response?.data,
        caseLoad: response?.data.maxNumber - response?.data.available,
      }),
    );
  } else {
    yield put(
      candidateDetailActions.setSupervisor({
        availabilityDate: '',
        interested: false,
        maxNumber: 0,
        available: 0,
        skillsets: [],
        caseLoad: 0,
      }),
    );
  }
}

export function* fetchTaxLetterInfo(action: PayloadAction<{ travelerId?: string }>) {
  const travelerId = action.payload.travelerId;
  try {
    if (isPositiveNumber(travelerId)) {
      const response = yield call(() => getPermanentTaxLetterData(travelerId));
      const { data } = response;
      yield put(candidateDetailActions.setTaxLetterInfo(data));
    }
  } catch (err) {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  }
}

export function* getCandidatePreferences(action: PayloadAction<{ travelerId?: string }>) {
  const travelerId = action.payload.travelerId;
  try {
    if (isPositiveNumber(travelerId)) {
      const response = yield call(() => getPreferencesData(travelerId));
      const { data } = response;
      yield put(candidateDetailActions.setPreferences(data));
    }
  } catch (err) {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  }
}

export function* updateSupervisorData(
  action: PayloadAction<{ reqBody: IPostSupervisorData; skillsets: ISupervisorSkillsets[] }>,
) {
  try {
    if (isPositiveNumber(action.payload?.reqBody?.travelerId)) {
      const candidateDetails = yield select(candidateDetailsSelection);
      const mapSupervisorData: IPostSupervisorData = {
        travelerId: candidateDetails?.travelerId,
        interested: action.payload?.reqBody.interested,
        availabilityDate: action.payload?.reqBody.availabilityDate || null,
        maxNumber: action.payload?.reqBody.maxNumber || null,
        skillsets: action.payload?.reqBody.skillsets || [],
        currentEmployeeId: action.payload?.reqBody.currentEmployeeId,
        currentEmployeeName: action.payload?.reqBody.currentEmployeeName,
      };
      const response = yield call(postSupervisorData, mapSupervisorData);
      if (httpSuccess(response.status)) {
        yield put(
          globalActions.setSnackBar({
            message: i18next.t('candidate.successChanges'),
            severity: 'success',
          }),
        );
        const secondData: ISupervisorData = {
          interested: action.payload?.reqBody.interested,
          availabilityDate: action.payload?.reqBody.availabilityDate || null,
          maxNumber: action.payload?.reqBody.maxNumber || null,
          skillsets: action.payload?.skillsets?.map((item, index) => {
            if (item.travelerSupervisorSkillsetID) {
              return item;
            } else {
              return {
                ...item,
                travelerSupervisorSkillsetID: response.data?.skillsets?.at(index)?.travelerSupervisorSkillsetID,
                travelerSupervisorSkillsetId: response.data?.skillsets?.at(index)?.travelerSupervisorSkillsetID,
              };
            }
          }),
          available: action.payload?.reqBody.maxNumber || null,
          caseLoad: candidateDetails.supervisor?.caseLoad,
        };
        yield put(candidateDetailActions.setSupervisor(secondData));
      } else {
        yield put(
          globalActions.setSnackBar({
            message: i18next.t('common.failedLoad'),
            severity: 'error',
          }),
        );
      }
    }
  } catch (err) {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  }
}

export function* deleteSupervisorSkillsetData(action: PayloadAction<IDeleteSupervisorSkillsetData>) {
  try {
    if (isPositiveNumber(action.payload.candidateId)) {
      const payload: IDeleteSupervisorSkillsets = {
        candidateId: action.payload.candidateId,
        travelerSupervisorSkillsetIds: action.payload.travelerSupervisorSkillsetIds,
        currentEmployeeId: action.payload.currentEmployeeId,
        currentEmployeeName: action.payload.currentEmployeeName,
      };
      const response = yield call(deleteSupervisorSkillSet, payload);

      if (response.status) {
        const candidateDetails = yield select(candidateDetailsSelection);
        const supervisorData = _.cloneDeep(candidateDetails?.supervisor);
        const skillsets = action.payload.skillsets?.filter(
          item => !action.payload.travelerSupervisorSkillsetIds.includes(item.travelerSupervisorSkillsetID),
        );
        supervisorData.skillsets = skillsets;
        yield put(
          globalActions.setSnackBar({
            message: i18next.t('candidate.supervisorCard.deleteSuccessSupervisorSkillset'),
            severity: 'success',
          }),
        );
        yield put(candidateDetailActions.setSupervisor(supervisorData));
      } else {
        yield put(
          globalActions.setSnackBar({
            message: i18next.t('common.failedLoad'),
            severity: 'error',
          }),
        );
      }
    }
  } catch (err) {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  }
}

export function* postUnverifiedHearsayCertificationData(action: PayloadAction<IHearsayCertificationRequest>) {
  try {
    if (isPositiveNumber(action.payload?.candidateId)) {
      const candidateDetails = yield select(candidateDetailsSelection);
      const data = action.payload?.data;
      const response = yield call(
        postUnverifiedHearsayCertification,
        action.payload?.candidateId,
        action.payload?.brandId,
        data,
      );
      if (httpSuccess(response.status)) {
        yield put(
          globalActions.setSnackBar({
            message: 'Certificate added successfully',
            severity: 'success',
          }),
        );
        const existingCertifications = _.cloneDeep(candidateDetails?.hearsayCertifications);
        let certIndex = existingCertifications.findIndex(x => x.travelerHearsayCertificationId === response.data);

        if (certIndex > -1) {
          existingCertifications[certIndex].certificationName = data[0]?.name;
          existingCertifications[certIndex].expirationDate = data[0]?.expirationDate;
          existingCertifications[certIndex].id = data[0]?.value;
          existingCertifications[certIndex].issuedBy = data[0]?.issuedBy;
        } else {
          existingCertifications.push({
            certificationName: data[0]?.name,
            expirationDate: data[0]?.expirationDate,
            id: data[0]?.value,
            issuedBy: data[0]?.issuedBy,
            travelerHearsayCertificationId: response.data,
          });
        }
        yield put(candidateDetailActions.setHearsayCertification(existingCertifications));
      } else {
        yield put(
          globalActions.setSnackBar({
            message: i18next.t('common.failedLoad'),
            severity: 'error',
          }),
        );
      }
    }
  } catch (err) {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  }
}

export function* deleteUnverifiedHearsayCertificationData(action: PayloadAction<IDeleteHearsayCertification>) {
  try {
    if (isPositiveNumber(action.payload.travelerId)) {
      const candidateDetails = yield select(candidateDetailsSelection);
      const response = yield call(deleteUnverifiedHearsayCertification, action.payload);

      if (response.status) {
        const existingCertifications = _.cloneDeep(candidateDetails?.hearsayCertifications);
        const filtered = existingCertifications?.filter(
          item => item.travelerHearsayCertificationId !== action.payload.travelerHearsayCertificationId,
        );
        yield put(
          globalActions.setSnackBar({
            message: i18next.t('candidate.qualificationsCard.deleteCertificationSuccess'),
            severity: 'success',
          }),
        );
        yield put(candidateDetailActions.setHearsayCertification(filtered));
      } else {
        yield put(
          globalActions.setSnackBar({
            message: i18next.t('common.failedLoad'),
            severity: 'error',
          }),
        );
      }
    }
  } catch (err) {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  }
}

export function* postUnverifiedHearsayLicenseData(action: PayloadAction<IHearsayLicense>) {
  try {
    if (isPositiveNumber(action.payload.travelerId)) {
      const candidateDetails = yield select(candidateDetailsSelection);
      const response = yield call(postUnverifiedHearsayLicense, action.payload);

      if (httpSuccess(response.status)) {
        const existingLicenses = _.cloneDeep(candidateDetails?.licenses);
        let licenseIndex = existingLicenses.findIndex(x => x.travelerHearsayLicensureID === response.data);

        if (licenseIndex > -1) {
          yield put(
            globalActions.setSnackBar({
              message: i18next.t('candidate.qualificationsCard.updateLicenseSuccess'),
              severity: 'success',
            }),
          );
          existingLicenses[licenseIndex].state = action.payload.stateProvinceID;
          existingLicenses[licenseIndex].licenseNumber = action.payload.licenseNumber;
          existingLicenses[licenseIndex].expirationOn = action.payload.expirationDate;
          existingLicenses[licenseIndex].dateCompleted = action.payload.expirationDate;
        } else {
          yield put(
            globalActions.setSnackBar({
              message: i18next.t('candidate.qualificationsCard.addLicenseSuccess'),
              severity: 'success',
            }),
          );
          existingLicenses.push({
            type: action.payload.requirementType,
            state: action.payload.stateProvinceID,
            licenseNumber: action.payload.licenseNumber,
            expirationOn: action.payload.expirationDate,
            dateCompleted: action.payload.expirationDate,
            verified: false,
            hearsayLicense: true,
            travelerHearsayLicensureID: response.data,
          });
        }
        yield put(candidateDetailActions.setLicenses(existingLicenses));
      } else {
        yield put(
          globalActions.setSnackBar({
            message: i18next.t('common.failedLoad'),
            severity: 'error',
          }),
        );
      }
    }
  } catch (err) {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  }
}

export function* deleteUnverifiedHearsayLicenseData(action: PayloadAction<IDeleteHearsayLicense>) {
  try {
    if (isPositiveNumber(action.payload.travelerId)) {
      const candidateDetails = yield select(candidateDetailsSelection);
      const response = yield call(deleteUnverifiedHearsayLicense, action.payload);

      if (response.status) {
        const existingLicenses = _.cloneDeep(candidateDetails?.licenses);
        const filtered = existingLicenses?.filter(
          item => item.travelerHearsayLicensureID !== action.payload.travelerHearsayLicensureId,
        );
        yield put(
          globalActions.setSnackBar({
            message: i18next.t('candidate.qualificationsCard.deleteLicenseSuccess'),
            severity: 'success',
          }),
        );
        yield put(candidateDetailActions.setLicenses(filtered));
      } else {
        yield put(
          globalActions.setSnackBar({
            message: i18next.t('common.failedLoad'),
            severity: 'error',
          }),
        );
      }
    }
  } catch (err) {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  }
}

export function* updateCandidateAddressData(action: PayloadAction<any>) {
  const data: ICandidateAddressDetails = action.payload?.requestBody;
  const latestaddress = yield call(() => getCandidateAddress(data?.candidateId, data?.brandId));

  try {
    if (isPositiveNumber(data?.candidateId)) {      
      let addressData = _.cloneDeep(data);

      addressData?.addresses?.forEach((item: any, index) => {
        if (item.travelerAddressId) {
          const address = latestaddress?.find(x => x.travelerAddressId === item.travelerAddressId);

          if (address) {
            if (item.addressDates) {
              item?.addressDates?.forEach((date, i) => {
                if (date && date.addressDateRangeId) {
                  const newDate = address?.addressDates?.find(x => x.addressDateRangeId === date.addressDateRangeId);
                  date.timestamp = newDate && newDate.timestamp ? newDate?.timestamp : date.timestamp;
                }
              });
            }

            item.timeStamp = address && address.timeStamp ? address?.timeStamp : item.timeStamp;
          }
        }
      });

      const candidateDetailsSel = yield select(candidateDetailsSelection);
      const response = yield call(updateCandidateAddress, addressData);

      if (response && response.length > 0 && isPositiveNumber(candidateDetailsSel.travelerId)) {
        yield put(candidateDetailActions.setAddressData(response));
      } else {
        yield put(candidateDetailActions.setAddressData(latestaddress));
      }
    }
  } catch (err) {
    if (err?.response?.status === 409) {      
      yield put(
        globalActions.setSnackBar({
          message: err?.response?.data,
          severity: 'error',
        }),
      );
      yield call(delay, 4000);
      yield put(candidateDetailActions.setAddressData(latestaddress));      
    }
  }
}

/**
 * Method to delete work experience record
 */
export function* deleteCandidateWorkExperience(
  action: PayloadAction<{ workHistoryRecordId: number; workExperienceList; requestBody }>,
) {
  const candidateDetails = yield select(candidateDetailsSelection);
  const user = yield select(selectUser);
  const workExperienceDetails = action.payload?.requestBody;
  const filteredWorkExp = action.payload?.workExperienceList.filter(
    item => item.workHistoryId !== action.payload.workHistoryRecordId,
  );
  const reqBody = {
    workHistoryId: action.payload.workHistoryRecordId,
    candidateId: candidateDetails?.travelerId,
    ...workExperienceDetails,
    currentEmployeeId: user.userInfo?.employeeId || 0,
    currentEmployeeName: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
  };
  const response = yield call(TrackDeleteWorkExperience, deleteCandidateWorkExperienceRecord, reqBody);
  if (httpSuccess(response.status)) {
    yield put(candidateDetailActions.setEmployment(filteredWorkExp));
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('candidate.workExperience.snackbar.deleteWorkExperience'),
        severity: 'success',
      }),
    );
  }
}

export function* updateWorkExperienceGridTask(action) {
  const { row, response, missingComponent } = action.payload;
  const temp: any[] = [];
  const employments = yield select(candidateEmployementSelection);
  const selfReferences = yield select(selectReferences);
  const isDataExist = employments?.some(d => d.workHistoryId === response.data.workHistoryId);
  employments.forEach(x => {
    if (x.workHistoryId !== row.workHistoryId) temp.push(x);
    else
      temp.push({
        ...x,
        startedOn: moment(row.startedOn).format('yyyy-MM-DDT00:00')?.toString(),
        endedOn: row.endedOn ? moment(row.endedOn).format('yyyy-MM-DDT00:00') : null,
        yearsOfExperience: parseInt('0'),
        monthsOfExperience: row.monthsOfExperience,
        discipline: response.data.discipline,
        disciplineAbbr: response.data.disciplineAbbr,
        specialty: response.data.specialty,
        specialtyAbbr: response.data.specialtyAbbr,
        position: row.position,
        approvedReferenceCount: row.approvedReferenceCount,
        facilityName: row.facilityName,
        location: {
          address1: '',
          city: row.location.city,
          stateProvinceID: row.location.stateProvinceID,
          state: row.location.state,
          country: row.location.country,
          zip: row.location.zip,
        },
        unit: row.unit,
        travelCompany: row.travelCompany,
        isSupervisor: row.isSupervisor,
        isTeletherapy: row.isTeletherapy,
        staffingAgency: row.staffingAgency,
        isTeachingHospital: row.isTeachingHospital,
        numberOfBedsInFacility: row.numberOfBedsInFacility,
        currentlyEmployed: row.currentlyEmployed,
        placementID: row.placementID,
        reasonForLeaving: row.reasonForLeaving,
        timeStamp: response?.data?.timeStamp,
        candidateId: row?.candidateId || 0,
        brandId: row?.brandId || 0,
        ref: row?.ref || null,
        travelCompanyId: row?.travelCompanyId || null,
        specialtyId: response?.data?.specialtyId || 0,
        disciplineId: response?.data?.disciplineId || 0,
        otherDiscipline: row?.otherDiscipline || null,
        otherSpecialty: row?.otherSpecialty || null,
        equipmentProcedures: row?.equipmentProcedures?.length ? row?.equipmentProcedures : { missingComponent },
        currentEmployeeId: row?.currentEmployeeId || 0,
        currentEmployeeName: row?.currentEmployeeId || '',
        referenceData: selfReferences?.items?.filter(item => item.workHistoryID === x.workHistoryId),
      });
  });
  if (!isDataExist) {
    temp.push(response.data);
  }
  yield put(candidateDetailActions.setEmployment(temp));
}

export function* fetchAllSkillChecklist() {
  const candidateDetails = yield select(candidateDetailsSelection);
  const response = yield call(() => fetchAllSkillChecklistData(candidateDetails?.travelerId));
  if (httpSuccess(response.status)) {
    yield put(candidateDetailActions.setAllSkillChecklistFromPassport(response?.data));
  }
}

export function* setUpReferenceGridData(action) {
  const { filter } = action.payload;
  yield put(candidateDetailActions.setReferenceGridLoadingStatus(true));
  const candidateDetails = yield select(candidateDetailsSelection);

  const selReferences = yield select(filter ? selectReferenceData : selectReferences); //BUG 359428 - if reference filter is available then use the filtered reference Data (filtered based on year(s) conditions)

  if (isPositiveNumber(candidateDetails.travelerId) && isPositiveNumber(candidateDetails.brandId)) {
    const response = yield call(() =>
      getCandidateRequestReference(candidateDetails.travelerId, candidateDetails.brandId),
    );
    const gridData = formatData(selReferences, filter, response.data);
    yield put(candidateDetailActions.setReferenceGrid(gridData));
  }
}

export function* getAvailableChannelFiltersData(action) {
  const response = yield call(getAvailableChannels, action.payload);
  if (response) {
    const channels = response
      .filter(item => item && item !== 'Alert')
      .map(item => (item === 'Voice' ? 'Call' : item === 'Conversation' ? 'SMS' : item));
    const discreteChannels = [...new Set(channels)];
    yield put(candidateDetailActions.setChannelValues(discreteChannels));
  }
}

export function* deleteTravelerPayrollData(action: PayloadAction<IDeleteTravelerPayroll>) {
  try {
    if (isPositiveNumber(action.payload.candidateId)) {
      const response = yield call(deleteTravelerPayroll, action.payload);

      if (response.status && response.status !== 500) {
        const candidateDetails = yield select(candidateDetailsSelection);
        const existingTaxData = _.cloneDeep(candidateDetails?.taxData);
        const filtered = existingTaxData?.filter(item => !action.payload.taxDataIds.includes(item.taxDataID));
        yield put(candidateDetailActions.setTaxData(filtered));

        yield put(
          globalActions.setSnackBar({
            message: 'Candidate payroll has been deleted successfully',
            severity: 'success',
          }),
        );
      } else {
        yield put(
          globalActions.setSnackBar({
            message: i18next.t('common.failedLoad'),
            severity: 'error',
          }),
        );
      }
    }
  } catch (err) {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  }
}

export function* deleteTravelerTaxData(action: PayloadAction<IDeleteTravelerTaxInfo>) {
  try {
    if (isPositiveNumber(action.payload.candidateId)) {
      const response = yield call(deleteTravelerTaxInfo, action.payload);

      if (response.status) {
        const candidateDetails = yield select(candidateDetailsSelection);
        const existingTaxData = _.cloneDeep(candidateDetails?.taxLetterInfo);
        const filtered = existingTaxData?.filter(
          item => !action.payload.TaxLetterInfoIds.includes(item.taxLetterInfoID),
        );
        yield put(candidateDetailActions.setTaxLetterInfo(filtered));
        yield put(
          globalActions.setSnackBar({
            message: 'Permanent Tax Home Information has been deleted successfully',
            severity: 'success',
          }),
        );
      } else {
        yield put(
          globalActions.setSnackBar({
            message: i18next.t('common.failedLoad'),
            severity: 'error',
          }),
        );
      }
    }
  } catch (err) {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  }
}

export function* postPermanentTaxData(action: PayloadAction<IPostTaxLetterInfo>) {
  try {
    if (isPositiveNumber(action.payload.candidateId)) {
      const candidateDetails = yield select(candidateDetailsSelection);
      const response = yield call(postTaxLetterInfo, action.payload?.candidateId, action.payload);
      if (httpSuccess(response.status)) {
        const existingTaxData = _.cloneDeep(candidateDetails?.taxLetterInfo);
        let selectedTaxLetterIndex = existingTaxData.findIndex(
          x => x.taxLetterInfoID === response.data.taxLetterInfoID,
        );

        if (selectedTaxLetterIndex > -1) {
          yield put(
            globalActions.setSnackBar({
              message: i18next.t('candidate.successChanges'),
              severity: 'success',
            }),
          );
          existingTaxData[selectedTaxLetterIndex].taxLetter = action.payload?.taxLetter;
          existingTaxData[selectedTaxLetterIndex].updateDate = action.payload?.updateDate;
        } else {
          yield put(
            globalActions.setSnackBar({
              message: 'Permanent Tax Home Information is added successfully',
              severity: 'success',
            }),
          );
          existingTaxData.push({
            candidateId: action.payload.candidateId,
            taxLetterInfoID: response.data?.taxLetterInfoID,
            taxLetter: action.payload?.taxLetter,
            updateDate: action.payload?.updateDate,
            currentEmployeeId: action.payload?.currentEmployeeId,
            currentEmployeeName: action.payload?.currentEmployeeName,
          });
        }

        yield put(candidateDetailActions.setTaxLetterInfo(existingTaxData));
      } else {
        yield put(
          globalActions.setSnackBar({
            message: i18next.t('common.failedLoad'),
            severity: 'error',
          }),
        );
      }
    }
  } catch (err) {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  }
}

export function* updateTaxData(action: PayloadAction<ITaxData>) {
  try {
    const candidateDetails = yield select(candidateDetailsSelection);
    const payload = action.payload;

    if (
      payload &&
      payload.effectiveDate &&
      candidateDetails.taxData.findIndex(
        x => x.taxDataID !== payload.taxDataID && x.effectiveDate === payload.effectiveDate,
      ) > -1
    ) {
      yield put(
        globalActions.setSnackBar({
          message: 'Effective date should be unique',
          severity: 'error',
        }),
      );
    } else {
      const response = yield call(postTaxData, action.payload as ITaxData);

      if (response.status && response.status === 200) {
        const response = yield call(() => getTaxData(action.payload.candidateId.toString()));
        const { data } = response;
        yield put(candidateDetailActions.setTaxData(data));
        yield put(
          globalActions.setSnackBar({
            message: 'Candidate Payroll Information has been updated successfully',
            severity: 'success',
          }),
        );
      } else {
        yield put(
          globalActions.setSnackBar({
            message: i18next.t('common.failedLoad'),
            severity: 'error',
          }),
        );
      }
    }
  } catch (err) {
    console.log('candidate-details-fetchEMRData');
  }
}

export function* updatePreferencesData(action: PayloadAction<ICandidatePreferences>) {
  try {
    const response = yield call(postPreferencesData, action.payload as ICandidatePreferences);

    if (response.status === 200) {
      const response = yield call(() => getPreferencesData(action.payload.travelerId.toString()));
      const { data } = response;
      yield put(candidateDetailActions.setPreferences(data));
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('candidate.successChanges'),
          severity: 'success',
        }),
      );
    } else {
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('common.failedLoad'),
          severity: 'error',
        }),
      );
    }
  } catch (err) {
    console.log('candidate-details-fetchEMRData');
  }
}

export function* deleteCandidateSkillsetData(action: PayloadAction<IDeleteSkillSet>) {
  try {
    if (isPositiveNumber(action.payload.candidateId)) {
      const response = yield call(deleteCandidateSkillSet, action.payload);

      if (response.status === 200) {
        const data = yield call(() => getCandidateSkillsetTree(action.payload.candidateId));
        yield put(candidateDetailActions.setSkillSetTree(data));
        yield put(
          globalActions.setSnackBar({
            message: 'Skill set has been deleted successfully',
            severity: 'success',
          }),
        );
      } else {
        yield put(
          globalActions.setSnackBar({
            message: i18next.t('common.failedLoad'),
            severity: 'error',
          }),
        );
      }
    }
  } catch (err) {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  }
}

export function* updateCandidateSkillsetTreeData(
  action: PayloadAction<{ data: IUpdateSkillsetTreeData; customSaveMessage?: string }>,
) {
  try {
    if (isPositiveNumber(action.payload.data.candidateId)) {
      const response = yield call(updateCandidateSkillsetTree, action.payload.data);

      if (response.status === 200) {
        const data = yield call(() => getCandidateSkillsetTree(action.payload.data.candidateId));
        yield put(candidateDetailActions.setSkillSetTree(data));
        yield put(
          globalActions.setSnackBar({
            message: action.payload.customSaveMessage ?? 'Skill set has been added successfully',
            severity: 'success',
          }),
        );
      } else {
        yield put(
          globalActions.setSnackBar({
            message: i18next.t('common.failedLoad'),
            severity: 'error',
          }),
        );
      }
    }
  } catch (err) {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  }
}

export function* candidateDetailsSaga() {
  yield takeLatest(candidateDetailActions.getChangeHistory, getCandidateChangeHistory);
  yield takeLatest(candidateDetailActions.getEMRData, fetchEMRData);
  yield takeLatest(candidateDetailActions.postEMRData, saveEMRData);
  yield takeLatest(candidateDetailActions.getPreferredTimeToReach, fetchPreferredTimeToReach);
  yield takeLatest(candidateDetailActions.postSellingNotesTemplate, handleSellingNotesTemplateSave);
  yield takeLatest(candidateDetailActions.getMajors, fetchMajorsList);
  yield takeLatest(candidateDetailActions.getDegree, fetchDegreeList);
  yield takeLatest(candidateDetailActions.getDiscipline, fetchDisciplineList);
  yield takeLatest(candidateDetailActions.getSpecialty, fetchSpecialty);
  yield takeLatest(candidateDetailActions.getTravelCompany, fetchTravelCompany);
  yield takeLatest(candidateDetailActions.getDipsSpecialtyMap, fetchDipsSpecialtyMap);
  yield takeLatest(candidateDetailActions.postRaceEthnicity, postRaceEthnicityData);
  yield takeLatest(candidateDetailActions.getRaceEthnicity, getRaceEthnicityData);
  yield takeLatest(candidateDetailActions.postGender, postGenderData);
  yield takeLatest(candidateDetailActions.getGenderOptions, getGenderOptionsData);
  yield takeLatest(candidateDetailActions.postCandidatePersonalInfo, postCandidatePersonalInformation);
  yield takeLatest(candidateDetailActions.postCandidateReference, postCandidateReferenceData);
  yield takeLatest(candidateDetailActions.deleteEducationRecord, deleteEducationRecord);
  yield takeLatest(candidateDetailActions.getTagsList, fetchTagsList);
  yield takeLatest(candidateDetailActions.postTags, postTagsData);
  yield takeLatest(candidateDetailActions.postReferenceRequest, postReferenceRequestData);
  yield takeLatest(candidateDetailActions.postCandidateAudit, postAuditData);
  yield takeLatest(candidateDetailActions.getTaxData, getAllTaxData);
  yield takeLatest(candidateDetailActions.updateCandidateSSN, updateCandidateSSNTask);
  yield takeLatest(candidateDetailActions.updateCandidateDateOfBirth, updateCandidateDateOfBirthTask);
  yield takeLatest(candidateDetailActions.getPlacementSkillsetExperience, getPlacementSkillsetExperienceData);
  yield takeLatest(candidateDetailActions.requestTimeOffData, fetchRequestTimeOff);
  yield takeLatest(candidateDetailActions.getSupervisorData, fetchSupervisorData);
  yield takeLatest(candidateDetailActions.postRequestTimeOff, postRequestTimeOffData);
  yield takeLatest(candidateDetailActions.postUnverifiedHearsayCertification, postUnverifiedHearsayCertificationData);
  yield takeLatest(
    candidateDetailActions.deleteUnverifiedHearsayCertification,
    deleteUnverifiedHearsayCertificationData,
  );
  yield takeLatest(candidateDetailActions.postUnverifiedHearsayLicense, postUnverifiedHearsayLicenseData);
  yield takeLatest(candidateDetailActions.deleteUnverifiedHearsayLicense, deleteUnverifiedHearsayLicenseData);
  yield takeLatest(candidateDetailActions.deleteWorkExperienceRecord, deleteCandidateWorkExperience);
  yield takeLatest(candidateDetailActions.postSupervisorData, updateSupervisorData);
  yield takeLatest(candidateDetailActions.deleteSupervisorSkillsets, deleteSupervisorSkillsetData);
  yield takeLatest(candidateDetailActions.updateWorkExperienceGrid, updateWorkExperienceGridTask);
  yield takeLatest(candidateDetailActions.getAllSkillChecklists, fetchAllSkillChecklist);
  yield takeLatest(candidateDetailActions.initiateReferenceGridData, setUpReferenceGridData);
  yield takeLatest(candidateDetailActions.getAvailableChannelFilters, getAvailableChannelFiltersData);
  yield takeLatest(candidateDetailActions.updateCandidateAddress, updateCandidateAddressData);
  yield takeLatest(candidateDetailActions.getPermanentTaxLetterData, fetchTaxLetterInfo);
  yield takeLatest(candidateDetailActions.getCandidatePreferenceData, getCandidatePreferences);
  yield takeLatest(candidateDetailActions.deleteTravelerPayroll, deleteTravelerPayrollData);
  yield takeLatest(candidateDetailActions.deleteTravelerTaxData, deleteTravelerTaxData);
  yield takeLatest(candidateDetailActions.postPermanentTaxData, postPermanentTaxData);
  yield takeLatest(candidateDetailActions.updateTaxData, updateTaxData);
  yield takeLatest(candidateDetailActions.updatePreferencesData, updatePreferencesData);
  yield takeLatest(candidateDetailActions.deleteCandidateSkillset, deleteCandidateSkillsetData);
  yield takeLatest(candidateDetailActions.updateCandidateSkillsetTree, updateCandidateSkillsetTreeData);
  yield takeLatest(candidateDetailActions.deleteCandidateAudit, deleteCandidateAuditData);
}
