import { missingField } from '@AMIEWEB/Notification/MultiChannel/Sms/CredentialingAnalyst/PlacementDisplay';
import i18next from 'i18next';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import { Concatenate } from 'utils/string/string';

export const getInitials = (firstName: string, lastName: string) =>
  firstName && lastName ? Concatenate([firstName?.substring(0, 1), lastName?.substring(0, 1)], '') : missingField;

const getPhoneNumber = contact =>
  contact && contact.phoneNumber
    ? Concatenate([contact.countryDialingCode ? '+' + contact.countryDialingCode : '+1', contact.phoneNumber], ' ')
    : '';

export const getKeyInternalPartnerContacts = placementDetails => ({
  recruiter: {
    designation: 'Recruiter',
    keyAttribute: 'recruiter',
    title: Concatenate([placementDetails?.recruiter?.firstName, placementDetails?.recruiter?.lastName], ' '),
    avatarText: getInitials(placementDetails?.recruiter?.firstName, placementDetails?.recruiter?.lastName),
    phoneNumber: getPhoneNumber(placementDetails?.recruiter),
    email: placementDetails?.recruiter?.email,
    id: placementDetails?.recruiter?.id,
    hideMessageIcon: true,
    lockReason:
      placementDetails?.recruiter?.disabledReason ||
      `${i18next.t('placement.profile.header.contactNotEditMessage')} ${missingField}`,
    disabled: placementDetails?.recruiter?.isDisabled || false,
  },
  regionalDirector: {
    designation: 'Regional Director',
    keyAttribute: 'regionalDirector',
    title: Concatenate(
      [placementDetails?.regionalDirector?.firstName, placementDetails?.regionalDirector?.lastName],
      ' ',
    ),
    avatarText: getInitials(
      placementDetails?.regionalDirector?.firstName,
      placementDetails?.regionalDirector?.lastName,
    ),
    phoneNumber: getPhoneNumber(placementDetails?.regionalDirector),
    email: placementDetails?.regionalDirector?.email,
    id: placementDetails?.regionalDirector?.id,
    hideMessageIcon: true,
    lockReason:
      placementDetails?.regionalDirector?.disabledReason ||
      `${i18next.t('placement.profile.header.contactNotEditMessage')} ${missingField}`,
    disabled: placementDetails?.regionalDirector?.isDisabled || false,
  },
  accountManager: {
    designation: 'Account Manager',
    keyAttribute: 'accountManager',
    title: Concatenate([placementDetails?.accountManager?.firstName, placementDetails?.accountManager?.lastName], ' '),
    avatarText: getInitials(placementDetails?.accountManager?.firstName, placementDetails?.accountManager?.lastName),
    phoneNumber: getPhoneNumber(placementDetails?.accountManager),
    email: placementDetails?.accountManager?.email,
    id: placementDetails?.accountManager?.id,
    hideMessageIcon: true,
    lockReason:
      placementDetails?.accountManager?.disabledReason ||
      `${i18next.t('placement.profile.header.contactNotEditMessage')} ${missingField}`,
    disabled: placementDetails?.accountManager?.isDisabled || false,
  },
  credentialingAnalyst: {
    designation: 'Credentialing Analyst',
    keyAttribute: 'credentialingAnalyst',
    title: Concatenate(
      [placementDetails?.credentialingAnalyst?.firstName, placementDetails?.credentialingAnalyst?.lastName],
      ' ',
    ),
    avatarText: getInitials(
      placementDetails?.credentialingAnalyst?.firstName,
      placementDetails?.credentialingAnalyst?.lastName,
    ),
    phoneNumber: getPhoneNumber(placementDetails?.credentialingAnalyst),
    email: placementDetails?.credentialingAnalyst?.email,
    id: placementDetails?.credentialingAnalyst?.id,
    hideMessageIcon: true,
    lockReason:
      placementDetails?.credentialingAnalyst?.disabledReason ||
      `${i18next.t('placement.profile.header.contactNotEditMessage')} ${missingField}`,
    disabled: placementDetails?.credentialingAnalyst?.isDisabled || false,
  },
  clientContractSpecialist: {
    designation: 'Client Contract Specialist',
    keyAttribute: 'clientContractSpecialist',
    title: Concatenate(
      [placementDetails?.clientContractSpecialist?.firstName, placementDetails?.clientContractSpecialist?.lastName],
      ' ',
    ),
    avatarText: getInitials(
      placementDetails?.clientContractSpecialist?.firstName,
      placementDetails?.clientContractSpecialist?.lastName,
    ),
    phoneNumber: getPhoneNumber(placementDetails?.clientContractSpecialist),
    email: placementDetails?.clientContractSpecialist?.email,
    id: placementDetails?.clientContractSpecialist?.id,
    hideMessageIcon: true,
    lockReason:
      placementDetails?.clientContractSpecialist?.disabledReason ||
      `${i18next.t('placement.profile.header.contactNotEditMessage')} ${missingField}`,
    disabled: placementDetails?.clientContractSpecialist?.isDisabled || false,
  },
  clinicalManager: {
    designation: 'Clinical Manager',
    keyAttribute: 'clinicalManager',
    title: Concatenate(
      [placementDetails?.clinicalManager?.firstName, placementDetails?.clinicalManager?.lastName],
      ' ',
    ),
    avatarText: getInitials(placementDetails?.clinicalManager?.firstName, placementDetails?.clinicalManager?.lastName),
    phoneNumber: getPhoneNumber(placementDetails?.clinicalManager),
    email: placementDetails?.clinicalManager?.email,
    id: placementDetails?.clinicalManager?.id,
    hideMessageIcon: true,
  },
});

export const placementContactsFlattened = placementDetails => ({
  placementId: placementDetails.placementId,
  facility: placementDetails.facility,
  recruiter: placementDetails.recruiter,
  regionalDirector: placementDetails.regionalDirector,
  accountManager: placementDetails.accountManager,
  credentialingAnalyst: placementDetails.credentialingAnalyst,
  clientContractSpecialist: placementDetails.clientContractSpecialist,
  clinicalManager: placementDetails.clinicalManager,
  facilityContacts: placementFacilityContactsDetails(placementDetails.facilityContacts),
  keyInternalPartners: getKeyInternalPartnerContacts(placementDetails),
  hideMessageIcon: true,
});

export const placementFacilityContactsDetails = facilityContacts =>
  facilityContacts.map(contact => ({
    ...contact,
    avatarText: getInitials(contact.firstName, contact.lastName),
    title: Concatenate([contact.firstName, contact.lastName], ' '),
    designation: contact.title,
    description: Concatenate(
      contact.actions.map(action => action.description),
      ', ',
    ),
    phoneNumber: getPhoneNumber(contact.phoneNumber?.[0]),
    hideMessageIcon: true,
    hideAvatar: false,
    checked: false,
    hidePhoneIcon: contact?.phoneNumber.length && contact.doNotCall === 0 ? false : true,
  }));

export const getKeyContact = customSwitch({
  ['accountManager']: 1,
  ['recruiter']: 2,
  ['regionalDirector']: 3,
  ['credentialingAnalyst']: 4,
  ['clientContractSpecialist']: 5,
})(null);

export enum Brand {
  AMN_Nurse = 1,
  NursesRX = 2,
  Onward = 3,
  Preferred = 4,
  OGP_Travel = 5,
  Nursefinders = 6,
  TTS = 7,
  OGP_Intl = 8,
  NurseChoice = 9,
  AMN_Allied = 10,
  AMN_Advanced_Practitioner_Staffing = 11,
  Club_Staffing = 12,
  AMN_Labor_Disruption = 14,
}

export const isDisplayContractHours = (brandId?: Brand) => brandId === Brand.OGP_Travel || brandId === Brand.OGP_Intl;

export enum KeyContactType {
  Internal = 'isInternal',
  Client = 'isClient',
}
